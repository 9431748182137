import React from "react";
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    height: "auto",
    backgroundColor: "#EEEEEE",
    border: "1px solid #E2E2E2",
    borderRadius: "5px",
    "& .MuiGrid-item": {
      paddingLeft: theme.spacing(1.5),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1)
    }
  },
  row: {
    borderBottom: "1px solid #E2E2E2",
  }
}));

const ReportDetails = ({ data }) => {
  const classes = useStyles();
  return (
    <Grid container direction="column" className={`${classes.root} information`}>
      <Grid container className={classes.row}>
        <Grid item xs={2}><Typography variant="h6">Nazwa</Typography></Grid>
        <Grid item xs><Typography variant="h6">{data.name}</Typography></Grid>
      </Grid>
      <Grid container className={classes.row}>
        <Grid item xs={2}><Typography variant="h6">Data utworzenia</Typography></Grid>
        <Grid item xs><Typography variant="h6">{data.createdAt}</Typography></Grid>
      </Grid>
      <Grid container className={classes.row}>
        <Grid item xs={2}><Typography variant="h6">Autor</Typography></Grid>
        <Grid item xs><Typography variant="h6">{data.email}</Typography></Grid>
      </Grid>
      <Grid container className={classes.row}>
        <Grid item xs={2}><Typography variant="h6">Zakres dat</Typography></Grid>
        <Grid item xs><Typography variant="h6">{data.dateFrom} - {data.dateTo}</Typography></Grid>
      </Grid>
      <Grid container>
        <Grid item xs={2}><Typography variant="h6">Notatka</Typography></Grid>
        <Grid item xs><Typography variant="h6">{data.note ? data.note : 'Brak'}</Typography></Grid>
      </Grid>
    </Grid>
  );
};

export default ReportDetails;
