import React, { useContext, useEffect, useState } from "react";
import { filterContext } from "../../_context/filterContext";
import { getSaleSygnatury } from "../../_services/_saleService";
// import TableComponent from "../Bestseller/components/TableComponent";
import { ProgressBarComponent } from "../ProgressBarComponent";
import { Grid, Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import TablePlaceholder from "../TablePlaceholder";
import { ActiveFilter } from "../Filters/_activeFilter/ActiveFilter";
import NoProductAlert from "../../_helpers/NoProductAlert";
import MUIDataTable from "mui-datatables";
import { RaportContext } from "../../_context/raportContext";
import { RaportModeContext } from "../../_context/raportModeContext";
import {
  ADD_TO_SELECTED_SIGNATURES,
  REMOVE_FROM_SELECTED_SIGNATURES
} from "../../_reducers/raportReducer";
import ReportMenu, { sygnaturiesReportType } from "../Reports/shared/ReportMenu";

const useStyles = makeStyles(theme => ({
  buttons: {
    marginTop: theme.spacing(1)
  },
  tablePaper: theme.tablePaper,
  chartPaper: theme.chartPaper
}));

const columns = [
  {
    name: "name",
    label: "Nazwa",
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: "total",
    label: "Sprzedane - Zwrócone [szt.]",
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: "sell",
    label: "Sprzedane [szt.]",
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: "returned",
    label: "Zwrócone [szt.]",
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: "rabat",
    label: "Rabat [%]",
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: "avg_vat",
    label: "Średni vat sprzedaży[%]",
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: "avg_p_netto",
    label: "Średnia cena netto [PLN]",
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: "priceNetto",
    label: "Suma netto [PLN]",
    options: {
      filter: true,
      sort: true
    }
  }
];

export const SaleSygnaturyView = ({ setView }) => {
  const classes = useStyles();

  const { state } = useContext(filterContext);
  const [isLoading, setIsLoading] = useState(true);
  const [saleSygnatury, setSaleSygnatury] = useState([]);
  const [saleSygnaturyType, setSaleSygnaturyType] = useState([]);
  const { isRaportMode } = useContext(RaportModeContext);
  const { state: stateRaport, dispatch } = useContext(RaportContext);

  useEffect(() => {
    window.scrollTo(0, 0);
    setIsLoading(true);
    getSaleSygnatury(state)
      .then(res => {
        setSaleSygnatury(res.data.total);
        setSaleSygnaturyType(res.data.type);
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        setIsLoading(false);
        window.scrollTo(0, 0);
      });
  }, [state]);

  function createData(
    name,
    total,
    sell,
    returned,
    rabat,
    avg_vat,
    avg_p_netto,
    priceNetto
  ) {
    return {
      name,
      total,
      sell,
      returned,
      rabat,
      avg_vat,
      avg_p_netto,
      priceNetto
    };
  }

  const round = (n, k) => {
    const factor = Math.pow(10, k + 1);
    n = Math.round(Math.round(n * factor) / 10);
    return n / (factor / 10);
  };

  const datas = saleSygnatury.map(it =>
    createData(
      it.name,
      it.qtyTotal,
      it.qtySell,
      it.qtyReturned,
      round(it.percentRabat, 2),
      round(it.avgVatRate, 2),
      round(it.avgPriceNetto, 2),
      round(it.priceNetto, 2)
    )
  );

  const handleSelected = val => {

    if (val.length === 1) {
      const id = saleSygnatury[val[0].dataIndex].id;
      const exist = stateRaport.selectedSignatures.includes(id);

      if (!exist) {
        dispatch({ type: ADD_TO_SELECTED_SIGNATURES, payload: id });
      } else {
        dispatch({ type: REMOVE_FROM_SELECTED_SIGNATURES, payload: id });
      }
    } else if (val.length > 1) {
      for (const valElement of val) {
        const id = saleSygnatury[valElement.dataIndex].id;
        const exist = stateRaport.selectedSignatures.includes(id);

        if (!exist) {
          dispatch({ type: ADD_TO_SELECTED_SIGNATURES, payload: id });
        }
      }
    } else {
      for (const valElement of val) {
        const id = saleSygnatury[valElement.dataIndex].id;
        const exist = stateRaport.selectedSignatures.includes(id);

        if (exist) {
          dispatch({ type: REMOVE_FROM_SELECTED_SIGNATURES, payload: id });
        }
      }
    }
  };

  let selectedSignaturesIndex = [];

  stateRaport.selectedSignatures.forEach(it => {
    saleSygnatury.forEach((syg, index) => {
      if (syg.id === it) {
        selectedSignaturesIndex.push(index);
      }
    });
  });

  if (!isLoading && saleSygnatury.length < 1)
    return <NoProductAlert setView={setView} />;

  const options = {
    download: false,
    print: false,
    filter: false,
    viewColumns: false,
    selectableRows: isRaportMode,
    onRowsSelect: handleSelected,
    disableToolbarSelect: true,
    selectableRowsHeader: true,
    rowsSelected: selectedSignaturesIndex,
    textLabels: {
      pagination: {
        rowsPerPage: "Wyświetl"
      },
      toolbar: {
        search: "Szukaj",
        viewColumns: "Pokaż kolumny",
        filterTable: "Filtruj tabelę"
      },
      filter: {
        all: "Wszystkie",
        title: "Filtry",
        reset: "Wyczyść"
      },
      viewColumns: {
        title: "Pokaż kolumny",
        titleArea: "Pokaż/Schowaj kolumnę"
      }
    }
  };

  return (
    <Grid container spacing={3}>
      {isRaportMode ? (
        <ReportMenu
          reducerKey="selectedSignatures"
          type={sygnaturiesReportType}
        />
      ) : null}
      <Grid item xs={12}>
        <ActiveFilter />
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.tablePaper}>
          {isLoading ? (
            <TablePlaceholder />
          ) : (
            <MUIDataTable data={datas} columns={columns} options={options} />
            // <TableComponent
            //   headCellsData={headers}
            //   rowsData={rows}
            // ></TableComponent>
          )}
        </Paper>
      </Grid>
      {!isRaportMode ? (
        <Grid item xs={12}>
          {isLoading ? (
            <TablePlaceholder />
          ) : (
            <Paper className={classes.chartPaper}>
              <Typography variant="h2">Sygnatury</Typography>
              <ProgressBarComponent
                type={saleSygnaturyType}
              ></ProgressBarComponent>
            </Paper>
          )}
        </Grid>
      ) : null}
    </Grid>
  );
};
