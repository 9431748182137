import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import {
  makeStyles,
  TextField,
  Typography,
  InputAdornment,
  Divider,
  Grid
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import HighlightOffRoundedIcon from "@material-ui/icons/HighlightOffRounded";
import { DatePicker } from "@material-ui/pickers";

const useStyles = makeStyles(theme => ({
  root: {
    " & .MuiDialog-paperWidthSm": {
      maxWidth: "unset!important",
      minWidth: "660px"
    }
  },
  modalContent: {
    width: "100%",
    display: "flex",
    gap: "20px",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    margin: "20px 0px"
  },
  inputField: {
    ...theme.inputField,
    ...theme.inputClearButton,
    width: "90%"
  },
  divider: theme.divider,
  labels: theme.datePriceLabels
}));

const getInitDateOfCurrentMonth = () => {
  var date = new Date();
  var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

  return {
    firstDayOfCurrentMonth: convertDate(firstDay),
    lastDayOfCurrentMonth: convertDate(lastDay)
  };
};

const convertDate = date => {
  return (
    date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
  );
};

const CreateRaportModal = ({ open, handleClose, handleSave, title = null }) => {
  const classes = useStyles();
  const [raportName, setRaportName] = useState("");
  const initDate = getInitDateOfCurrentMonth();
  const [dateFrom, setDateFrom] = useState(initDate.firstDayOfCurrentMonth);
  const [dateTo, setDateTo] = useState(initDate.lastDayOfCurrentMonth);

  const submit = () => {
    handleSave({ name: raportName, dateFrom: dateFrom, dateTo: dateTo });
    setRaportName("");
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="dialog-title"
      className={classes.root}
    >
      <div className={classes.modalContent}>
        <Typography variant="h2">Podaj dane dotyczące raportu</Typography>
        <TextField
          key="query-for-uni-index"
          label="Nazwa raportu"
          value={raportName || ""}
          variant="outlined"
          onChange={event => setRaportName(event.target.value)}
          name="raportName"
          className={classes.inputField}
        />

        <div
          style={{
            width: "90%",
            display: "flex",
            flexDirection: "row"
          }}
        >
          <DatePicker
            variant="inline"
            inputVariant="outlined"
            label="Data Od"
            value={dateFrom}
            onChange={event => setDateFrom(convertDate(event))}
            format="yyyy/MM/dd"
            name="dateFrom"
            className={classes.inputField}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  className={classes.inputClearButton}
                  position="end"
                >
                  <HighlightOffRoundedIcon
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      setDateFrom(null);
                    }}
                  />
                </InputAdornment>
              )
            }}
          />

          <Grid item className={classes.divider}>
            <br />
            <Divider />
          </Grid>

          <DatePicker
            variant="inline"
            inputVariant="outlined"
            label="Data do"
            value={dateTo}
            onChange={event => setDateTo(convertDate(event))}
            format="yyyy/MM/dd"
            name="dateFrom"
            className={classes.inputField}
            InputProps={{
              endAdornment: (
                <InputAdornment className={classes.clear} position="end">
                  <HighlightOffRoundedIcon
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      setDateTo(null);
                    }}
                  />
                </InputAdornment>
              )
            }}
          />
        </div>
        <Button variant="contained" color="primary" onClick={submit}>
          {title ? title : "UTWÓRZ"}
        </Button>
      </div>
    </Dialog>
  );
};

export default CreateRaportModal;
