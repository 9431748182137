import React, { useContext, useState } from "react";
import { makeStyles } from "@material-ui/core";
import { RaportContext } from "../../../_context/raportContext";
import NoteAddRoundedIcon from "@material-ui/icons/NoteAddRounded";
import { filterContext } from "../../../_context/filterContext";
import { addProductItemToReport } from "../../../_services/_raportsService";
import { CLEAR_ALL_SELECTED } from "../../../_reducers/raportReducer";
import { useDispatch } from "react-redux";
import { ERROR, SUCCESS } from "../../../_actions/alertsActions";
import AddToRaportModal from "../DraftReport/AddToRaportModal";

const useStyles = makeStyles(theme => ({
  // placeholder: {
  //   height: "47px",
  //   width: "calc(100% - 92px)"
  // },
  root: {
    position: "fixed",
    zIndex: 1000,
    width: "calc(100% - 92px)",
    height: "47px",
    backgroundColor: "black",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px 20px 0px 20px"
  },
  item: {
    color: "#ffffff",
    display: "flex",
    alignItems: "center"
  },
  add: {
    color: "#ffffff",
    display: "flex",
    alignItems: "center",

    "&:hover": {
      cursor: "pointer"
    }
  }
}));

export const productReportType = "product";
export const ordersReportType = "order";
export const designersReportType = "projektanci";
export const localizationsReportType = "lokalizacja";
export const sygnaturiesReportType = "sygnatura";
export const categoriesReportType = "kategoria";

const ReportMenu = ({ reducerKey, type }) => {
  const classes = useStyles();
  const { state, dispatch: dispatchReport } = useContext(RaportContext);
  const { state: stateFilters } = useContext(filterContext);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = id => {
    setIsLoading(true);

    switch (type) {
      case productReportType:
        return addItemsToProductReports(
          id,
          productReportType,
          "selectedProduct",
          "produkty",
          "produktów"
        );
      case ordersReportType:
        return addItemsToProductReports(
          id,
          ordersReportType,
          null,
          "zamówienia",
          "zamówień"
        );
      case designersReportType:
        return addItemsToProductReports(
          id,
          designersReportType,
          "selectedDesigner",
          "projektantów",
          "projektantów"
        );
      case localizationsReportType:
        return addItemsToProductReports(
          id,
          localizationsReportType,
          "selectedLocalization",
          "lokalizacje",
          "lokalizacji"
        );
      case sygnaturiesReportType:
        return addItemsToProductReports(
          id,
          sygnaturiesReportType,
          "selectedSignatures",
          "sygnatury",
          "sygnatur"
        );
      case categoriesReportType:
        return addItemsToProductReports(
          id,
          categoriesReportType,
          "selectedCategories",
          "kategorie",
          "kategorii"
        );
      default:
        return;
    }
  };

  const addItemsToProductReports = (reportId, type, reducerKey, successTypeText, errorTypeText) => {
    const parsedFilters = {
      ...stateFilters,
      groupBy: stateFilters.groupBy
        .filter(it => it.isActive)
        .map(it => {
          return it.value;
        })
    };

    const data = {
      ids: reducerKey ? state[reducerKey].join(",") : [],
      filter: parsedFilters
    };


    addProductItemToReport(reportId, type, data)
      .then(res => {
        dispatch({
          type: SUCCESS,
          payload: `Pomyślnie dodano ${successTypeText} do raportu`
        });
      })
      .catch(() => {
        dispatch({
          type: ERROR,
          payload: `Błąd dodawania ${errorTypeText} do raportu`
        });
      })
      .finally(() => {
        dispatchReport({ type: CLEAR_ALL_SELECTED });
        setIsLoading(false);
        setOpen(false);
      });
  };

  return (
    <>
      <div className={classes.root}>
        {reducerKey ? (
          <div className={classes.item}>
            {state[reducerKey].length} zaznaczonych
          </div>
        ) : null}

        <div className={classes.add} onClick={() => setOpen(true)}>
          <NoteAddRoundedIcon style={{ fontSize: "22px", marginRight: "5px" }} />
          DODAJ DO RAPORTU
        </div>

        <AddToRaportModal
          open={open}
          handleClose={handleClose}
          handleSave={handleSave}
          isLoading={isLoading}
        />
      </div>
      {/*<div className={classes.placeholder}></div>*/}
    </>
  );
};

export default ReportMenu;
