import React, { useContext, useEffect, useState } from "react";
import { filterContext } from "../../../_context/filterContext";
import { getSezony } from "../../../_services/_bestsellerService";
import TableComponent from "../components/TableComponent";
import Grid from "@material-ui/core/Grid";
import TablePlaceholder from "../../../_components/TablePlaceholder";
import { formatter } from "../../../_helpers/ConvertPrice";

export const SezonyComponent = ({ switchValue, id, sezonyData }) => {
  const { state } = useContext(filterContext);
  const [sezony, setSezony] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (sezonyData) {
      setSezony(sezonyData);
      setIsLoading(false);
      return;
    }
    setIsLoading(true);
    getSezony(switchValue ? state : { isPaid: 1 }, id)
      .then(res => {
        setSezony(res.data);
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [state, id, switchValue, sezonyData]);

  const headers = [
    {
      id: "sezon",
      numeric: false,
      disablePadding: true,
      label: "Sezon"
    },
    {
      id: "total",
      numeric: true,
      disablePadding: false,
      label: "Sprzedane - Zwrócone [szt.]"
    },
    {
      id: "sell",
      numeric: true,
      disablePadding: false,
      label: "Sprzedane [szt.]"
    },
    {
      id: "returned",
      numeric: true,
      disablePadding: false,
      label: "Zwrócone [szt.]"
    },
    {
      id: "p_netto",
      numeric: true,
      disablePadding: false,
      label: "Łączna cena netto [PLN]"
    },
    {
      id: "rabat",
      numeric: true,
      disablePadding: false,
      label: "Rabat [%]"
    },
    {
      id: "avg_p_netto",
      numeric: true,
      disablePadding: false,
      label: "Średnia cena netto [PLN]"
    }
  ];

  function createData(
    sezon,
    total,
    sell,
    returned,
    p_netto,
    rabat,
    avg_p_netto
  ) {
    return { sezon, total, sell, returned, p_netto, rabat, avg_p_netto };
  }

  const round = (n, k) => {
    const factor = Math.pow(10, k + 1);
    n = Math.round(Math.round(n * factor) / 10);
    return n / (factor / 10);
  };

  const rows = sezony.map(it =>
    createData(
      it.sezon,
      it.qtyTotal,
      it.qtySell,
      it.qtyReturned,
      formatter(it.priceNetto),
      round(it.percentRabat, 2),
      formatter(it.avgPriceNetto)
    )
  );

  //   const headerChart = ["Kraj", "Sprzedane"];
  //   const sygnaturaDataChart = lokalizacje.map(it => [
  //     it.name,
  //     parseInt(it.qtySell)
  //   ]);

  //   const data = [headerChart, ...sygnaturaDataChart];

  return (
    <>
      <Grid item xs={12}>
        {isLoading ? (
          <TablePlaceholder />
        ) : (
          <TableComponent
            headCellsData={headers}
            rowsData={rows}
          ></TableComponent>
        )}
      </Grid>
      {/* <Grid item xs={5}>
        {isLoading ? (
          <TablePlaceholder />
        ) : (
          <Chart
            width={"500px"}
            height={"300px"}
            chartType="GeoChart"
            data={data}
            mapsApiKey="YOUR_KEY_HERE"
            rootProps={{ "data-testid": "1" }}
          />
        )}
      </Grid> */}
    </>
  );
};
