import { SUCCESS } from "../../_actions/alertsActions";
import { cloneElement } from "react";
import { useDispatch } from "react-redux";

const CopyToClipboard = ({ value, children }) => {
  const dispatch = useDispatch();

  const handleOnClick = () => {
    const el = document.createElement("textarea");
    el.value = value.trim();
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    dispatch({ type: SUCCESS, payload: "Skopiowano" });
  }

  const childrenProps = {
    onClick: handleOnClick,
    ...children.props
  };

  return cloneElement(children, childrenProps)
}

export default CopyToClipboard;
