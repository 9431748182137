import React, { useContext, useEffect, useState } from "react";
import { filterContext } from "../../_context/filterContext";
import { getSaleKategorie } from "../../_services/_saleService";
import { SaleKategorieViewComponent } from "./SaleKategorieComponents/SaleKategorieViewComponent";
import { ActiveFilter } from "../Filters/_activeFilter/ActiveFilter";
import NoProductAlert from "../../_helpers/NoProductAlert";
import { RaportModeContext } from "../../_context/raportModeContext";
import ReportMenu, { categoriesReportType } from "../Reports/shared/ReportMenu";
import Grid from "@material-ui/core/Grid";

export const SaleKategorieView = ({ setView }) => {
  const { state } = useContext(filterContext);
  const [isLoading, setIsLoading] = useState(true);
  const [saleKategorie, setSaleKategorie] = useState([]);
  const [saleKategorieWomen, setSaleKategorieWomen] = useState([]);
  const [saleKategorieMen, setSaleKategorieMen] = useState([]);
  const [saleKategorieChild, setSaleKategorieChild] = useState([]);
  const [saleKategorieUnisex, setSaleKategorieUnisex] = useState([]);
  const [saleKategorieColor, setSaleKategorieColor] = useState([]);
  const { isRaportMode } = useContext(RaportModeContext);

  useEffect(() => {
    setIsLoading(true);
    window.scrollTo(0, 0);
    getSaleKategorie(state)
      .then(res => {
        setSaleKategorie(res.data.categories);
        setSaleKategorieWomen(res.data.categories.women);
        setSaleKategorieMen(res.data.categories.men);
        setSaleKategorieChild(res.data.categories.child);
        setSaleKategorieUnisex(res.data.categories.unisex);
        setSaleKategorieColor(res.data.categories.color);
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        setIsLoading(false);
        window.scrollTo(0, 0);
      });
  }, [state]);

  if (!isLoading && saleKategorie.length < 1)
    return <NoProductAlert setView={setView} />;

  return (
    <>
      <Grid container spacing={3}>
        {isRaportMode ? (
          <ReportMenu
            reducerKey="selectedCategories"
            type={categoriesReportType}
          />
        ) : null}
        <ActiveFilter />
      </Grid>

      <SaleKategorieViewComponent
        isLoading={isLoading}
        data={saleKategorieWomen}
        label="Kobiety"
      />

      <SaleKategorieViewComponent
        isLoading={isLoading}
        data={saleKategorieMen}
        label="Mężczyźni"
      />

      <SaleKategorieViewComponent
        isLoading={isLoading}
        data={saleKategorieUnisex}
        label="Unisex"
      />

      <SaleKategorieViewComponent
        isLoading={isLoading}
        data={saleKategorieChild}
        label="Dzieci"
      />

      <SaleKategorieViewComponent
        isLoading={isLoading}
        data={saleKategorieColor}
        label="Kolory"
      />
    </>
  );
};
