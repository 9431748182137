import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { makeStyles, Grid } from "@material-ui/core";
import { ReportsMenu } from "../shared/ReportNavigation";
import { useParams } from "react-router-dom";
import useReportById from "../../../_hooks/Reports/useReportById";
import Actions from "./Actions";
import { markReportUserNotificationAsSeen } from "../../../_services/_raportsService";
import ReportDetails from "../shared/ReportDetails";
import ReportItemTypes from "../shared/report-item-types";
import ProductsReportPanel from "../ProductsReportPanel";
import OrdersReportPanel from "../OrdersReportPanel";
import DesignersReportPanel from "../DesignersReportPanel";
import LocalizationsReportPanel from "../LocalizationsReportPanel";
import SignaturesReportPanel from "../SignaturesReportPanel";
import CategoriesReportPanel from "../CategoriesReportPanel";
import ReportPlaceholder from "../shared/ReportPlaceholder";

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: `calc(${theme.headerHeight} * 2)`,
    backgroundColor: theme.palette.gray,
    padding: theme.spacing(1.5),
    boxSizing: "border-box",
    minHeight: `calc(100vh - ${parseInt(theme.headerHeight) * 2}px)`,
    "& .MuiTablePagination-root": {
      border: "none !important"
    }
  },
  reportPanelsContainer: {
    maxWidth: "100%",
    marginTop: theme.spacing(1.5),
    gap: "2px",
    "& .MuiPaper-root:not(.MuiTableCell-root > .MuiPaper-root)": {
      boxShadow: "none !important"
    }
  },
  tab: {}
}));

const ShowPublishedReport = () => {
  const classes = useStyles();
  const params = useParams();
  const { data } = useReportById(params.id);

  const [isSeen, setIsSeen] = useState(false);

  useEffect(() => {
    if (!isSeen) {
      markReportUserNotificationAsSeen(params.id).then(res => {
        if (res.status === 200) {
          setIsSeen(true);
        }
      });
    }
  }, [setIsSeen, params.id, isSeen]);

  return (
    <div className={classes.root}>
      <ReportsMenu/>

      <Helmet>
        <meta charSet="utf-8"/>
        <title>Raport opublikowany</title>
      </Helmet>

      {!data?.report ? (
        <ReportPlaceholder/>
      ) : (
        <Grid container direction="column">
          <Actions
            reportId={data.report.id}
            note={data.report.note}
            ownerUserId={data.report.owner.id}
            comments={data.comment.filter(it => it.type === ReportItemTypes.General)}
            isDraft={false}
          />

          <ReportDetails data={data.report}/>

          <div className={classes.reportPanelsContainer}>
            <ProductsReportPanel
              data={data.item.filter(it => it.type === ReportItemTypes.Product)}
              comments={data.comment.filter(it => it.type === ReportItemTypes.Product)}
              id={data.report.id}
              isDraft={false}
            />

            <OrdersReportPanel
              data={data.item.filter(it => it.type === ReportItemTypes.Order)[0]}
              comments={data.comment.filter(it => it.type === ReportItemTypes.Order)}
              id={data.report.id}
              isDraft={false}
            />

            <DesignersReportPanel
              data={data.item.filter(it => it.type === ReportItemTypes.Designer)[0]}
              comments={data.comment.filter(it => it.type === ReportItemTypes.Designer)}
              id={data.report.id}
              isDraft={false}
            />

            <LocalizationsReportPanel
              data={data.item.filter(it => it.type === ReportItemTypes.Localization)[0]}
              comments={data.comment.filter(it => it.type === ReportItemTypes.Localization)}
              id={data.report.id}
              isDraft={false}
            />

            <SignaturesReportPanel
              data={data.item.filter(it => it.type === ReportItemTypes.Signature)[0]}
              comments={data.comment.filter(it => it.type === ReportItemTypes.Signature)}
              id={data.report.id}
              isDraft={false}
            />

            <CategoriesReportPanel
              data={data.item.filter(it => it.type === ReportItemTypes.Category)[0]}
              comments={data.comment.filter(it => it.type === ReportItemTypes.Category)}
              id={data.report.id}
              isDraft={false}
            />
          </div>
        </Grid>
      )}
    </div>
  );
};

export default ShowPublishedReport;
