import React, { useContext } from "react";
import { Typography, Checkbox } from "@material-ui/core";
import { RaportContext } from "../../../_context/raportContext";
import { ADD_TO_SELECTED_PRDOUCT } from "../../../_reducers/raportReducer";

const ProductReportCheckbox = ({ isRaportMode, itemId }) => {
  const { state, dispatch } = useContext(RaportContext);

  const handleChange = e => {
    dispatch({ type: ADD_TO_SELECTED_PRDOUCT, payload: itemId });
  };
  return (
    <>
      {isRaportMode ? (
        <Typography variant="body1" component="span">
          <Checkbox
            style={{ color: "white" }}
            onChange={handleChange}
            checked={state.selectedProduct.includes(itemId)}
          />
        </Typography>
      ) : null}
    </>
  );
};

export default ProductReportCheckbox;
