import React, { useState, useContext } from "react";
import MUIDataTable from "mui-datatables";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { TableCell, TableRow, Link, Tooltip } from "@material-ui/core";
import BestsellerNavigation from "../../Bestseller/BestsellerMenu";
import { RaportContext } from "../../../_context/raportContext";
import {
  UPDATE_POSITION,
  UPDATE_SELECTED_PRODUCT_TABLE_ITEMS
} from "../../../_reducers/raportReducer";
import LinkIcon from "@material-ui/icons/Link";
import { HeadLabel } from "./HeadLabel";
import { formatter } from "@helpers/ConvertPrice";
import { getCategory, MUIDataTableNumericSort } from "./helpers";
import ProductThumbnail from "../../Common/ProductThumbnail";
import CopyToClipboard from "../../Common/CopyToClipboard";

const useStyles = makeStyles(theme => ({
  table: {
    "& th:first-of-type button": {
      display: "none"
    },
    "& .MuiTableCell-head div": {
      justifyContent: "flex-end"
    }
  },
  infoLabelWrapper: {
    display: "flex",
    flexDirection: "row",
    fontSize: "10px"
  },
  fotoWrapper: {
    marginTop: "8px",
    marginRight: "14px",
    height: "70px",
    minWidth: "45px",
    display: "flex"
  },
  infoWrapper: {
    marginLeft: "10px",
    flex: 1,
    flexDirection: "column",
    padding: "6px 0"
  },
  infoRow: {
    display: "grid",
    flex: 1,
    width: "100%",
    gridTemplateColumns: "80px auto",
    textTransform: "uppercase",
    marginBottom: "5px",
    fontSize: "12px",
    "& p, & h6, & a": {
      paddingTop: 0,
      fontSize: "12px",
      display: "flex"
    }
  },
  nameRow: {
    textTransform: "uppercase",
    marginBottom: "5px",
    display: "flex",
    "& p, & h6": {
      fontSize: "13px"
    }
  },
  position: {
    width: "34px",
    height: "30px",
    paddingLeft: "10px",
    paddingRight: 0,
    borderRadius: "4px",
    border: "1px solid #585858"
  },
  linkIcon: {
    fontSize: "15px",
    position: "relative",
    top: "1px",
    paddingLeft: "4px",
    paddingRight: "16px"
  },
  expandableRow: {
    padding: "0!important",
    "& > div": {
      marginTop: 0
    }
  },
  carryOver: {
    marginLeft: theme.spacing(1),
    background: "black",
    color: "white",
    fontSize: "10px",
    padding: "2px 4px",
    borderRadius: "5px"
  }
}));

const ProductReportTable = ({ data, editMode }) => {
  const { state, dispatch } = useContext(RaportContext);

  let dataParsed = data.map(it => {
    return {
      lp: { position: parseInt(it.position) + 1, id: it.id },
      info: it,
      sezon: it.result.product.sezon,
      designer: it.result.product.designer,
      basePriceNetto:
        it.result && it.result.product && it.result.product.basePriceNetto
          ? parseInt(it.result.product.basePriceNetto, 10)
          : 0,
      avgPriceNetto:
        it.result && it.result.product && it.result.product.avgPriceNetto
          ? parseInt(it.result.product.avgPriceNetto, 10)
          : 0,
      firstDelivery:
        it.result.product &&
        it.result.product.dostawy.first &&
        it.result.product.dostawy.first.dataDostawy
          ? it.result.product.dostawy.first.dataDostawy.date
          : "-",
      threeLastDelivery:
        it.result.product && it.result.product.dostawy.last.length
          ? it.result.product.dostawy.last
          : [],
      percentRabat:
        it.result.product && it.result.product.percentRabat
          ? it.result.product.percentRabat + "%"
          : "0%",
      qtySell:
        it.result.product && it.result.product.qtySell
          ? it.result.product.qtySell
          : 0,
      stock:
        it.result.product && it.result.product.stock
          ? it.result.product.stock
          : 0,
      qtyReturned:
        it.result.product && it.result.product.qtyReturned
          ? it.result.product.qtyReturned
          : 0,
      qtyTotal:
        it.result.product && it.result.product.qtyTotal
          ? it.result.product.qtyTotal
          : 0,
      avgVat:
        it.result.product && it.result.product.avgVatRate
          ? it.result.product.avgVatRate + "%"
          : "0%"
    };
  });

  const [order, setOrder] = useState(
    Object.assign({}, ...dataParsed.map(it => ({ [it.lp.id]: it.lp.position })))
  );

  const columns = [
    {
      name: "lp",
      label: "Lp",
      options: {
        filter: false,
        sort: true,
        sortCompare: o => (lp1, lp2) => (order[lp1.data.id] - order[lp2.data.id]) * (o === "asc" ? 1 : -1),
        customHeadLabelRender: (value, tableMeta) => (
          <HeadLabel value={value.label}/>
        ),
        customBodyRender: (value, tableMeta) => {
          return (
            <input
              readOnly={!editMode}
              type="number"
              min="1"
              max={Object.keys(order).length}
              value={order[value.id]}
              className={classes.position}
              style={{ fontSize: "12px" }}
              onChange={e => {
                const newPosition = parseInt(e.target.value);
                let newOrder = {};
                Object.assign(newOrder, order);

                if (newPosition > order[value.id]) {
                  const nextItemId = Object.keys(order).find(
                    key => order[key] === order[value.id] + 1
                  );
                  if (nextItemId) {
                    newOrder[nextItemId] = order[nextItemId] - 1;
                  }
                } else if (newPosition < order[value.id]) {
                  const prevItemId = Object.keys(order).find(
                    key => order[key] === order[value.id] - 1
                  );
                  if (prevItemId) {
                    newOrder[prevItemId] = order[prevItemId] + 1;
                  }
                }

                newOrder[value.id] = newPosition;
                setOrder(newOrder);
                const obj = { id: value.id, position: newPosition - 1 };
                dispatch({ type: UPDATE_POSITION, payload: obj });
              }}
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
              }}
            />
          );
        }
      }
    },
    {
      name: "info",
      label: "Nazwa",
      options: {
        filter: true,
        sort: true,
        sortCompare: order => (val1, val2) =>
          val1.data.result.product.nazwa.localeCompare(val2.data.result.product.nazwa) * (order === "asc" ? 1 : -1),
        customHeadLabelRender: (value, tableMeta) => (
          <HeadLabel value={value.label} usePadding/>
        ),
        customBodyRender: (value, tableMeta) => {
          return <InfoLabel data={value} isDraft={editMode}/>;
        }
      }
    },
    {
      name: "sezon",
      label: "Sezon",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: (value, tableMeta) => (
          <HeadLabel value={value.label}/>
        ),
        customBodyRender: (value, tableMeta) => {
          return <HeadLabel value={value}/>;
        }
      }
    },
    {
      name: "designer",
      label: "Projektant",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: (value, tableMeta) => (
          <HeadLabel value={value.label}/>
        ),
        customBodyRender: (value, tableMeta) => {
          return <HeadLabel value={value}/>;
        }
      }
    },
    {
      name: "qtyTotal",
      label: "Sprzedaż - Zwroty",
      options: {
        filter: true,
        sort: true,
        sortCompare: MUIDataTableNumericSort,
        customHeadLabelRender: (value, tableMeta) => (
          <HeadLabel value={value.label}/>
        ),
        customBodyRender: (value, tableMeta) => {
          return <HeadLabel value={value}/>;
        }
      }
    },
    {
      name: "qtySell",
      label: "Sprzedaż",
      options: {
        filter: true,
        sort: true,
        sortCompare: MUIDataTableNumericSort,
        customHeadLabelRender: (value, tableMeta) => (
          <HeadLabel value={value.label}/>
        ),
        customBodyRender: (value, tableMeta) => {
          return <HeadLabel value={value}/>;
        }
      }
    },
    {
      name: "qtyReturned",
      label: "Zwroty",
      options: {
        filter: true,
        sort: true,
        sortCompare: MUIDataTableNumericSort,
        customHeadLabelRender: (value, tableMeta) => (
          <HeadLabel value={value.label}/>
        ),
        customBodyRender: (value, tableMeta) => {
          return <HeadLabel value={value}/>;
        }
      }
    },
    {
      name: "stock",
      label: "Stan",
      options: {
        filter: true,
        sort: true,
        sortCompare: MUIDataTableNumericSort,
        customHeadLabelRender: (value, tableMeta) => (
          <HeadLabel value={value.label}/>
        ),
        customBodyRender: (value, tableMeta) => {
          return <HeadLabel value={value}/>;
        }
      }
    },
    {
      name: "basePriceNetto",
      label: "Cena bazowa",
      options: {
        filter: true,
        sort: true,
        sortCompare: MUIDataTableNumericSort,
        customHeadLabelRender: (value, tableMeta) => (
          <HeadLabel value={value.label}/>
        ),
        customBodyRender: (value, tableMeta) => {
          return <HeadLabel value={formatter(value)}/>;
        }
      }
    },
    {
      name: "avgPriceNetto",
      label: "Średnia cena sprzedaży",
      options: {
        filter: true,
        sort: true,
        sortCompare: MUIDataTableNumericSort,
        customHeadLabelRender: (value, tableMeta) => (
          <HeadLabel value={value.label}/>
        ),
        customBodyRender: (value, tableMeta) => {
          return <HeadLabel value={formatter(value)}/>;
        }
      }
    },
    {
      name: "percentRabat",
      label: "Średni rabat",
      options: {
        filter: true,
        sort: true,
        sortCompare: MUIDataTableNumericSort,
        customHeadLabelRender: (value, tableMeta) => (
          <HeadLabel value={value.label}/>
        ),
        customBodyRender: (value, tableMeta) => {
          return <HeadLabel value={value}/>;
        }
      }
    },
    {
      name: "avgVat",
      label: "Średni vat",
      options: {
        filter: true,
        sort: true,
        sortCompare: MUIDataTableNumericSort,
        customHeadLabelRender: (value, tableMeta) => (
          <HeadLabel value={value.label}/>
        ),
        customBodyRender: (value, tableMeta) => {
          return <HeadLabel value={value}/>;
        }
      }
    },
    {
      name: "firstDelivery",
      label: "Pierwsza dostawa",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: (value, tableMeta) => (
          <HeadLabel value={value.label}/>
        ),
        customBodyRender: (value, index) => {
          return <div key={index} style={{ whiteSpace: "nowrap" }}>{value}</div>;
        }
      }
    },
    {
      name: "threeLastDelivery",
      label: " 3 ostatnie dostawy",
      options: {
        sortCompare: (order) => (obj1, obj2) => {
          let val1, val2;

          const compare = (index = 0) => {
            val1 = obj1.data[index] ? parseFloat(obj1.data[index].dataDostawy.replace(/[^\d.]/g, "")) : 0;
            val2 = obj2.data[index] ? parseFloat(obj2.data[index].dataDostawy.replace(/[^\d.]/g, "")) : 0;

            if (val1 === val2) {
              if (index < 3) {
                return compare(index + 1);
              }
            }

            return (val1 - val2) * (order === "asc" ? 1 : -1);
          };

          return compare();
        },
        customHeadLabelRender: (value, tableMeta) => (
          <HeadLabel value={value.label}/>
        ),
        customBodyRender: (value, index) => {
          return (
            <div
              key={index}
              style={{
                display: "flex",
                flexDirection: "column",
                whiteSpace: "nowrap"
              }}
            >
              {value.length
                ? value.map((it, index) => {
                  return (
                    <div style={{ fontSize: "12px" }} key={index}>
                      {it.dataDostawy}
                      {it.qty ? ` (${it.qty} szt.)` : ""}
                    </div>
                  );
                })
                : "-"}
            </div>
          );
        }
      }
    }
  ];

  const handleExpandable = (rowData, rowMeta) => {
    const dataItem = data[rowMeta.dataIndex].result;

    return (
      <TableRow>
        <TableCell
          colSpan={rowData.length + 1}
          className={classes.expandableRow}
        >
          <BestsellerNavigation
            isRaportView={true}
            sygnaturyData={dataItem.sygnatury}
            rozmiaryData={dataItem.towar}
            wartosciData={dataItem.wartosci}
            lokalizacjeData={dataItem.lokalizacje}
            dynamikaData={dataItem.dynamika}
            rabatyData={dataItem.rabaty}
            sezonyData={dataItem.sezony}
            priceData={dataItem.priceCompare}
          />
        </TableCell>
      </TableRow>
    );
  };

  const handleSelected = rowsSelected => {
    let arr = [];

    rowsSelected.forEach(el => {
      const index = el.dataIndex;
      const id = data[index].id;

      arr.push({ id: id, index: index });
    });

    dispatch({
      type: UPDATE_SELECTED_PRODUCT_TABLE_ITEMS,
      payload: arr
    });
  };

  const options = {
    tableBodyMaxHeight: "80vh",
    fixedHeader: true,
    pagination: false,
    expandableRows: true,
    expandableRowsHeader: false,
    expandableRowsOnClick: true,
    viewColumns: true,
    renderExpandableRow: handleExpandable,
    onRowSelectionChange: handleSelected,
    rowsSelected: state.selectedProductTableItems.map(it => it.index),
    download: false,
    print: false,
    selectableRows: editMode ? "multiple" : "none",
    selectToolbarPlacement: "none",
    filter: false,
    textLabels: {
      toolbar: {
        search: "Szukaj",
        viewColumns: "Pokaż kolumny",
        filterTable: "Filtruj tabelę"
      },
      filter: {
        all: "Wszystkie",
        title: "Filtry",
        reset: "Wyczyść"
      },
      viewColumns: {
        title: "Pokaż kolumny",
        titleArea: "Pokaż/Schowaj kolumnę"
      }
    },
    customSearch: (searchQuery, currentRow, columns) => {
      let isFound = false;

      currentRow.forEach(col => {
        if (col?.toString().indexOf(searchQuery) >= 0
          || col?.result?.product.photoSid.toLowerCase().indexOf(searchQuery.toLowerCase()) >= 0) {
          isFound = true;
        }
      });

      return isFound;
    }
  };

  const classes = useStyles();

  return (
    <div className={classes.table}>
      <MUIDataTable data={dataParsed} columns={columns} options={options}/>
    </div>
  );
};

export default ProductReportTable;

const InfoLabel = ({ data, isDraft }) => {
  const item = data.result.product;
  const classes = useStyles();

  return (
    <div className={classes.infoLabelWrapper}>
      <div className={classes.fotoWrapper}>
        <ProductThumbnail photoSrc={item.photo}/>

        {/*<img src={item ? item.photo : null} alt="img" />*/}
      </div>
      <div className={classes.infoWrapper}>
        <div className={classes.nameRow}>
          <CopyToClipboard value={item.photoSid}>
            <Tooltip title={"kopiuj symbol"} placement="right">
              <Typography variant="body1">
                {item ? item.photoSid : null}
                {item ? (item.isCo ? <span className={classes.carryOver}>CARRY OVER</span> : null) : null}
              </Typography>
            </Tooltip>
          </CopyToClipboard>
        </div>
        <div className={classes.infoRow}>
          <Typography variant="subtitle2">
            Kategoria
          </Typography>
          <Typography variant="body1">
            {item && item.category ? getCategory(item.category) : null}
          </Typography>
        </div>
        <div className={classes.infoRow}>
          <Typography variant="subtitle2">
            Linki
          </Typography>
          <Typography variant="body1">
            {item && item.polkaId && item.photo ? (
              <Link
                href={`https://vitkac.com/sp/${item.polkaId}`}
                target="_blank"
                underline="none"
                color="primary"
              >
                Vitkac{" "}
                <LinkIcon fontSize="small" className={classes.linkIcon}/>
              </Link>
            ) : (
              ""
            )}
            {item && item.idFarfetch && item.photo ? (
              <Link
                href={
                  "http://54.37.0.134/r.php?u=https://farfetch.com/pl/shopping/item-" +
                  item.idFarfetch +
                  ".aspx"
                }
                target="_blank"
                underline="none"
                color="primary"
              >
                Farfetch{" "}
                <LinkIcon fontSize="small" className={classes.linkIcon}/>
              </Link>
            ) : (
              ""
            )}
          </Typography>
        </div>
      </div>
    </div>
  );
};
