import React, { useState } from "react";

export const RaportModeContext = React.createContext(false);

export const RaportModeProvider = ({ children }) => {
  const [isRaportMode, setIsRaportMode] = useState(false);

  return (
    <RaportModeContext.Provider value={{ isRaportMode, setIsRaportMode }}>
      {children}
    </RaportModeContext.Provider>
  );
};

// export default RaportModeContext;
