import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import { gql } from "apollo-boost";
import { useMutation, useQuery } from "@apollo/react-hooks";
import TaskLimitInputDialog from "./TaskLimitInputDialog";
import { GET_TASKS_2_RUN } from "./Task2RunTabele";
import { GET_TASK_2_KILL } from "./Task2KillTabele";

const ADD_TASK_2_KILL = gql`
  mutation CreateTask2Kill($command: String!) {
    createTask2Kill(input: { command: $command }) {
      task2Kill {
        id
        command
      }
    }
  }
`;

const ADD_TASK_2_RUN = gql`
  mutation CreateTask2Run($command: String!) {
    createTask2Run(input: { command: $command }) {
      task2Run {
        id
        command
        log
        afterDate
      }
    }
  }
`;

const UPDATE_TASK_ACTIVITY = gql`
  mutation updateTask($id: ID!, $isLocked: Boolean) {
    updateTask(input: { id: $id, isLocked: $isLocked }) {
      task {
        id
        command
        name
        isLocked
        lastCall
        endTask
        repeatEveryMin
        everydayTimeStart
        log
      }
    }
  }
`;

const GET_TASKS = gql`
  {
    tasks {
      id
      command
      name
      isLocked
      lastCall
      endTask
      repeatEveryMin
      everydayTimeStart
      log
    }
  }
`;

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 100,
    "& .MuiTableCell-body": {
      whiteSpace: "nowrap"
    },
    "& .MuiTableCell-head": {
      whiteSpace: "nowrap"
    }
  },
  maxWidth: {
    width: "100%"
  },
  actions: {
    minWidth: "170px"
  }
}));

function createData(
  id,
  command,
  name,
  isLocked,
  lastCall,
  endTask,
  repeatEveryMin,
  everydayTimeStart,
  log
) {
  return {
    id,
    command,
    name,
    isLocked,
    lastCall,
    endTask,
    repeatEveryMin,
    everydayTimeStart,
    log
  };
}
export const TasksTabele = () => {
  const classes = useStyles();
  const [openDialog, setOpenDialog] = useState(false);
  const [item, setItem] = useState(null);

  const taskLimitInputDialot = (
    <TaskLimitInputDialog
      open={openDialog}
      setOpen={setOpenDialog}
      item={item}
    ></TaskLimitInputDialog>
  );

  const { loading, error, data } = useQuery(GET_TASKS);

  const [updateTask] = useMutation(UPDATE_TASK_ACTIVITY, {
    update(cache, { data }) {
      const { tasks } = cache.readQuery({ query: GET_TASKS });

      cache.writeQuery({
        query: GET_TASKS,
        data: {
          tasks: tasks.map(it => {
            if (it.id === data.updateTask.task.id) {
              return data.updateTask.task;
            }
            return it;
          })
        }
      });
    }
  });

  const [saveTask2Kill] = useMutation(ADD_TASK_2_KILL, {
    update(cache, { data }) {
      const { task2Kills } = cache.readQuery({ query: GET_TASK_2_KILL });
      cache.writeQuery({
        query: GET_TASK_2_KILL,
        data: {
          task2Kills: [...task2Kills, data.createTask2Kill.task2Kill]
        }
      });
    }
  });

  const [saveTask2Run] = useMutation(ADD_TASK_2_RUN, {
    update(cache, { data }) {
      const { task2Runs } = cache.readQuery({ query: GET_TASKS_2_RUN });
      cache.writeQuery({
        query: GET_TASKS_2_RUN,
        data: {
          task2Runs: [...task2Runs, data.createTask2Run.task2Run]
        }
      });
    }
  });

  if (loading) return <>Loading</>;
  if (error) return <>Błąd ładowania taksów</>;

  const rows = data.tasks.map(it =>
    createData(
      it.id,
      it.command,
      it.name,
      it.isLocked,
      it.lastCall,
      it.endTask,
      it.repeatEveryMin,
      it.everydayTimeStart,
      it.log
    )
  );

  const addTask2Run = item => {
    saveTask2Run({
      variables: {
        command: item.command
      }
    }).catch(err => console.log(err));
  };

  const addTask2Kill = item => {
    updateTask({
      variables: {
        id: item.id,
        isLocked: !item.isLocked
      }
    });

    saveTask2Kill({
      variables: {
        command: item.command
      }
    })
      .then(console.log("sucess"))
      .catch(err => console.log(err));
  };

  const handleOpen = item => {
    setItem(item);
    setOpenDialog(true);
  };

  return (
    <TableContainer>
      <Table className={classes.table} aria-label="simple table" size="small">
        <TableHead>
          <TableRow>
            <TableCell align="left">Id</TableCell>
            <TableCell align="left">Command</TableCell>
            <TableCell align="left">Name</TableCell>
            <TableCell align="left">Last call</TableCell>
            <TableCell align="left">Task's end</TableCell>
            <TableCell align="left">Repeat every [min]</TableCell>
            <TableCell align="left">Everyday time start</TableCell>
            <TableCell align="left">Path to log</TableCell>
            <TableCell align="right">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow key={index}>
              <TableCell align="left">{row.id}</TableCell>
              <TableCell align="left">{row.command}</TableCell>
              <TableCell align="left">{row.name}</TableCell>
              <TableCell align="left">{row.lastCall}</TableCell>
              <TableCell align="left">{row.endTask}</TableCell>
              <TableCell align="left">{row.repeatEveryMin}</TableCell>
              <TableCell align="left">{row.everydayTimeStart}</TableCell>
              <TableCell align="left">{row.log}</TableCell>
              <TableCell align="right" className={classes.actions}>
                <Grid
                  container
                  direction="row"
                  spacing={1}
                  justifyContent="flex-end"
                >
                  <Grid item>
                    {row.isLocked ? (
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={e => addTask2Kill(row)}
                      >
                        End
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.startTaskButton}
                        onClick={e => addTask2Run(row)}
                      >
                        Start
                      </Button>
                    )}
                  </Grid>
                  <Grid item>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => handleOpen(row)}
                    >
                      Logs
                    </Button>
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {openDialog ? taskLimitInputDialot : ""}
    </TableContainer>
  );
};
