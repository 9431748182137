import React, { useState } from "react";
import { Button, makeStyles, Tooltip } from "@material-ui/core";
import ArrowRightAltRoundedIcon from "@material-ui/icons/ArrowRightAltRounded";
import { Link } from "react-router-dom";
import DeleteIcon from "@material-ui/icons/DeleteForeverRounded";
import PublishIcon from "@material-ui/icons/PublishRounded";
import ConfirmRemoveModal from "../shared/ConfirmRemoveModal";
import {
  removeReport,
  reportCompletedStatus
} from "../../../_services/_raportsService";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ERROR, SUCCESS } from "../../../_actions/alertsActions";
import NoteOutlinedIcon from '@material-ui/icons/NoteOutlined';
import IconButton from "../../Common/IconButton";
import NoteDrawer from "./NoteDrawer";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: theme.spacing(1.5)
  },
  rightActions: {
    display: "flex",
    flexDirection: "row",
    gap: "10px"
  }
}));

const Actions = ({ reportId, note }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const handlePublishReport = () => {
    reportCompletedStatus(reportId)
      .finally(() => {
        dispatch({ type: SUCCESS, payload: "Raport opublikowany pomyślnie" });
        history.push("/raports/draft");
      })
      .catch(err => {
        dispatch({
          type: ERROR,
          payload: "Błąd publikacji raportu, Spróbuj ponownie"
        });
      });
  };

  const handleRemove = () => {
    removeReport(reportId)
      .then(res => {
        if (res.status === 204) {
          setOpenDrawer(false);
        }
      })
      .finally(() => {
        history.push("/raports/draft");
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <Link to="/raports/draft">
        <Button variant="outlined" color="primary">
          <ArrowRightAltRoundedIcon style={{ transform: "rotate(180deg)" }} />
          WRÓĆ DO LISTY
        </Button>
      </Link>

      <div className={classes.rightActions}>
        <Tooltip title="Usuń wersję roboczą">
          <IconButton onClick={() => setOpen(true)} Icon={DeleteIcon} color="red"/>
        </Tooltip>

        <Button variant="outlined" color="primary" onClick={() => setOpenDrawer(true)}>
          <NoteOutlinedIcon fontSize="medium"/>
          {note ? 'EDYTUJ NOTATKĘ' : 'DODAJ NOTATKĘ'}
        </Button>

        <Button
          variant="contained"
          color="primary"
          onClick={() => handlePublishReport()}
        >
          <PublishIcon fontSize="medium" />
          OPUBLIKUJ
        </Button>
      </div>

      <ConfirmRemoveModal
        open={open}
        handleClose={handleClose}
        handleConfirm={handleRemove}
        customTitle={"Czy na pewno chcesz trwale usunąć wersję roboczą raportu?"}
      />

      <NoteDrawer
        open={openDrawer}
        setOpen={setOpenDrawer}
        reportId={reportId}
        note={note}
        isEditable={true}
      />
    </div>
  );
};

export default Actions;
