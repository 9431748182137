import React, { Fragment, useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { CategoryItem, CategoryItemDisabled } from "./CategoryItem";
import { CategoryChip } from "./CategoryChip";
import { Grid, Paper, Typography } from "@material-ui/core";
import { filterContext } from "../../_context/filterContext";

const useStyles = makeStyles(theme => ({
  root: {
    ...theme.filterPaper,
    minWidth: "750px"
  },
  selects: {
    "& .MuiGrid-item:first-of-type": {
      paddingLeft: 0
    },
    "& .MuiGrid-item:last-of-type": {
      paddingRight: 0
    }
  }
}));

export const CategoryFilter = () => {
  const classes = useStyles();

  const {
    state: { category, _update }
  } = useContext(filterContext);

  const [selectedCategory, setSelectedCategory] = useState({});

  const [submited, setSubmited] = useState(
    category.length ? category.split(",").map(it => parseInt(it, 10)) : []
  );

  const [level, setLevel] = useState(1);
  const [emptyLevel1, setEmptyLevel1] = useState(false);

  const submitCategory = e => {
    setEmptyLevel1(true);
    e.preventDefault();
    e.stopPropagation();

    if (Object.entries(selectedCategory).length !== 0) {
      setSubmited([...submited, parseInt(selectedCategory.id)]);
      setLevel(1);
    }

    setSelectedCategory({});
  };

  useEffect(() => {
    setSubmited(
      category.length ? category.split(",").map(it => parseInt(it, 10)) : []
    );
    return () => {};
  }, [category, _update]);

  const catComponent = [1, 2, 3, 4, 5].map(it => {
    return level >= it ? (
      <CategoryItem
        key={it}
        level={it}
        setSelectedCategory={setSelectedCategory}
        setLevel={setLevel}
        selectedCategory={selectedCategory}
        emptyLevel1={emptyLevel1}
        setEmptyLevel1={setEmptyLevel1}
      />
    ) : (
      <CategoryItemDisabled key={`disabled=${it}`} />
    );
  });

  return (
    <Fragment>
      <Paper className={classes.root}>
        <Typography variant="h2">Kategorie</Typography>

        <Grid container direction="column" alignItems="center" spacing={2}>
          <Grid container item xs={12} spacing={3} className={classes.selects}>
            {submited.length ? (
              <CategoryChip submited={submited} setSubmited={setSubmited} />
            ) : (
              ""
            )}
          </Grid>
          <Grid container item xs={12} spacing={3} className={classes.selects}>
            {catComponent}
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={e => submitCategory(e)}
            >
              Dodaj kategorię
            </Button>
          </Grid>
        </Grid>

        <input type="hidden" name="category" value={submited} />
      </Paper>
    </Fragment>
  );
};
