import { Select, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import ArrowForwardIosRoundedIcon from "@material-ui/icons/ArrowForwardIosRounded";
import FirstPageRoundedIcon from "@material-ui/icons/FirstPageRounded";
import LastPageRoundedIcon from "@material-ui/icons/LastPageRounded";
import React, { useContext } from "react";
import {
  filterContext,
  SET_LIMIT,
  SET_PAGE
} from "../../_context/filterContext";
import {
  filterContextDevstat,
  SET_LIMIT_DEVSTAT,
  SET_PAGE_DEVSTAT
} from "../../_context/filterContextDevstat";

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: "3px"
  },
  formControl: {
    marginRight: theme.spacing(3),
    minWidth: 30,
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid rgba(132, 132, 132, 0.13)",
      borderRadius: 0
    }
  },
  button: {
    borderRadius: 0,
    lineHeight: 1.7,
    boxSizing: "border-box",
    minWidth: 0,
    backgroundColor: "#323232",
    padding: "9px 4px",
    "&:disabled": {
      backgroundColor: "#e0e0e0"
    }
  },
  buttonIcon: {
    height: "12px",
    margin: "0 !important",
    fontSize: "24px !important"
  },
  paginationInput: theme.paginationSelect.input,
  paginationLabel: {
    marginRight: theme.spacing(1)
  }
}));

export const Pagination = ({
  totalPages,
  limitOptions = [5, 10, 15],
  context
}) => {
  const classes = useStyles();

  const {
    dispatch,
    state: { page, limit }
  } = useContext(context);

  const hasNextPage = !!(totalPages < parseInt(page) + 1);
  const hasPrevPage = !!(parseInt(page) > 1);

  const setPage = page => {
    switch (context) {
      case filterContext:
        dispatch({ type: SET_PAGE, payload: page });
        break;
      case filterContextDevstat:
        dispatch({ type: SET_PAGE_DEVSTAT, payload: page });
        break;
      default:
    }
  };

  const setLimit = (limit, e) => {
    e.preventDefault();
    e.stopPropagation();
    localStorage.setItem("limitPage", limit);

    switch (context) {
      case filterContext:
        dispatch({ type: SET_LIMIT, payload: limit });
        break;
      case filterContextDevstat:
        dispatch({ type: SET_LIMIT_DEVSTAT, payload: limit });
        break;
      default:
    }
  };

  const setPrev = e => {
    e.stopPropagation();
    e.preventDefault();
    var prevPage = parseInt(page) > 1 ? parseInt(page) - 1 : parseInt(page);
    setPage(prevPage);
  };

  const setNext = e => {
    e.stopPropagation();
    e.preventDefault();
    var nextPage = parseInt(page) + 1;
    setPage(nextPage);
  };

  const setFirst = e => {
    e.stopPropagation();
    e.preventDefault();
    setPage(1);
  };

  const setLast = e => {
    e.stopPropagation();
    e.preventDefault();
    setPage(totalPages);
  };

  const pageOptions = [...Array(totalPages)].map((it, index) => {
    return (
      <option key={index} value={index + 1}>
        {index + 1}
      </option>
    );
  });

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="flex-end"
      className={classes.root}
    >
      <Grid item>
        <Grid container direction="row" alignItems="center">
          <Grid item className={classes.noMargin}>
            <Typography variant="body1" className={classes.paginationLabel}>
              Wyświetl
            </Typography>
          </Grid>
          <Grid item>
            <FormControl
              style={{ minWidth: 30 }}
              variant="outlined"
              className={classes.formControl}
            >
              <Select
                native
                placeholder="Wybierz"
                value={limit}
                onChange={event => {
                  setLimit(parseInt(event.target.value, 10), event);
                  setPage(1);
                }}
                classes={{ root: classes.paginationInput }}
              >
                {limitOptions.map(lim => {
                  const id = `option-${lim}`;
                  return (
                    <option key={id} value={lim}>
                      {lim}
                    </option>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <Grid container direction="row" alignItems="center">
          <Grid item>
            <Typography variant="body1" className={classes.paginationLabel}>
              Strona:
            </Typography>
          </Grid>
          <Grid item>
            <FormControl
              style={{ minWidth: 30 }}
              variant="outlined"
              className={classes.formControl}
            >
              <Select
                native
                placeholder="Wybierz"
                value={page ? page : 1}
                onChange={e => {
                  setPage(e.target.value);
                }}
                classes={{ root: classes.paginationInput }}
              >
                {pageOptions}
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <Button
              onClick={e => setFirst(e)}
              variant="contained"
              color="primary"
              className={classes.button}
              disabled={!hasPrevPage}
            >
              <FirstPageRoundedIcon className={classes.buttonIcon} />
            </Button>
            <Button
              onClick={e => setPrev(e)}
              variant="contained"
              color="primary"
              className={classes.button}
              disabled={!hasPrevPage}
            >
              <ArrowBackIosRoundedIcon className={classes.buttonIcon} />
            </Button>
            <Button
              onClick={e => setNext(e)}
              variant="contained"
              color="primary"
              className={classes.button}
              disabled={hasNextPage}
            >
              <ArrowForwardIosRoundedIcon className={classes.buttonIcon} />
            </Button>
            <Button
              onClick={e => setLast(e)}
              variant="contained"
              color="primary"
              className={classes.button}
              disabled={hasNextPage}
            >
              <LastPageRoundedIcon className={classes.buttonIcon} />
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
