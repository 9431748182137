import React, { useContext } from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import TablePlaceholder from "../../TablePlaceholder";
import SaleKategorieChart from "./SaleKategorieChart";
import KategorieTable from "./SaleKategorieTable";
import { Checkbox } from "@material-ui/core";
import { RaportModeContext } from "../../../_context/raportModeContext";
import { RaportContext } from "../../../_context/raportContext";
import {
  ADD_TO_SELECTED_CATEGORIES_LEVEL2,
  REMOVE_FROM_SELECTED_CATEGORIES_LEVEL2
} from "../../../_reducers/raportReducer";

const useStyles = makeStyles(theme => ({
  root: {
    padding: "0 0 12px",
    "& .MuiTableContainer-root": {
      margin: "18px 12px 0",
      width: "calc(100% - 42px)"
    },
    "& .tablePlaceholder > div": {
      boxShadow: "none",
      backgroundColor: "transparent"
    },
    "& .MuiTablePagination-root": {
      margin: "0 32px"
    }
  },
  chartSkeleton: {
    "& .tablePlaceholder": {
      marginTop: "-16px"
    },
    "& .MuiLinearProgress-colorPrimary": {
      display: "none"
    }
  },
  buttons: {
    marginTop: theme.spacing(1)
  },
  paper: {
    ...theme.tablePaper,
    marginTop: "24px",
    border: "1px solid #37474f",
    boxShadow: "none",
    borderRadius: 0
  },
  header: {
    backgroundColor: "#37474f",
    color: "white",
    textTransform: "uppercase"
  },
  checkbox: {
    position: "absolute"
  }
}));

export const SaleKategorieViewComponent = ({ isLoading, data, label }) => {
  const classes = useStyles();
  const { isRaportMode } = useContext(RaportModeContext);
  const { dispatch } = useContext(RaportContext);

  const handleAddToRaportLevel2 = e => {
    const checked = e.target.checked;
    const arr = data.map(it => it.p2Id);

    if (checked) {
      dispatch({ type: ADD_TO_SELECTED_CATEGORIES_LEVEL2, payload: arr });
    } else {
      dispatch({ type: REMOVE_FROM_SELECTED_CATEGORIES_LEVEL2, payload: arr });
    }
  };

  return (
    <Paper className={classes.paper}>
      <div
        className={classes.header}
        style={{ padding: isRaportMode ? "0px" : "7px 10px 9px" }}
      >
        <Typography variant="h3">
          {isRaportMode ? (
            <Checkbox
              style={{ color: "#ffffff" }}
              onChange={handleAddToRaportLevel2}
            />
          ) : null}

          {label}
        </Typography>
      </div>
      <div className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {isLoading ? (
              <TablePlaceholder />
            ) : data.length ? (
              <KategorieTable data={data}></KategorieTable>
            ) : (
              ""
            )}
          </Grid>
          <Grid item xs={12} className={classes.chartSkeleton}>
            {isLoading ? (
              <TablePlaceholder />
            ) : data.length ? (
              <SaleKategorieChart data={data} />
            ) : (
              ""
            )}
          </Grid>
        </Grid>
      </div>
    </Paper>
  );
};
