import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import React, { useState } from "react";
import { AutoSizer, List } from "react-virtualized";
import { InputAdornment } from "@material-ui/core";
import HighlightOffRoundedIcon from "@material-ui/icons/HighlightOffRounded";

const useStyles = makeStyles(theme => ({
  cardHeader: {
    //padding: theme.spacing(1, 1)
  },
  card: {
    padding: "8px"
  },
  buttons: {
    width: "80px"
  },
  list: {
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: "4px",
    "&:hover": {
      border: "1px solid black"
    },
    "&:focus": {
      outline: "none"
    }
  },
  listItem: {
    boxSizing: "border-box",
    padding: "8px 11px",
    fontSize: "0.85em",
    transition: "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: theme.palette.gray,
      transition: "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
    }
  },
  textField: theme.inputClearButton,
  button: {
    margin: theme.spacing(0.5, 0)
  },
  listContainer: {
    padding: "0!important"
  }
}));

const SelectedList = ({ selected, setSelected, data, keyName, nameKey }) => {
  const classes = useStyles();

  const handleSelect = value => {
    setSelected([...selected, value._id]);
  };

  const handleUnSelect = value => {
    setSelected(selected.filter(it => it !== value._id));
  };

  return (
    <Grid
      container
      direction="row"
      spacing={2}
      justifyContent="center"
      alignItems="center"
      className={classes.root}
    >
      <Grid item xs className={classes.listContainer}>
        <CustomList
          keyName={keyName + "-to-select"}
          items={data.filter(it => selected.indexOf(it._id) === -1)}
          classes={classes}
          selected={selected}
          setSelected={setSelected}
          handleClick={handleSelect}
          nameKey={nameKey}
        />
      </Grid>

      <Grid item className={classes.buttons}>
        <Button
          variant="outlined"
          color="secondary"
          onClick={e => setSelected([])}
        >
          {"<<"}
        </Button>
      </Grid>

      <Grid item xs className={classes.listContainer}>
        <CustomList
          keyName={keyName + "-selected"}
          items={data.filter(it => selected.indexOf(it._id) > -1)}
          classes={classes}
          selected={selected}
          setSelected={setSelected}
          handleClick={handleUnSelect}
          nameKey={nameKey}
        />
      </Grid>
    </Grid>
  );
};
export default SelectedList;

const CustomList = ({ items, classes, handleClick, keyName, nameKey }) => {
  const [query, setQuery] = useState("");

  const data = items.filter(it =>
    query && query.length
      ? it[nameKey].toUpperCase().includes(query.toUpperCase())
      : it
  );

  const rowRenderer = ({
    key, // Unique key within array of rows
    index, // Index of row within collection
    isScrolling, // The List is currently being scrolled
    isVisible, // This row is visible within the List (eg it is not an overscanned row)
    style // Style object to be applied to row (to position it)
  }) => {
    return (
      <div
        key={key}
        style={style}
        className={classes.listItem}
        onClick={e => handleClick(data[index])}
      >
        {data[index][nameKey]}
      </div>
    );
  };

  return (
    <Card key={keyName} className={classes.card}>
      <CardHeader
        className={classes.cardHeader}
        avatar={
          <TextField
            id={"search-input-" + keyName}
            label="Szukaj"
            value={query}
            variant="outlined"
            onChange={event => setQuery(event.target.value)}
            key={"input-key-" + keyName}
            classes={{ root: classes.textField }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <HighlightOffRoundedIcon onClick={e => setQuery("")} />
                </InputAdornment>
              )
            }}
            fullWidth
          />
        }
      />
      <AutoSizer disableHeight>
        {({ width }) => (
          <List
            width={width}
            height={250}
            rowCount={data.length}
            rowHeight={35}
            rowRenderer={rowRenderer}
            className={classes.list}
          />
        )}
      </AutoSizer>
    </Card>
  );
};
