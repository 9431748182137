import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import React, { useContext, useEffect, useState } from "react";
import { filterContext } from "../../_context/filterContext";
import SelectedList from "./SelectedList";
import { makeStyles, Paper, Typography, Button, Grid } from "@material-ui/core";
import { Indicator } from "../Indicator";

const GET_SYGNATURY = gql`
  {
    sygnaturas {
      _id
      name
      isShow
    }
  }
`;

const useStyles = makeStyles(theme => ({
  root: theme.filterPaper
}));

export const SygnaturaFilter = () => {
  const classes = useStyles();
  const [isShow, setIsShow] = useState(false);
  const { loading, error, data } = useQuery(GET_SYGNATURY);

  const {
    state: { sygnaturas, _update }
  } = useContext(filterContext);

  const [selected, setSelected] = useState(
    sygnaturas.length ? sygnaturas.split(",").map(it => parseInt(it, 10)) : ""
  );

  useEffect(() => {
    setSelected(
      sygnaturas.length ? sygnaturas.split(",").map(it => parseInt(it, 10)) : ""
    );
    return () => {};
  }, [sygnaturas, _update]);

  if (loading) return <Indicator centerVertically height="380px"></Indicator>;
  if (error) return "Błąd ładowania sygnatur";

  const showHiddenSygnatura = data => {
    setIsShow(!isShow);
  };

  return (
    <Paper className={classes.root}>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Typography variant="h2">Sygnatura</Typography>
        </Grid>
        <Grid item>
          <Button
            onClick={e => showHiddenSygnatura(data)}
            variant="contained"
            color="primary"
          >
            {isShow ? "Ukryj Archiwalne" : "Pokaz Archiwalne"}
          </Button>
        </Grid>
      </Grid>
      <input type="hidden" value={selected} name="sygnaturas" />
      <SelectedList
        data={
          isShow ? data.sygnaturas : data.sygnaturas.filter(it => it.isShow)
        }
        selected={selected}
        setSelected={setSelected}
        keyName={"sygnaturas"}
        nameKey={"name"}
      />
    </Paper>
  );
};
