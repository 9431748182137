import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import React, { useContext, useEffect, useState } from "react";
import { filterContext } from "../../../_context/filterContext";
import { getSygnatury } from "../../../_services/_bestsellerService";
import { ProgressBarComponent } from "../../ProgressBarComponent";
import TableComponent from "../components/TableComponent";
import TablePlaceholder from "../../../_components/TablePlaceholder";

const useStyles = makeStyles(theme => ({
  chart: {
    paddingLeft: theme.spacing(2),
    justifyContent: "flex-start",
    alginItems: "flex-start"
  }
}));

export const SygnaturyComponent = ({ switchValue, id, sygnaturies }) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);

  const { state } = useContext(filterContext);
  const [sygnatures, setSygnatures] = useState(
    sygnaturies && sygnaturies.total ? sygnaturies.total : []
  );
  const [sygnaturesType, setSygnaturesType] = useState(
    sygnaturies && sygnaturies.type ? sygnaturies.type : []
  );

  const setUpSygnaturies = syg => {
    setIsLoading(syg ? setIsLoading(false) : setIsLoading(true));
    setSygnatures(syg && syg.total ? syg.total : []);
    setSygnaturesType(syg && syg.type ? syg.type : []);
  };

  useEffect(() => {
    setIsLoading(true);
    if (!switchValue) {
      getSygnatury({ isPaid: 1 }, id)
        .then(res => {
          setUpSygnaturies(res.data);
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setUpSygnaturies(sygnaturies);
    }
  }, [state, id, switchValue, sygnaturies]);

  const headers = [
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "Sygnatura"
    },
    {
      id: "total",
      numeric: true,
      disablePadding: false,
      label: "Sprzedane - Zwrócone [szt.]"
    },
    {
      id: "sell",
      numeric: true,
      disablePadding: false,
      label: "Sprzedane [szt.]"
    },
    {
      id: "returned",
      numeric: true,
      disablePadding: false,
      label: "Zwrócone [szt.]"
    }
  ];

  function createData(name, total, sell, returned) {
    return { name, total, sell, returned };
  }

  const rows = sygnatures.map(it =>
    createData(
      it.name,
      parseInt(it.qtyTotal),
      parseInt(it.qtySell),
      parseInt(it.qtyReturned)
    )
  );

  return (
    <>
      <Grid item xs={8}>
        {isLoading ? (
          <TablePlaceholder />
        ) : (
          <TableComponent
            headCellsData={headers}
            rowsData={rows}
          ></TableComponent>
        )}
      </Grid>
      <Grid item xs={4} className={classes.chart}>
        {isLoading ? (
          <TablePlaceholder />
        ) : (
          <ProgressBarComponent type={sygnaturesType}></ProgressBarComponent>
        )}
      </Grid>
    </>
  );
};
