import React, { useState, useEffect } from "react";
import {
  getAllUserAutosuggestion,
  getAllUsersAddedToReport,
  addMultipleUsersToReport,
  removeUserFromReport
} from "../../../_services/_raportsService";
import { makeStyles } from "@material-ui/styles";
import ClearIcon from "@material-ui/icons/Clear";
import { useDispatch } from "react-redux";
import { SUCCESS, ERROR } from "../../../_actions/alertsActions";
import { Drawer, Chip, Select, MenuItem, OutlinedInput, IconButton, Button, Popover } from "@material-ui/core";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Grid from "@material-ui/core/Grid";
import DrawerHeader from "../shared/DrawerHeader";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
  container: {
    width: "520px",
    maxWidth: "520px",
    height: "100%",
    padding: theme.spacing(2),

    "& .MuiInputBase-root": {
      borderRadius: "0"
    }
  },
  sharedUsersWrapper: {
    border: "1px solid #E2E2E2",
    padding: theme.spacing(1.5),
    "&:not(:last-child)": {
      borderBottom: "none"
    }
  },
  menuItem: {
    borderBottom: "1px solid #E2E2E2",
    padding: theme.spacing(1.5)
  }
}));

const MultiSelectChip = ({ value, handleDelete, ...props }) => (
  <Chip
    {...props}
    variant="outlined"
    color="primary"
    size="small"
    label={value.email}
    onDelete={() => null}
    deleteIcon={
      <ClearIcon
        color="primary"
        fontSize="small"
        onMouseDown={e => {
          e.stopPropagation();
          handleDelete();
        }}
      />
    }
  />
);


const ShareDrawer = ({ open, onClose, reportId }) => {
  const classes = useStyles();
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [sentRaport, setSentRaport] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    getUsers();
  }, [sentRaport]);

  const getUsers = () => {
    getAllUserAutosuggestion().then(res => {
      if (res.status === 200) {
        setUsers(res.data);
        setIsLoading(false);
      }
    });
  };

  const removeUser = userID => {
    removeUserFromReport(reportId, { userId: userID })
      .then(res => {
        if (res.status === 204) {
          setSentRaport(sentRaport.filter(e => e.id !== userID));
          dispatch({
            type: SUCCESS,
            payload: "Dostęp do raportu odebrany poprawnie"
          });
        }
      })
      .catch(() => {
        dispatch({
          type: ERROR,
          payload: "Błąd podczas odbierania uprawnień do raportu"
        });
      });
  };

  if (isLoading) return null;

  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <Grid container className={classes.container} direction="column" alignItems="stretch" spacing={2}>
        <Grid item>
          <DrawerHeader title="Udostępnij raport" onClose={onClose}/>
        </Grid>

        <Grid item>
          <MultipleSelect
            usersList={users}
            sentRaport={sentRaport}
            setSentRaport={setSentRaport}
            reportId={reportId}
          />
        </Grid>

        {users.length > 0 && sentRaport.length > 0 && (
          <>
            <Grid item></Grid>
            <Grid item>
              <Typography variant="h5"><strong>Udostępniono</strong></Typography>
            </Grid>

            <Grid item container direction="column">
              {sentRaport.map((it, i) => (
                <Grid item container direction="row" justifyContent="space-between" alignItems="center" key={i}
                      className={classes.sharedUsersWrapper}>
                  <Grid item xs container direction="column" justifyContent="flex-start" alignItems="flex-start">
                    <Grid item>
                      <Typography variant="h6"><strong>{it.name}</strong></Typography>
                    </Grid>
                    <Grid item style={{ color: "gray", fontSize: 12 }}>
                      {it.email}
                    </Grid>
                  </Grid>

                  <Grid item>
                    <PopupState variant="popover">
                      {popupState => (
                        <>
                          <IconButton{...bindTrigger(popupState)}>
                            <MoreVertIcon/>
                          </IconButton>
                          <Popover
                            {...bindPopover(popupState)}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "center"
                            }}
                          >
                            <Button onClick={() => removeUser(it.id)}>
                              Usuń
                            </Button>
                          </Popover>
                        </>
                      )}
                    </PopupState>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </>
        )}
      </Grid>
    </Drawer>
  );
};

export default ShareDrawer;

const MultipleSelect = ({ usersList, sentRaport, setSentRaport, reportId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    if (selected.length === 0 && isLoading) {
      getAllUsersAddedToReport(reportId).then(res => {
        let temp = [];
        res.data.map(e =>
          temp.push({ id: e.id, email: e.email, name: e.name })
        );
        setSentRaport(temp);
        setIsLoading(false);
      });
    }
  });

  if (isLoading) return null;

  const handleSelect = obj => {
    const found = selected.some(e => e.id === obj.id);

    if (!found) {
      handleAddUser(obj);
    } else {
      handleDelete(obj);
    }
  };

  const handleAddUser = obj => {
    setSelected([
      ...selected,
      { id: obj.id, email: String(obj.email), name: String(obj.name) }
    ]);
  };

  const handleDelete = obj => {
    setSelected(selected.filter(e => e.id !== obj.id));
  };

  const sendUsers = () => {
    let usersIDs = "";
    let usersArray = [];

    selected.forEach((e, i) => {
      const found = sentRaport.some(el => el.id === e.id);

      if (!found) {
        if (selected.length - 1 > i) {
          usersIDs = usersIDs + e.id + ", ";
        } else {
          usersIDs = usersIDs + e.id;
        }
        usersArray.push({ id: e.id, email: e.email, name: e.name });
      } else {
        dispatch({
          type: ERROR,
          payload: "Raport został już udostępniony temu użytkownikowi: " + e.email
        });
      }
    });

    if (usersIDs.length > 0) {
      addMultipleUsersToReport(reportId, usersIDs)
        .then(res => {
          if (res.status === 200) {
            setSentRaport([...sentRaport].concat(usersArray));
            dispatch({
              type: SUCCESS,
              payload: "Raport udostępniony poprawnie"
            });
            setSelected([]);
          }
        })
        .catch(() => {
          dispatch({ type: ERROR, payload: "Błąd udostępniania raportu" });
        });
    }
  };

  return (
    <Grid container direction="column" alignItems="stretch" spacing={2}>
      <Grid item>
        <Select
          multiple
          value={selected}
          placeholder="Dodaj osoby"
          onClick={e => e.stopPropagation()}
          variant="outlined"
          input={<OutlinedInput fullWidth/>}
          renderValue={e => e.map((value, index) => (
            <MultiSelectChip
              key={index}
              value={value}
              handleDelete={() => handleDelete(usersList.filter(it => it.id === value.id)[0])}
            />
          ))}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center"
            },
            PaperProps: {
              square: true,
            },
            getContentAnchorEl: null
          }}
        >
          <div style={{ maxHeight: "50vh" }}>
            {usersList.map(it => (
              <MenuItem key={it.id} value={it.email} onClick={() => handleSelect(it)} disableGutters
                        className={classes.menuItem}>
                <Grid container direction="column" alignItems="stretch">
                  <Grid item>
                    <Typography variant="h5"><strong>{it.name}</strong></Typography>
                  </Grid>
                  <Grid item>
                    <div style={{ color: "gray", fontSize: 12 }}>{it.email}</div>
                  </Grid>
                </Grid>
              </MenuItem>
            ))}
          </div>
        </Select>
      </Grid>

      <Grid item container justifyContent="space-between">
        <Grid item>
          <Button variant="contained" color="primary" onClick={() => sendUsers()}>
            Udostępnij
          </Button>
        </Grid>
        <Grid item>
          <Button variant="outlined" color="primary" onClick={() => setSelected([])}>
            ANULUJ
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
