import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { makeStyles, Grid, Typography } from "@material-ui/core";
import PublishedTable from "./PublishedTable";
import useReportListByType from "../../../_hooks/Reports/useReportListByType";
import { getSharedReportsUser } from "../../../_services/_raportsService";
import ReportPlaceholder from "../shared/ReportPlaceholder";

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.headerHeight,
    backgroundColor: theme.palette.gray,
    flexGrow: 1,
    minHeight: `calc(100vh - ${theme.headerHeight} * 2)`,
    padding: theme.spacing(1.5),
    boxSizing: "border-box"
  },
  placeholderPaper: theme.placeholderPaper,
  paper: theme.bestsellerPaper
}));

const PublishedView = props => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Helmet>
        <meta charSet="utf-8"/>
        <title>Raporty</title>
      </Helmet>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <OwnReports/>
        </Grid>
        <Grid item xs={12}>
          <ShareReports/>
        </Grid>
      </Grid>
    </div>
  );
};

export default PublishedView;

const OwnReports = () => {
  const { data, isLoading, reload } = useReportListByType("completed");

  if (isLoading) return <ReportPlaceholder/>;

  return data ? (
    <Grid container spacing={1} direction="column">
      <Grid item>
        <Typography variant="h3">Raporty własne</Typography>
      </Grid>
      <Grid item>
        <PublishedTable data={data} removable={true} onDelete={() => reload()}/>
      </Grid>
    </Grid>
  ) : <></>;
};

const ShareReports = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getSharedReportsUser().then(res => {
      setData(res.data);
      setIsLoading(false);
    });
  }, []);

  if (isLoading) return <ReportPlaceholder/>;

  return data ? (
    <Grid container spacing={1} direction="column">
      <Grid item>
        <Typography variant="h3">Raporty udostępnione</Typography>
      </Grid>
      <Grid item>
        <PublishedTable data={data}/>
      </Grid>
    </Grid>
  ) : <></>;
};
