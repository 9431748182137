import React, { useLayoutEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    photoFullView: {
      position: "absolute",
      borderRadius: "3px",
      border: `1px solid ${theme.palette.border}`,
      top: "-100%",
      width: "400px",
      height: "auto",
      zIndex: 999
    },
    thumbnailContainer: {
      position: "relative",
      width: "100%",
      height: "100%"
    },
    thumbnail: {
      height: "100%"
    }
  }
));

const ProductThumbnail = ({ photoSrc, thumbnailClass}) => {
  const [photoWidth, setPhotoWidth] = useState(0);

  const ref = useRef();

  // eslint-disable-next-line
  useLayoutEffect(() => {
    const width = ref.current.getBoundingClientRect().width;

    if (width !== photoWidth) {
      setPhotoWidth(width);
    }
  });

  const classes = useStyles(photoWidth);
  const [isShown, setIsShown] = useState(false);

  return (
    <div className={classes.thumbnailContainer}>
      <img
        onMouseEnter={() => setIsShown(true)}
        onMouseLeave={() => setIsShown(false)}
        src={photoSrc.length ? photoSrc : process.env.PUBLIC_URL + "no-img.png"}
        className={`${thumbnailClass} ${classes.thumbnail}`}
        alt="Product"
        ref={ref}
      />
      {isShown && photoWidth && photoSrc.length > 0 ? (
        <img src={photoSrc} alt="Product" className={classes.photoFullView} style={{left: `${photoWidth + 1}px`}}/>
      ) : null}
    </div>
  );
};

export default ProductThumbnail;
