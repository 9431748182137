import React, { useContext } from "react";
import MUIDataTable from "mui-datatables";
import { RaportContext } from "../../../_context/raportContext";
import {
  ADD_TO_SELECTED_LOCALIZATION,
  REMOVE_FROM_SELECTED_LOCALIZATION,
  ADD_ALL_TO_SELECTED_LOCALIZATION,
  REMOVE_ALL_FROM_SELECTED_LOCALIZATION
} from "../../../_reducers/raportReducer";

export const SaleLokalizacjeTable = ({
  data,
  setCountryIds,
  setSelectedCountryNames,
  setRowsSelected,
  rowsSelected,
  isRaportMode
}) => {
  const { state, dispatch } = useContext(RaportContext);
  const columns = [
    {
      name: "name",
      label: "Name",
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: "code",
      label: "Kod",
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: "total",
      label: "Sprzedane - Zwrócone [szt.]",
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: "sell",
      label: "Sprzedane [szt.]",
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: "returned",
      label: "Zwrócone [szt.]",
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: "rabat",
      label: "Rabat [%]",
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: "avg_vat",
      label: "Średni vat sprzedaży[%]",
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: "avg_p_netto",
      label: "Średnia cena netto [PLN]",
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: "priceNetto",
      label: "Suma netto [PLN]",
      options: {
        filter: true,
        sort: true
      }
    }
  ];

  function createData(
    name,
    code,
    total,
    sell,
    returned,
    rabat,
    avg_vat,
    avg_p_netto,
    priceNetto
  ) {
    return {
      name,
      code,
      total,
      sell,
      returned,
      rabat,
      avg_vat,
      avg_p_netto,
      priceNetto
    };
  }

  const round = (n, k) => {
    const factor = Math.pow(10, k + 1);
    n = Math.round(Math.round(n * factor) / 10);
    return n / (factor / 10);
  };

  const datas = data.map(it =>
    createData(
      it.name,
      it.code,
      it.qtyTotal,
      it.qtySell,
      it.qtyReturned,
      round(it.percentRabat, 2),
      round(it.avgVatRate, 2),
      round(it.avgPriceNetto, 2),
      round(it.priceNetto, 2)
    )
  );

  const handleSelected = dataSelected => {
    const indexx = dataSelected.map(it => it.dataIndex);

    if (indexx.length > 1) {
      setRowsSelected(indexx);
      setCountryIds(indexx.map(it => data[it].id));
      setSelectedCountryNames(
        indexx.map(it => {
          return { name: data[it].name, id: data[it].id, index: it };
        })
      );
    } else if (indexx.length < 1) {
      setRowsSelected([]);
      setCountryIds([]);
      setSelectedCountryNames([]);
    } else {
      let rSelected = [];
      const index = dataSelected[0]["dataIndex"];
      if (rowsSelected.indexOf(index) !== -1) {
        rSelected = rowsSelected.filter(it => it !== index);
      } else {
        rSelected = [...rowsSelected, index];
      }

      setCountryIds(rSelected.map(it => data[it].id));
      setSelectedCountryNames(
        rSelected.map(it => {
          return { name: data[it].name, id: data[it].id, index: it };
        })
      );
      setRowsSelected(rSelected);
    }
  };

  const handleSelectedRaportMode = arr => {
    if (!arr.length) {
      dispatch({ type: REMOVE_ALL_FROM_SELECTED_LOCALIZATION });
      return;
    }

    if (arr.length > 1) {
      handleSelectedAllRaportMode(arr);
      return;
    }

    const id = data[arr[0].dataIndex].id;
    const exist = state.selectedLocalization.includes(id);

    if (!exist) {
      dispatch({ type: ADD_TO_SELECTED_LOCALIZATION, payload: id });
    } else {
      dispatch({ type: REMOVE_FROM_SELECTED_LOCALIZATION, payload: id });
    }
  };

  const handleSelectedAllRaportMode = values => {
    const ids = values.map(it => data[it.dataIndex].id);

    dispatch({ type: ADD_ALL_TO_SELECTED_LOCALIZATION, payload: ids });
  };

  let selectedDesignerIndexRaport = [];

  state.selectedLocalization.forEach(it => {
    data.forEach((lok, index) => {
      if (lok.id === it) {
        selectedDesignerIndexRaport.push(index);
      }
    });
  });

  const options = {
    download: true,
    print: false,
    onRowsSelect: isRaportMode ? handleSelectedRaportMode : handleSelected,
    disableToolbarSelect: true,
    rowsSelected: isRaportMode ? selectedDesignerIndexRaport : rowsSelected,
    textLabels: {
      pagination: {
        rowsPerPage: "Wyświetl"
      },
      toolbar: {
        search: "Szukaj",
        viewColumns: "Pokaż kolumny",
        filterTable: "Filtruj tabelę"
      },
      filter: {
        all: "Wszystkie",
        title: "Filtry",
        reset: "Wyczyść"
      },
      viewColumns: {
        title: "Pokaż kolumny",
        titleArea: "Pokaż/Schowaj kolumnę"
      }
    }
  };

  return (
    <>
      <MUIDataTable data={datas} columns={columns} options={options} />
    </>
  );
};
