import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { makeStyles, Grid } from "@material-ui/core";
import { ReportsMenu } from "../shared/ReportNavigation";
import { useParams } from "react-router-dom";
import Actions from "./Actions";
import ReportDetails from "../shared/ReportDetails";
import ReportItemTypes from "../shared/report-item-types";
import ProductsReportPanel from "../ProductsReportPanel";
import OrdersReportPanel from "../OrdersReportPanel";
import { getReportById, getReportItemByType } from "../../../_services/_raportsService";
import DesignersReportPanel from "../DesignersReportPanel";
import LocalizationsReportPanel from "../LocalizationsReportPanel";
import SignaturesReportPanel from "../SignaturesReportPanel";
import CategoriesReportPanel from "../CategoriesReportPanel";
import ReportPlaceholder from "../shared/ReportPlaceholder";

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: `calc(${theme.headerHeight} * 2)`,
    backgroundColor: theme.palette.gray,
    padding: theme.spacing(1.5),
    boxSizing: "border-box",
    minHeight: `calc(100vh - ${parseInt(theme.headerHeight) * 2}px)`
  },
  reportPanelsContainer: {
    maxWidth: "100%",
    marginTop: theme.spacing(1.5),
    gap: "2px",
    "& .MuiPaper-root:not(.MuiTableCell-root > .MuiPaper-root)": {
      boxShadow: "none !important"
    }
  },
  tab: {
    "& .MuiTablePagination-root": {
      border: "none !important"
    }
  }
}));

const ShowDraftVersionReport = () => {
  const classes = useStyles();
  const params = useParams();

  const [update, setUpdate] = useState(0);
  const [modifiedType, setModifiedType] = useState();
  const [data, setData] = useState([]);

  const [productItems, setProductItems] = useState([]);
  const [orderItems, setOrderItems] = useState([]);
  const [designerItems, setDesignerItems] = useState([]);
  const [localizationItems, setLocalizationItems] = useState([]);
  const [signatureItems, setSignatureItems] = useState([]);
  const [categoryItems, setCategoryItems] = useState({});


  const triggerUpdate = (itemType) => {
    setModifiedType(itemType);
    setUpdate(update => update + 1);
  };

  useEffect(() => {
    if (!!params.id) {
      getReportById(params.id).then(res => {
        setData(res.data);
        setProductItems(res.data.item.filter(it => it.type === ReportItemTypes.Product));
        setOrderItems(res.data.item.filter(it => it.type === ReportItemTypes.Order)[0]);
        setDesignerItems(res.data.item.filter(it => it.type === ReportItemTypes.Designer)[0]);
        setLocalizationItems(res.data.item.filter(it => it.type === ReportItemTypes.Localization)[0]);
        setSignatureItems(res.data.item.filter(it => it.type === ReportItemTypes.Signature)[0]);
        setCategoryItems(res.data.item.filter(it => it.type === ReportItemTypes.Category)[0]);
      });
    }
  }, [params.id]);

  useEffect(() => {
    if (!!params.id && modifiedType) {
      getReportItemByType(params.id, modifiedType)
        .then(res => {
          switch (modifiedType) {
            case ReportItemTypes.Product:
              setProductItems(res.data);
              break;
            case ReportItemTypes.Order:
              setOrderItems(res.data[0]);
              break;
            case ReportItemTypes.Designer:
              setDesignerItems(res.data[0]);
              break;
            case ReportItemTypes.Localization:
              setLocalizationItems(res.data[0]);
              break;
            case ReportItemTypes.Signature:
              setSignatureItems((res.data[0]));
              break;
            case ReportItemTypes.Category:
              setCategoryItems(res.data[0]);
              break;
            default:
              break;
          }
        });
    }
    // eslint-disable-next-line
  }, [update]);

  return (
    <div className={classes.root}>
      <ReportsMenu/>

      <Helmet>
        <meta charSet="utf-8"/>
        <title>Raport roboczy</title>
      </Helmet>

      {!data?.report ? (
        <ReportPlaceholder/>
        ) : (
        <Grid container direction="column">
          <Actions reportId={data.report.id} note={data.report.note} isDraft={true}/>

          <ReportDetails data={data.report}/>

          <div className={classes.reportPanelsContainer}>
            <ProductsReportPanel
              data={productItems}
              id={data.report.id}
              isDraft={true}
              triggerUpdate={triggerUpdate}
            />

            <OrdersReportPanel
              data={orderItems}
              id={data.report.id}
              isDraft={true}
              triggerUpdate={triggerUpdate}
            />

            <DesignersReportPanel
              data={designerItems}
              id={data.report.id}
              isDraft={true}
              triggerUpdate={triggerUpdate}
            />

            <LocalizationsReportPanel
              data={localizationItems}
              id={data.report.id}
              isDraft={true}
              triggerUpdate={triggerUpdate}
            />

            <SignaturesReportPanel
              data={signatureItems}
              id={data.report.id}
              isDraft={true}
              triggerUpdate={triggerUpdate}
            />

            <CategoriesReportPanel
              data={categoryItems}
              id={data.report.id}
              isDraft={true}
              triggerUpdate={triggerUpdate}
            />
          </div>
        </Grid>
      )}
    </div>
  );
};

export default ShowDraftVersionReport;
