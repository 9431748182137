import React, { useRef, useState } from "react";
import { addCommentToReport } from "../../../_services/_raportsService";
import { useDispatch } from "react-redux";
import { ERROR, SUCCESS } from "../../../_actions/alertsActions";
import { Button, Drawer } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import DrawerHeader from "../shared/DrawerHeader";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
  container: {
    width: "520px",
    maxWidth: "520px",
    height: "100%",
    padding: theme.spacing(2)
  },
  textarea: {
    resize: "vertical",
    fontFamily: "Arial",
    padding: "7px",
    borderRadius: 0,
    transition: "height 0.5s",
    transitionDelay: "0.2s",
    border: "1px solid #eaeaea",
    height: "15px",
    "&:focus": {
      outline: "none",
      border: "1px solid black",
      height: "100px"
    }
  },
  commentsContainer: {
    borderTop: "1px solid #EAEAEA",
    overflowY: "auto",
    overflowX: "hidden",
    marginLeft: `${theme.spacing(0.75)}px!important`,
    marginRight: `${theme.spacing(0.75)}px!important`,
    marginTop: `${theme.spacing(1)}px!important`,
    padding: "0!important"
  },
  commentWrapper: {
    borderBottom: "1px solid #EAEAEA",
    padding: `${theme.spacing(1.5)}px 0!important`
  },
  commentInfoDate: {
    fontSize: 10,
    color: "gray"
  }
}));

const CommentsDrawer = ({
  open,
  onClose,
  title,
  comments,
  reportId,
  setCommentsCount,
  userName,
  type
}) => {
  const classes = useStyles();
  const commentRef = useRef();
  const dispatch = useDispatch();
  const [currentComments, setCurrentComments] = useState(comments);
  const [inputFocus, setInputFocus] = useState(false);


  const handleAddComment = () => {
    const message = commentRef.current.value;
    setInputFocus(false)

    addCommentToReport(reportId, {
      message: message,
      type: type
    }).then(res => {
      if (res.status === 200) {
        const filteredComments = res.data && res.data.length ? res.data.filter(it => it.type === type) : null;
        setCurrentComments(filteredComments);
        setCommentsCount(!!filteredComments?.length ? filteredComments.length : null);
        dispatch({ type: SUCCESS, payload: "Komentarz dodany do raportu" });
        commentRef.current.value = "";

      }
    }).catch(e => {
      dispatch({ type: ERROR, payload: "Wystąpił błąd. Spróbuj ponownie" });
    });
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClick={e => {
        e.stopPropagation();
        e.preventDefault()
      }}
    >
      <Grid container className={classes.container} direction="column" alignItems="stretch" spacing={1} >
        <Grid item>
          <DrawerHeader title={`Komentarze do sekcji ${title.toUpperCase()}`} onClose={onClose}/>
        </Grid>

        <Grid item>
          <Typography variant="h6"><strong>{userName}</strong></Typography>
        </Grid>

        <Grid item>
          <textarea
            ref={commentRef}
            cols="65"
            rows="1"
            charswidth="23"
            placeholder="Napisz komentarz..."
            onFocus={(e) => setInputFocus(true)}
            onBlur={(e) => {
              setTimeout(() => setInputFocus(false), 90);
            }}
            className={classes.textarea}
          />
        </Grid>

        {inputFocus && (
          <Grid item container justifyContent="space-between">
            <Grid item>
              <Button variant="contained" color="primary" onClick={handleAddComment}>
                DODAJ KOMENTARZ
              </Button>
            </Grid>
            <Grid item>
              <Button variant="outlined" color="primary" onClick={onClose}>
                ANULUJ
              </Button>
            </Grid>
          </Grid>
        )}

        {!!currentComments.length && (
          <Grid item xs className={classes.commentsContainer}>
            {currentComments.map((it, index) =>
              <Grid container direction="column" className={classes.commentWrapper} key={index}>
                <Grid item container spacing={1} direction="row" alignItems="baseline">
                  <Grid item>
                    <Typography variant="h6"><strong>{it.owner.name}</strong></Typography>
                  </Grid>

                  <Grid item className={classes.commentInfoDate}>
                    {it.createdAt}
                  </Grid>
                </Grid>

                <Grid item>
                  <Typography variant="body1"><small>{it.comment}</small></Typography>
                </Grid>
              </Grid>
            )}
          </Grid>
        )}
      </Grid>
    </Drawer>
  );
};

export default CommentsDrawer;
