import React, { cloneElement, useState } from "react";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Accordion, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%"
  },
  expandIcon: {
    padding: "8px"
  },
  typography: {
    fontSize: "14px !important",
    fontWeight: "400 !important"
  },
  accordion: {
    marginTop: "2px !important",
    border: "1px solid #e7e7e7b3",

    "&.Mui-expanded": {
      marginBottom: `${theme.spacing(1)}px !important`
    }
  }
}));


const ReportAccordionPanel = ({ id, title, actionButtons, children }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  const actionButtonsProps = actionButtons.map(it => {
    return {
      ...it.props,
      onClick: (e) => {
        e.preventDefault();
        e.stopPropagation();
        it.props.onClick(e);
      }
    };
  });

  return (
    <Accordion id={id} expanded={expanded} elevation={0} className={classes.accordion}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon/>}
        onClick={() => setExpanded(!expanded)}
        classes={{ expandIcon: classes.expandIcon }}
      >
        <div className={classes.wrapper}>
          <Typography variant="h3" className={classes.typography}>
            {title}
          </Typography>

          <Grid container spacing={1} direction="row" justify="flex-end">
            {actionButtons?.map((it, i) => (
              <Grid item key={i}>
                {cloneElement(it, actionButtonsProps[i])}
              </Grid>
            ))}
          </Grid>
        </div>
      </AccordionSummary>

      <AccordionDetails>
        {expanded ? children : ""}
      </AccordionDetails>
    </Accordion>
  );
};

export default ReportAccordionPanel;
