import React, { useState } from "react";
import { Drawer, Button, Select, FormControl } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import DrawerHeader from "../shared/DrawerHeader";
import { CategoryItem, CategoryItemDisabled } from "../../Filters/CategoryItem";
import { makeStyles } from "@material-ui/core/styles";
import FilterAutocomplete from "../shared/FilterAutocomplete";
import ArrowDownDivider from "../../Common/ArrowDownDivider";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: "520px",
    height: "100%",
    padding: theme.spacing(2),
    "& .MuiInputBase-root, & .MuiSelect-select": {
      borderRadius: "0!important",
    },
    "& .MuiAutocomplete-root .MuiInputBase-root": {
      padding: "0 9px"
    },
    "& .MuiFormControlLabel-labelPlacementTop": {
      margin: "-11px"
    }
  }
}));

const ProductFiltersDrawer = ({
  open,
  onClose,
  title,
  filtersData,
  onSubmit
}) => {
  const classes = useStyles();
  const [signature, setSignature] = useState(null);
  const [designer, setDesigner] = useState(null);
  const [isCO, setIsCO] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState({});
  const [level, setLevel] = useState(1);
  const [emptyLevel1, setEmptyLevel1] = useState(false);

  const catComponent = [1, 2, 3, 4, 5].map(it => {
    return level >= it ? (
      <Grid item container spacing={1} direction="column" key={it}>
        <Grid item key={it}>
          <CategoryItem
            level={it}
            setSelectedCategory={setSelectedCategory}
            setLevel={setLevel}
            selectedCategory={selectedCategory}
            emptyLevel1={emptyLevel1}
            setEmptyLevel1={setEmptyLevel1}
            selectWrapper={{ borderRadius: 0 }}
          />
        </Grid>
        {it < 5 && <Grid item><ArrowDownDivider/></Grid>}
      </Grid>
    ) : (
      <Grid item container spacing={1} direction="column" key={`disabled-${it}`}>
        <Grid item>
          <CategoryItemDisabled
            key={`disabled-${it}`}
            selectWrapper={{ borderRadius: 0 }}
          />
        </Grid>
        {it < 5 && <Grid item><ArrowDownDivider/></Grid>}
      </Grid>
    );
  });

  const getSelectedCategoryId = (depth = 5) =>
    depth > 0 ?
      selectedCategory[`p${depth}Id`] ?? getSelectedCategoryId(depth - 1)
      : null;


  const deselectAll = () => {
    setLevel(1);
    setSignature(null);
    setDesigner(null);
    setSelectedCategory({});
    setIsCO(null);
  };

  const submit = () => {
    onSubmit({
      designer: designer,
      category: Number(getSelectedCategoryId()),
      level: level - 1,
      signature: signature,
      isCO: isCO
    });
  };

  return (
    <Drawer anchor="right" open={open} onClose={onClose} PaperProps={{ sx: { width: 520 } }}>
      <Grid container direction="column" spacing={2} className={classes.wrapper}>
        <Grid item>
          <DrawerHeader title={`Filtrowanie w sekcji ${title.toUpperCase()}`} onClose={onClose}/>
        </Grid>

        <Grid item container spacing={1} direction="column">
          <Grid item container direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h6"><strong>Kategoria</strong></Typography>
            <Button
              onClick={() => {
                deselectAll();
                submit();
              }}
              disabled={!signature && !designer && !selectedCategory}>
              WYCZYŚĆ
            </Button>
          </Grid>
          {catComponent}
        </Grid>

        <Grid item>
          <FilterAutocomplete
            options={filtersData.signatures}
            setValue={setSignature}
            value={signature}
            title="Sygnatura"
          />
        </Grid>

        <Grid item>
          <FilterAutocomplete
            options={filtersData.designers}
            setValue={setDesigner}
            value={designer}
            title="Projektant"
          />
        </Grid>

        <Grid item container spacing={1} direction="column">
          <Grid item>
            <Typography variant="h6"><strong>Carry Over</strong></Typography>
          </Grid>
          <Grid item>
            <FormControl variant="outlined" fullWidth>
              <Select native value={isCO} onChange={e => setIsCO(e.target.value)}>
                <option value="">Bez znaczenia</option>
                <option value={true}>Tak</option>
                <option value={false}>Nie</option>
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Grid item container justifyContent="space-between">
          <Grid item>
            <Button variant="contained" color="primary" onClick={() => submit()}>
              FILTRUJ
            </Button>
          </Grid>
          <Grid item>
            <Button variant="outlined" color="primary" onClick={() => {
              deselectAll();
              onClose();
            }}>
              ANULUJ
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Drawer>
  );
};

export default ProductFiltersDrawer;
