import React, { useContext, useEffect, useState } from "react";
import { filterContext } from "../../../_context/filterContext";
import { getRozmiary } from "../../../_services/_bestsellerService";
import TableComponent from "../components/TableComponent";
import Grid from "@material-ui/core/Grid";
import TablePlaceholder from "../../../_components/TablePlaceholder";
import { formatter } from "../../../_helpers/ConvertPrice";

export const RozmiaryComponent = ({ switchValue, id, rozmiaryData }) => {
  const { state } = useContext(filterContext);
  const [rozmiary, setRozmiary] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (rozmiaryData) {
      setRozmiary(rozmiaryData);
      setIsLoading(false);
      return;
    }
    setIsLoading(true);
    getRozmiary(switchValue ? state : { isPaid: 1 }, id)
      .then(res => {
        setRozmiary(res.data);
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [state, id, switchValue, rozmiaryData]);

  const headers = [
    {
      id: "rozmiar",
      numeric: false,
      disablePadding: true,
      label: "Rozmiar"
    },
    {
      id: "stockTotal",
      numeric: true,
      disablePadding: false,
      label: "Stan [szt.]"
    },
    {
      id: "total",
      numeric: true,
      disablePadding: false,
      label: "Sprzedane - Zwrócone [szt.]"
    },
    {
      id: "sell",
      numeric: true,
      disablePadding: false,
      label: "Sprzedane [szt.]"
    },
    {
      id: "returned",
      numeric: true,
      disablePadding: false,
      label: "Zwrócone [szt.]"
    },
    {
      id: "rabat",
      numeric: true,
      disablePadding: false,
      label: "Rabat [%]"
    },
    {
      id: "avg_p_netto",
      numeric: true,
      disablePadding: false,
      label: "Średnia cena netto [PLN]"
    },
    {
      id: "avg_p_brutto",
      numeric: true,
      disablePadding: false,
      label: "Średnia cena brutto [PLN]"
    },
    {
      id: "p_netto",
      numeric: true,
      disablePadding: false,
      label: "Łączna suma netto [PLN]"
    },
    {
      id: "p_brutto",
      numeric: true,
      disablePadding: false,
      label: "Łączna suma brutto [PLN]"
    }
  ];

  function createData(
    rozmiar,
    stock,
    stockExtra,
    sell,
    returned,
    total,
    rabat,
    avg_p_netto,
    avg_p_brutto,
    p_netto,
    p_brutto
  ) {
    let stockTotal = stock + (stockExtra && stockExtra > 0 ? stockExtra : 0);
    return {
      rozmiar,
      stockTotal,
      sell,
      returned,
      total,
      rabat,
      avg_p_netto,
      avg_p_brutto,
      p_netto,
      p_brutto
    };
  }

  const round = (n, k) => {
    const factor = Math.pow(10, k + 1);
    n = Math.round(Math.round(n * factor) / 10);
    return n / (factor / 10);
  };

  const rows = rozmiary.map(it =>
    createData(
      it.dlugosc ? it.rozmiar + "/" + it.dlugosc : it.rozmiar,
      parseInt(it.stock),
      parseInt(it.stockExtra),
      parseInt(it.qtySell),
      parseInt(it.qtyReturned),
      parseInt(it.qtyTotal),
      round(it.percentRabat, 2),
      formatter(it.avgPriceNetto),
      formatter(it.avgPriceBrutto),
      formatter(it.priceNetto),
      formatter(it.priceBrutto)
    )
  );

  return (
    <Grid item xs={12}>
      {isLoading ? (
        <TablePlaceholder />
      ) : (
        <TableComponent
          headCellsData={headers}
          rowsData={rows}
        ></TableComponent>
      )}
    </Grid>
  );
};
