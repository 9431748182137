import React, { useState } from "react";
import ReportAccordionPanel from "./ReportAccordionPanel";
import { OrderListTableMui } from "../Orders/OrderListTableMui";
import OrdersChartPriceNetto from "../Orders/OrdersChartComponent/OrdersChartPriceNetto";
import OrdersChartCurrency from "../Orders/OrdersChartComponent/OrdersChartCurrency";
import { Button, Grid } from "@material-ui/core";
import CommentsButton from "./shared/CommentsButton";
import ReportItemTypes from "./shared/report-item-types";
import CachedIcon from "@material-ui/icons/CachedRounded";
import DeleteIcon from "@material-ui/icons/DeleteForeverRounded";
import ConfirmRemoveModal from "./shared/ConfirmRemoveModal";
import { removeReportItemArray, updateReportResult } from "../../_services/_raportsService";

const OrdersReportPanel = ({ data, comments, id, isDraft, triggerUpdate }) => {
  const title = "ZAMÓWIENIA";

  const [openDialog, setOpenDialog] = useState(false);

  const handleUpdateData = () => {
    updateReportResult({ ids: data.id })
      .then(res => triggerUpdate(ReportItemTypes.Order));
  };

  const handleRemove = () => {
    removeReportItemArray(data.id).then(res => {
      setOpenDialog(false);
      triggerUpdate(ReportItemTypes.Order);
    });
  };

  let actionButtons;

  if (isDraft) {
    actionButtons = [
      <Button
        color="primary"
        variant="outlined"
        onClick={() => handleUpdateData()}
      >
        <CachedIcon/>
        Aktualizuj dane
      </Button>,
      <Button
        variant="outlined"
        color="primary"
        style={{ color: "#9B0000" }}
        onClick={() => setOpenDialog(true)}
      >
        <DeleteIcon/>
        Usuń tabelę
        <ConfirmRemoveModal
          open={openDialog}
          handleClose={() => setOpenDialog(false)}
          handleConfirm={() => handleRemove()}
        />
      </Button>
    ];
  } else {
    actionButtons = [
      <CommentsButton comments={comments} title={title} type={ReportItemTypes.Order}/>
    ];
  }

  return data?.result?.length ? (
    <ReportAccordionPanel
      id="orders"
      title={title}
      reportId={id}
      actionButtons={actionButtons}
    >
      <Grid container direction="column" spacing={3} alignItems="stretch" justifyContent="flex-start">
        <Grid item xs>
          <OrderListTableMui data={data.result} selectableOption="none"/>
        </Grid>
        <Grid item xs>
          <OrdersChartPriceNetto data={data.result}/>
        </Grid>
        <Grid item xs>
          <OrdersChartCurrency data={data.result}/>
        </Grid>
      </Grid>
    </ReportAccordionPanel>
  ) : null;
};

export default OrdersReportPanel;
