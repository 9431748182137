import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { getDevstatProduct } from "../../../_services/devstatService";
import BestsellerListItemPlaceholder from "../../../_components/Bestseller/BestsellerListItemPlaceholder";
import DevstatProductListItem from "./DevstatProductListItem";
import { Pagination } from "../../Filters/Pagination";
import { filterContextDevstat } from "../../../_context/filterContextDevstat";

const useStyles = makeStyles(theme => ({
  root: theme.filterPaper,
  inputField: theme.inputField,
  background: {
    minHeight: `calc(100vh - ${theme.headerHeight})`,
    padding: theme.spacing(2),
    boxSizing: "border-box",
    backgroundColor: theme.palette.gray
  }
}));

const DevstatProductList = () => {
  const classes = useStyles();
  const { state } = useContext(filterContextDevstat);
  const [devstatProducts, setDevstatProducts] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [limit, setLimit] = useState(state.limit);
  const limitOptions = [20, 40, 60, 80, 100];
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    getDevstatProduct(state)
      .then(res => {
        setDevstatProducts(res.data.results);
        setTotalItems(parseInt(res.data.pagination.totalItems, 10));
        setTotalPages(parseInt(res.data.pagination.totalPages, 10));
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        setIsLoading(false);
        window.scroll(0, 0);
      });

    return () => {};
  }, [state]);

  const loading = [...Array(5)].map((it, index) => {
    return (
      <Grid item xs={12} key={index}>
        <Paper className={classes.placeholderPaper} key={index}>
          <BestsellerListItemPlaceholder />
        </Paper>
      </Grid>
    );
  });

  const items = devstatProducts.map((it, index) => {
    return (
      <Grid item xs={12} key={index}>
        <Paper className={classes.paper}>
          <DevstatProductListItem
            item={it}
            index={index}
          ></DevstatProductListItem>
        </Paper>
      </Grid>
    );
  });

  const pagination = (
    <Pagination
      totalItems={totalItems}
      limitOptions={limitOptions}
      totalPages={totalPages}
      currentLimit={limit}
      setLimit={setLimit}
      context={filterContextDevstat}
    ></Pagination>
  );

  return (
    <Grid container spacing={1} className={classes.grid}>
      <Grid
        item
        xs={12}
        container
        justifyContent="flex-start"
        alignItems="center"
      >
        <Grid item xs={3}>
          <Typography variant="body2" spacing={2}>
            {totalItems ? totalItems + " produktów" : "0 produktów"}
          </Typography>
        </Grid>
        <Grid item xs container justifyContent="flex-end" spacing={3}>
          <Grid item>{pagination}</Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid item container spacing={2} className={classes.container}>
          {isLoading ? (
            loading
          ) : totalItems > 0 ? (
            items
          ) : (
            <h1>Brak wyników wyszukiwania</h1>
          )}
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid item container justifyContent="flex-end" spacing={3}>
          <Grid item>{pagination}</Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DevstatProductList;
