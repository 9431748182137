import React, { useRef } from "react";
import { SUCCESS } from "../../../_actions/alertsActions";
import { addNoteToReport } from "../../../_services/_raportsService";
import { makeStyles, Drawer, Button, Typography } from "@material-ui/core";
import { useDispatch } from "react-redux";
import Grid from "@material-ui/core/Grid";
import DrawerHeader from "../shared/DrawerHeader";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "520px",
    maxWidth: "520px",
    height: "100%",
    padding: theme.spacing(2)
  },
  textarea: {
    resize: "vertical",
    fontFamily: "Arial",
    padding: "7px",
    borderRadius: 0,
    overflowY: "auto",
    border: "1px solid #eaeaea",
    height: "100px",
    "&:focus": {
      outline: "none",
      border: "1px solid black"
    }
  }
}));

const NoteDrawer = ({ open, setOpen, reportId, note, isEditable, triggerUpdate }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const noteRef = useRef();

  const handleAddNote = () => {
    const note = noteRef.current.value;


    addNoteToReport(reportId, { note: note })
      .then(res => {
        if (res.status === 200) {
          dispatch({ type: SUCCESS, payload: "Notatka zapisana poprawnie" });
          triggerUpdate();
        }
      });
  };

  const onClose = () => setOpen(false);

  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <Grid container className={classes.container} direction="column" alignItems="stretch" spacing={1}>
        <Grid item>
          <DrawerHeader
            title={isEditable ?
              note ? "Edytuj notatkę" : "Dodaj notatkę do raportu"
              : "Podgląd notatki"
            }
            onClose={onClose}
          />
        </Grid>

        <Grid item>
          {isEditable ? (
            <textarea
              ref={noteRef}
              cols="65"
              rows="1"
              defaultValue={note}
              placeholder="Napisz notatkę..."
              className={classes.textarea}
            />
          ) : (
            <Typography variant="body1">{note ? note : "Brak notatki"}</Typography>
          )}
        </Grid>

        {isEditable && (
          <Grid item container justifyContent="space-between">
            <Grid item>
              <Button onClick={handleAddNote} variant="contained" color="primary">
                ZAPISZ
              </Button>
            </Grid>
            <Grid item>
              <Button onClick={onClose} variant="outlined" color="primary">
                ANULUJ
              </Button>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Drawer>
  );
};

export default NoteDrawer;
