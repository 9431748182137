import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import React, { useEffect, useState } from "react";
import { getDevstatLog } from "../../../_services/devstatLogService";
import DevstatLogList from "./DevstatLogList";
import LogItemFile from "./LogItemFile";
import LogItemProduct from "./LogItemProduct";
import { DevstatStatic } from "./DevstatStatics";

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.headerHeight,
    flexGrow: 1,
    backgroundColor: theme.palette.gray,
    padding: theme.spacing(2),
    minHeight: `calc(100vh - ${theme.headerHeight})`,
    boxSizing: "border-box"
  },
  paper: theme.filterPaper
}));

const DevstatLog = () => {
  const classes = useStyles();

  const [list, setList] = useState([]);
  const [refreshCount, setRefreshCount] = useState(0);

  useEffect(() => {
    getDevstatLog().then(it => {
      setList(
        it.data.map(item => {
          return {
            ...item,
            isActive: item.isActive === true ? 1 : 0
          };
        })
      );
    });
    return () => {};
  }, [refreshCount]);

  const handleRefresh = () => {
    setRefreshCount(refreshCount + 1);
  };

  const handleChangeActivity = (item, value) => {
    setList(
      list.map(it => {
        if (it === item) {
          return {
            ...it,
            isActive: value
          };
        }
        return it;
      })
    );
  };

  const items = list
    .filter(it => it.isActive)
    .map((it, key) => {
      if (it.isDb) {
        switch (it.sid) {
          case "product":
            return <LogItemProduct key={key} item={it} />;
          case "sprzedaz":
            return null;
          default:
            return null;
        }
      }

      return <LogItemFile name={it.name} isDb={it.isDb} key={key} />;
    });

  return (
    <div className={classes.root}>
      <Grid container spacing={3} className={classes.grid}>
        <Grid
          item
          xs={12}
          container
          spacing={2}
          justifyContent="flex-start"
          alignItems="center"
        >
          <Grid item xs={12}>
            <DevstatStatic></DevstatStatic>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          container
          spacing={2}
          justifyContent="flex-start"
          alignItems="center"
        >
          <Grid item xs={12}>
            <DevstatLogList
              list={list}
              handleRefresh={handleRefresh}
              handleChangeActivity={handleChangeActivity}
            />
          </Grid>
          {items}
        </Grid>
      </Grid>
    </div>
  );
};

export default DevstatLog;
