import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { NavLink } from "react-router-dom";
import React, { createRef, forwardRef, useState } from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { Badge, ListItemText } from "@material-ui/core";
import ArrowForwardIosRoundedIcon from "@material-ui/icons/ArrowForwardIosRounded";

const useStyles = makeStyles(theme => ({
  menuItem: {
    marginLeft: "20px",
    flexGrow: 1,
    color: theme.palette.common.black,
    textTransform: "uppercase",
    display: "flex",
    height: "100%"
  },
  link: {
    textDecoration: "none",
    color: theme.palette.common.black,
    display: "flex",
    alignItems: "center",
    padding: "12px 0 9px 0"
  },
  activeLink: {
    borderTop: "2px solid #000",
    paddingTop: theme.spacing(1.25)
  },
  menuIcon: {
    marginTop: "-6px"
  }
}));

export const HeaderMenuItem = ({ linkTo, label, items, Icon, derivedClasses, badgeCount, iconBadge }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState({ value: null, name: null });

  const handleClick = (event, name) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl({ value: event.currentTarget, name: name });
  };

  const handleClose = () => {
    setAnchorEl({ value: null, name: null });
  };

  const OptionalLink = forwardRef(({ item, children }, ref) => {
    if (item.linkTo) {
      return <NavLink ref={ref} to={item.linkTo} onClick={() => handleClose()}>{children}</NavLink>;
    }

    return children;
  });

  const OptionalBadge = forwardRef(({iconBadge, children }, ref) => {
    if (iconBadge) {
      return <Badge badgeContent={badgeCount} color="error" overlap="circular">{children}</Badge>;
    }

    return children;
  });

  return (
    <Grid item>
      <Typography variant="h6" className={Icon ? null : classes.menuItem}>
        <NavLink
          to={linkTo}
          activeClassName={Icon ? '' : classes.activeLink}
          className={classes.link}
          onClick={e => items?.length ? handleClick(e, linkTo) : null}
        >
          {label ? label : null}
          {Icon ?
            <OptionalBadge ref={createRef()} iconBadge={iconBadge} badgeCount={badgeCount}>
              <Icon.type {...Icon.props} className={classes.menuIcon}/>
            </OptionalBadge>
            : null}
        </NavLink>

        {items?.length ? (
          <Menu
            anchorEl={anchorEl.value}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            keepMounted
            open={Boolean(anchorEl.value) && anchorEl.name === linkTo}
            onClose={handleClose}
            classes={{ list: derivedClasses?.list }}
          >
            {items.map((it, index) => (
              it.divider ? <MenuItem key={index} divider/> :
                <OptionalLink key={index} item={it} ref={createRef()}>
                  <MenuItem className={it.derivedClasses?.item}>
                    <ListItemText primary={it.primaryText} secondary={it.secondaryText}
                                  classes={{ primary: it.derivedClasses?.primaryText }}/>
                    {it.suffix ? <ArrowForwardIosRoundedIcon/> : null}
                  </MenuItem>
                </OptionalLink>
            ))}
          </Menu>
        ) : null
        }
      </Typography>
    </Grid>
  );
};

