import React from "react";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";


const DrawerHeader = ({ title, onClose }) => {
  return (
    <Grid container direction="row" justifyContent="space-between" alignItems="center">
      <Grid item>
        <Typography variant="h5"><strong>{title}</strong></Typography>
      </Grid>

      <Grid item>
        <IconButton aria-label="close" onClick={onClose}>
          <CloseIcon style={{ color: "black" }}/>
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default DrawerHeader;
