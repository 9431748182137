import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import React from "react";
import { Button, Link } from "@material-ui/core";
import { EnhancedTableHead } from "../../Reports/shared/EnhancedTableHead";
import { MuiTableSortedRows } from "../../Reports/shared/MuiTableSortedRows";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 750
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  },
  pagination: {
    "& .MuiIconButton-root": {
      padding: 0
    },
    "& .MuiSelect-selectMenu": {
      height: "14px"
    },
    "& .MuiTypography-body1": {
      fontSize: "0.87em"
    }
  }
}));

let rows = [];
let headCells = [];

export default function TableComponent({ headCellsData, rowsData, customCellsData }) {
  headCells = headCellsData;
  rows = rowsData;

  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("total");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const Pagination = () => {
    return rows.length > 5 ? (
      <TablePagination
        rowsPerPageOptions={[5, 10, 20, 40, 60, 80, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        labelRowsPerPage="Wyświetl:"
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        className={classes.pagination}
      />
    ) : (
      <></>
    );
  };

  if (!customCellsData) {
    customCellsData = [];
  }

  customCellsData.push(
    {
      name: "uniIndeks",
      content: (row) =>
        <Button variant="contained">
        <Link
          href={`/devstat/show/${row[this.name]}`}
          underline="none"
          color="primary"
          target="_blank"
        >
          Check
        </Link>
      </Button>
    }
  );

  return (
    <>
      <TableContainer>
        <Table
          className={classes.table}
          size="small"
        >
          <EnhancedTableHead
            order={order}
            setOrder={setOrder}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            rowCount={rows ? rows.length : 0}
            headCells={headCells}
          />
          <TableBody>
            <MuiTableSortedRows
              rows={rows?.length ? rows : []}
              order={order}
              orderBy={orderBy}
              headCells={headCells}
              page={page}
              rowsPerPage={rowsPerPage}
              customCellsData={customCellsData ? customCellsData : []}
            >
            </MuiTableSortedRows>
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination />
    </>
  );
}
