import React, { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import {
  TableCell,
  TableRow,
  Grid,
  Accordion as MuiAccordion,
  AccordionSummary,
  AccordionDetails, Typography
} from "@material-ui/core";

import { round } from "./TablesFunctions";
import SaleKategorieChart from "../../Sale/SaleKategorieComponents/SaleKategorieChart";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { HeadLabel } from "./HeadLabel";

import NumberFormat from "react-number-format";

const parseAvgPrice = number => {
  let parseNumber = parseFloat(number);
  return parseNumber.toFixed(2);
};

const useStyles = makeStyles(theme => ({
  title: {
    textTransform: "uppercase",
    fontWeight: 500,
    fontSize: "12px"
  },
  table: {
    "& .MuiPaper-root": {
      borderRadius: 0
    }
  },
  chartSkeleton: {
    margin: "0 16px"
  },
  detailsRoot: {
    padding: 0
  },
  tableRowCell: {
    border: "none",
  }
}));

const CategoryAccordion = props => {
  const {
    title,
    data,
    selectedIds,
    selectedIndex,
    setSelectedIds,
    setSelectedIndex,
    isDraft
  } = props;

  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  return (
    <MuiAccordion square onChange={(e, expanded) => setExpanded(expanded)}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon/>}
        style={{ backgroundColor: "#E7E7E7" }}
      >
        <Typography variant="h5" className={classes.title}>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails classes={{ root: classes.detailsRoot }}>
        {expanded ?
          <Grid container spacing={4}>
            <Grid item xs={12} className={classes.table}>
              <TableCategories
                data={data}
                selectedIndex={selectedIndex}
                setSelectedIndex={setSelectedIndex}
                selectedIds={selectedIds}
                setSelectedIds={setSelectedIds}
                expandableRows={title.toUpperCase() !== "KOLORY"}
                isDraft={isDraft}
              />
            </Grid>
            <Grid item xs={12} className={classes.chartSkeleton}>
              <SaleKategorieChart data={data} dataKey="name"/>
            </Grid>
          </Grid>
          : <></>
        }
      </AccordionDetails>
    </MuiAccordion>
  );
};

export default CategoryAccordion;

const TableCategories = ({
  data,
  selectedIndex,
  setSelectedIndex,
  selectedIds,
  setSelectedIds,
  expandableRows,
  isDraft
}) => {
  const classes = useStyles();

  const handleSelected = (selectedRows, allRowsSelected, rowsSelected) => {
    if (selectedRows.length === 1) {
      const index = selectedRows[0].dataIndex;
      const id = data[index].id;

      const exist = selectedIndex.includes(index);

      let newArr = [...selectedIndex];
      let newArrIds = [...selectedIds];

      if (exist) {
        newArr = newArr.filter(it => it !== index);
        newArrIds = newArrIds.filter(it => it !== id);
      } else {
        newArr.push(index);
        newArrIds.push(id);
      }

      setSelectedIds([...new Set(newArrIds)]);
      setSelectedIndex(newArr);
    } else if (selectedRows.length > 1) {
      const arr = [];

      for (const selectedRow of selectedRows) {
        arr.push(data[selectedRow.dataIndex].id);
      }

      setSelectedIds([...new Set(arr.concat(selectedIds))]);
      setSelectedIndex(selectedRows.map(it => it.dataIndex));
    } else if (selectedRows.length === 0) {
      const arrToDeselect = data.map(it => it.id);
      setSelectedIds(selectedIds => selectedIds.filter(item => !arrToDeselect.includes(item)));
      setSelectedIndex([]);
    }
  };

  const handleExpandable = (rowData, rowMeta) => {
    const dataItem = data[rowMeta.dataIndex].child;

    return dataItem?.map((it, index) => {
      return (
        <TableRow key={index} hover={true}>
          <TableCell></TableCell>
          <TableCell>{it.p3}</TableCell>
          <TableCell>{it.qtyTotal}</TableCell>
          <TableCell>{it.qtySell}</TableCell>
          <TableCell>{it.qtyReturned}</TableCell>
          <TableCell>{round(it.percentRabat, 2)}</TableCell>
          <TableCell>{round(it.avgVatRate, 2)}</TableCell>
          <TableCell>
            <NumberFormat
              value={parseAvgPrice(it.avgPriceNetto)}
              displayType={"text"}
              thousandSeparator={true}
            />
          </TableCell>
          <TableCell>
            <NumberFormat
              value={parseAvgPrice(it.priceNetto)}
              displayType={"text"}
              thousandSeparator={true}
            />
          </TableCell>
        </TableRow>
      );
    });
  };

  const options = {
    download: false,
    pagination: false,
    search: true,
    print: false,
    filter: false,
    viewColumns: true,
    selectableRows: isDraft ? "multiple" : "none",
    renderExpandableRow: handleExpandable,
    expandableRows: expandableRows,
    expandableRowsOnClick: expandableRows,
    onRowSelectionChange: handleSelected,
    selectToolbarPlacement: "none",
    selectableRowsHeader: true,
    rowsSelected: selectedIndex,
    textLabels: {
      pagination: {
        rowsPerPage: "Wyświetl"
      },
      toolbar: {
        search: "Szukaj"
      }
    }
  };

  const columns = [
    {
      name: "name",
      label: "Nazwa",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: (value, tableMeta) => (
          <HeadLabel value={value.label}/>
        ),

      }
    },
    {
      name: "total",
      label: "Sprzedane - Zwrócone [szt.]",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: (value, tableMeta) => (
          <HeadLabel value={value.label}/>
        )
      }
    },
    {
      name: "sell",
      label: "Sprzedane [szt.]",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: (value, tableMeta) => (
          <HeadLabel value={value.label}/>
        )
      }
    },
    {
      name: "returned",
      label: "Zwrócone [szt.]",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: (value, tableMeta) => (
          <HeadLabel value={value.label}/>
        )
      }
    },
    {
      name: "rabat",
      label: "Rabat [%]",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: (value, tableMeta) => (
          <HeadLabel value={value.label}/>
        )
      }
    },
    {
      name: "avg_vat",
      label: "Średni vat sprzedaży [%]",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: (value, tableMeta) => (
          <HeadLabel value={value.label}/>
        )
      }
    },
    {
      name: "avg_p_netto",
      label: "Średnia cena netto [PLN]",
      options: {
        filter: true,
        sort: true,
        sortCompare: order => (val1, val2) =>
          (val1.data - val2.data) * (order === "asc" ? 1 : -1),
        customBodyRender: (value, tableMeta, updateValue) => (
          <NumberFormat
            value={value}
            displayType={"text"}
            thousandSeparator={true}
          />
        ),
        customHeadLabelRender: (value, tableMeta) => (
          <HeadLabel value={value.label}/>
        )
      }
    },
    {
      name: "priceNetto",
      label: "Suma netto [PLN]",
      options: {
        filter: true,
        sort: true,
        numeric: true,
        sortCompare: order => (val1, val2) =>
          (val1.data - val2.data) * (order === "asc" ? 1 : -1),
        customBodyRender: (value, tableMeta, updateValue) => (
          <NumberFormat
            value={parseAvgPrice(value)}
            displayType={"text"}
            thousandSeparator={true}
          />
        ),
        customHeadLabelRender: (value, tableMeta) => (
          <HeadLabel value={value.label}/>
        )
      }
    }
  ];

  return <MUIDataTable data={data} columns={columns} options={options} className={classes.tableRowCell}/>;
};
