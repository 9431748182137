import Paper from "@material-ui/core/Paper";
import React, { useContext } from "react";
import BestletterListItem from "./BestletterListItem";
import { Checkbox } from "@material-ui/core";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import {
  ADD_ALL_FROM_PAGE_TO_SELECTED_PRODUCT,
  REMOVE_ALL_SELECTED_PRODUCT_FROM_PAGE
} from "../../_reducers/raportReducer";
import { RaportContext } from "../../_context/raportContext";

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: "-4px"
  },
  grid: {
    "& .activeFilter": {
      paddingTop: 0
    }
  },
  paper: theme.bestsellerPaper,
  placeholderPaper: theme.placeholderPaper,
  filterContainer: {
    paddingTop: "0!important"
  },
  container: {
    marginTop: "-17px"
  }
}));

const BestsellerListRaportMode = ({
  loading,
  noProductAlert,
  isLoading,
  bestsellerList,
  totalItems
}) => {
  const classes = useStyles();
  const { state, dispatch } = useContext(RaportContext);

  const handleSelectAll = e => {
    const val = e.target.checked;
    const items = bestsellerList.map(it => it.id);

    if (val) {
      dispatch({
        type: ADD_ALL_FROM_PAGE_TO_SELECTED_PRODUCT,
        payload: items
      });
    } else {
      dispatch({ type: REMOVE_ALL_SELECTED_PRODUCT_FROM_PAGE, payload: items });
    }
  };

  const items = bestsellerList.map((it, index) => {
    return (
      <Grid item xs={12} key={index}>
        <Paper className={classes.paper}>
          <BestletterListItem
            item={it}
            index={index}
            raportMode={true}
          ></BestletterListItem>
        </Paper>
      </Grid>
    );
  });

  const itemsIds = bestsellerList.map(it => it.id);

  return (
    <>
      <Typography
        variant="body1"
        component="span"
        style={{ display: "flex", alignItems: "center" }}
      >
        <Checkbox
          style={{ color: "black" }}
          onChange={handleSelectAll}
          checked={itemsIds.every(it => state.selectedProduct.includes(it))}
        />
        {" "}
        <div>Zaznacz wszystkie</div>
      </Typography>
      {isLoading ? loading : totalItems > 0 ? items : noProductAlert}
    </>
  );
};

export default BestsellerListRaportMode;
