import { LOGIN, LOGOUT, SET_USER } from "../_actions/authActions";

const initialState = {
  isLoggedIn: undefined,
  token: undefined,
  loggedUser: undefined,
  name: "",
  userId: undefined,
  isDevStat: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGIN: {
      const { apiToken, id, name, isDevStat, email } = action.payload;
      localStorage.setItem("apiToken", apiToken);
      localStorage.setItem("id", id);

      return {
        ...state,
        isLoggedIn: true,
        token: apiToken,
        userId: id,
        name: name,
        email: email,
        isDevStat: isDevStat
      };
    }
    case LOGOUT: {
      localStorage.removeItem("apiToken");
      localStorage.removeItem("id");
      return {
        ...state,
        isLoggedIn: false,
        loggedUser: undefined,
        userId: undefined,
        token: null,
        name: "",
        email: ""
      };
    }
    case SET_USER:
      const { id, apiToken, loggedUser, isDevStat, name, email } = action.payload;
      localStorage.setItem("apiToken", apiToken);
      localStorage.setItem("id", id);
      return {
        ...state,
        isLoggedIn: true,
        loggedUser,
        userId: id,
        name,
        email,
        isDevStat,
        token: apiToken
      };
    default: {
      return { ...state };
    }
  }
};
