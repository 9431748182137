import React, { useState } from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { getExtraStockOdl } from "../../../_services/_bestsellerService";
import CircularProgress from "@material-ui/core/CircularProgress";
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles(theme => ({
  container: {
    padding: "5px",
    width: "70%",
    border: "2px solid rgba(132, 132, 132, 0.13)"
  }
}));

const BestsellerItemStany = ({ item }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const getDataFromApi = () => {
    setLoading(true);
    getExtraStockOdl(item.id)
      .then(res => {
        setData(res.data);
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
        setOpen(prevState => !open);
      });
  };

  const handleOpen = () => {
    if (!open) {
      if (!data.length) {
        getDataFromApi();
      } else {
        setOpen(prevState => !open);
      }
    }

    if (open) {
      setOpen(prevState => !open);
    }
  };

  return (
    <Grid container direction="column" className={classes.container}>
      <Grid
        item
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Tooltip
          title={`Dostępne: ${item.stock} szt. Warszawa Outlet: ${item.stockExtra} szt.`}
          aria-label="add"
        >
          <Grid item>
            <Typography variant="subtitle2">Stan</Typography>
            <Typography variant="body1">
              {item.stock + item.stockExtra} szt.
            </Typography>
          </Grid>
        </Tooltip>
        <Grid
          item
          style={{
            cursor: "pointer",
            justifyContent: "center",
            alignItems: "center"
          }}
          onClick={handleOpen}
        >
          {!open ? (
            loading ? (
              <CircularProgress size={18} />
            ) : (
              <ExpandMoreIcon />
            )
          ) : (
            <ExpandLessIcon />
          )}
        </Grid>
      </Grid>
      {
        <Grid
          container
          direction="column"
          style={{
            height: open ? 60 : 0,
            opacity: open ? 1 : 0,
            marginTop: open ? 10 : 0,
            visibility: open ? "visible" : "hidden",
            display: "block",
            transition:
              "height 200ms ease-in 0ms, opacity 200ms ease-in 100ms, margin-top 200ms ease-in 0ms, visibility 100ms ease-in 0ms"
          }}
        >
          {data.map((it, index) => {
            return (
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                key={index}
              >
                <Typography variant="subtitle2">{it.name}</Typography>
                <Typography variant="body1">{it.value}</Typography>
              </Grid>
            );
          })}
        </Grid>
      }
    </Grid>
  );
};

export default BestsellerItemStany;
