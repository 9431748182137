import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ModeCommentIcon from "@material-ui/icons/ModeComment";
import ModeCommentOutlinedIcon from "@material-ui/icons/ModeCommentOutlined";

const useStyles = makeStyles(theme => ({
  wrapper: {
    position: "relative",
    marginBottom: "-7px",
    "& svg": {
      fontWeight: "400!important",
      fontSize: "17px"
    }
  },
  counter: {
    position: "absolute",
    top: "3px",
    fontSize: "8px",
    fontWeight: "400",
    lineHeight: "8px",
    left: "-3px",
    color: "white",
    width: "100%",
    textAlign: "center"
  }
}));

export const CommentCountIcon = ({ count, className }) => {
  const classes = useStyles();

  return <div className={`${classes.wrapper} ${className}`}>
    {count ? (
        <>
          <ModeCommentIcon fontSize="medium"/>
          <span className={classes.counter}>{count}</span>
        </>
      )
      : (
        <ModeCommentOutlinedIcon fontSize="medium"/>
      )}
  </div>;
};
