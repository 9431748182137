//PRODUKTY
export const ADD_TO_SELECTED_PRDOUCT = "add_to_selected_product";
export const ADD_ALL_FROM_PAGE_TO_SELECTED_PRODUCT =
  "add_all_from_page_to_selected_product";
export const REMOVE_ALL_SELECTED_PRODUCT_FROM_PAGE =
  "remove_all_selected_product_from_page";
export const SAVE_ALL_PRODUCTS = "save_all_products";

//PROJEKTANCI
export const ADD_TO_SELECTED_DESIGNER = "add_to_selected_designer";
export const REMOVE_FROM_SELECTED_DESIGNER = "remove_from_selected_designer";
export const ADD_ALL_TO_SELECTED_DESIGNER = "add_all_to_selected_designer";
export const REMOVE_ALL_FROM_SELECTED_DESIGNER =
  "remove_all_from_selected_designer";

//LOKALIZACJE
export const ADD_TO_SELECTED_LOCALIZATION = "add_to_selected_localization";
export const REMOVE_FROM_SELECTED_LOCALIZATION =
  "remove_from_selected_localization";
export const ADD_ALL_TO_SELECTED_LOCALIZATION =
  "add_all_to_selected_localization";
export const REMOVE_ALL_FROM_SELECTED_LOCALIZATION =
  "remove_all_from_selected_localization";

// SYGNATURY
export const ADD_TO_SELECTED_SIGNATURES = "add_to_selected_signatures";
export const REMOVE_FROM_SELECTED_SIGNATURES =
  "remove_from_selected_signatures";

// KATEGORIE
export const ADD_TO_SELECTED_CATEGORIES = "add_to_selected_categories";
export const ADD_TO_SELECTED_CATEGORIES_LEVEL2 =
  "add_to_selected_categories_level2";
export const REMOVE_FROM_SELECTED_CATEGORIES_LEVEL2 =
  "remove_from_selected_categories_level2";

//ALL
export const CLEAR_ALL_SELECTED = "clear_all_selected";

//draft version
export const LOAD_DRAFT_VERSION = "load_draft_version";
export const ADD_TO_DRAFT_VERSION = "add_to_draft_version";

//update position
export const UPDATE_POSITION = "update_position";
export const CLEAR_POSITION = "clear_position";

// actions in tables
export const UPDATE_SELECTED_PRODUCT_TABLE_ITEMS =
  "update_selected_product_table_items";
export const CLEAR_SELECTED_PRODUCT_TABLE_ITEMS =
  "clear_selected_product_table_items";


export const initialState = {
  // components view
  productItems: [],
  selectedProduct: [],
  selectedDesigner: [],
  selectedLocalization: [],
  selectedSignatures: [],
  selectedCategories: [],
  draftRaports: [],
  newPosition: [],
  // tables draft
  selectedProductTableItems: []
};

export const raportReducer = (state, action) => {
  switch (action.type) {
    // PRODUKTY
    case ADD_TO_SELECTED_PRDOUCT: {
      let newArr = state.selectedProduct;
      const exist = newArr.includes(action.payload);

      if (exist) {
        newArr = newArr.filter(it => it !== action.payload);
      } else {
        newArr.push(action.payload);
      }

      return {
        ...state,
        selectedProduct: newArr
      };
    }
    case ADD_ALL_FROM_PAGE_TO_SELECTED_PRODUCT: {
      const newArr = state.selectedProduct;

      action.payload.forEach(it => {
        const exist = state.selectedProduct.includes(it);

        if (!exist) {
          newArr.push(it);
        }

        return it;
      });

      return {
        ...state,
        selectedProduct: newArr
      };
    }
    case REMOVE_ALL_SELECTED_PRODUCT_FROM_PAGE: {
      let newArr = [];

      state.selectedProduct.forEach(it => {
        if (action.payload.includes(it)) {
          return;
        } else {
          newArr.push(it);
        }
      });

      return {
        ...state,
        selectedProduct: newArr
      };
    }

    case SAVE_ALL_PRODUCTS: {
      return {
        ...state,
        productItems: action.items
      }
    }

    // PROJEKTANCI
    case ADD_TO_SELECTED_DESIGNER: {
      let newArr = state.selectedDesigner;

      newArr.push(action.payload);

      return {
        ...state,
        selectedDesigner: newArr
      };
    }
    case REMOVE_FROM_SELECTED_DESIGNER: {
      let newArr = state.selectedDesigner.filter(it => it !== action.payload);

      return {
        ...state,
        selectedDesigner: newArr
      };
    }
    case ADD_ALL_TO_SELECTED_DESIGNER: {
      return {
        ...state,
        selectedDesigner: action.payload
      };
    }
    case REMOVE_ALL_FROM_SELECTED_DESIGNER: {
      return {
        ...state,
        selectedDesigner: []
      };
    }

    //LOKALIZACJE
    case ADD_TO_SELECTED_LOCALIZATION: {
      let newArr = state.selectedLocalization;

      newArr.push(action.payload);

      return {
        ...state,
        selectedLocalization: newArr
      };
    }
    case REMOVE_FROM_SELECTED_LOCALIZATION: {
      let newArr = state.selectedLocalization.filter(
        it => it !== action.payload
      );

      return {
        ...state,
        selectedLocalization: newArr
      };
    }
    case ADD_ALL_TO_SELECTED_LOCALIZATION: {
      return {
        ...state,
        selectedLocalization: action.payload
      };
    }
    case REMOVE_ALL_FROM_SELECTED_LOCALIZATION: {
      return {
        ...state,
        selectedLocalization: []
      };
    }

    //SYGNATURY
    case ADD_TO_SELECTED_SIGNATURES: {
      let newArr = state.selectedSignatures;

      newArr.push(action.payload);

      return {
        ...state,
        selectedSignatures: newArr
      };
    }
    case REMOVE_FROM_SELECTED_SIGNATURES: {
      let newArr = state.selectedSignatures.filter(it => it !== action.payload);

      return {
        ...state,
        selectedSignatures: newArr
      };
    }

    //KATEGORIE
    case ADD_TO_SELECTED_CATEGORIES: {
      let newArr = state.selectedCategories;
      const exist = newArr.includes(action.payload);

      if (exist) {
        newArr = newArr.filter(it => it !== action.payload);
      } else {
        newArr.push(action.payload);
      }

      return {
        ...state,
        selectedCategories: newArr
      };
    }
    case ADD_TO_SELECTED_CATEGORIES_LEVEL2: {
      const payloadArr = action.payload;
      const newArr = state.selectedCategories;

      payloadArr.forEach(it => {
        const exist = state.selectedCategories.includes(it);

        if (!exist) {
          newArr.push(it);
        }
      });

      return {
        ...state,
        selectedCategories: newArr
      };
    }
    case REMOVE_FROM_SELECTED_CATEGORIES_LEVEL2: {
      const payloadArr = action.payload;
      const newArr = state.selectedCategories;

      payloadArr.forEach(it => {
        const exist = state.selectedCategories.includes(it);

        if (exist) {
          const index = newArr.indexOf(it);
          newArr.splice(index, 1);
        }
      });

      return {
        ...state,
        selectedCategories: newArr
      };
    }

    // DRAFT VERSION
    case LOAD_DRAFT_VERSION: {
      return {
        ...state,
        draftRaports: action.payload
      };
    }

    case ADD_TO_DRAFT_VERSION: {
      const newArr = state.draftRaports;

      newArr.push(action.payload);

      return {
        ...state,
        draftRaports: newArr
      };
    }

    //ALL
    case CLEAR_ALL_SELECTED: {
      return {
        ...state,
        selectedProduct: [],
        selectedDesigner: [],
        selectedLocalization: [],
        selectedSignatures: [],
        selectedCategories: []
      };
    }

    //UPDATE POSITION
    case UPDATE_POSITION: {
      const newArr = state.newPosition;
      const obj = action.payload;

      const checkExist = () => {
        return newArr.some(function(el) {
          return el.id === obj.id;
        });
      };

      const exist = checkExist();

      if (!exist) {
        newArr.push(obj);
      } else {
        newArr.filter(it => {
          if (it.id === obj.id) {
            it.position = obj.position;
          }

          return it;
        });
      }

      return {
        ...state,
        newPosition: newArr
      };
    }

    //CLEAR_POSITION
    case CLEAR_POSITION: {
      return {
        ...state,
        newPosition: []
      };
    }

    // actions in tables
    case UPDATE_SELECTED_PRODUCT_TABLE_ITEMS: {
      let newArr = state.selectedProductTableItems;
      const payloadArr = action.payload;

      if (!payloadArr.length) {
        newArr = [];
      }

      payloadArr.forEach(obj => {
        const exist = newArr.some(function(el) {
          return el.id === obj.id;
        });

        if (!exist) {
          newArr.push(obj);
        } else {
          newArr = newArr.filter(it => it.id !== obj.id);
        }
      });

      return {
        ...state,
        selectedProductTableItems: newArr
      };
    }

    case CLEAR_SELECTED_PRODUCT_TABLE_ITEMS: {
      return {
        ...state,
        selectedProductTableItems: []
      };
    }

    default: {
      return { ...state };
    }
  }
};
