import { makeStyles, Paper, LinearProgress, Grid, Box } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import React from "react";

const useStyles = makeStyles(theme => ({
  root: {
    margin: "0 0 7px"
  },
  photo: {
    margin: "5px 0 0 15px"
  },
  paper: {
    padding: "0"
  },
  textSkeleton: {
    transform: "scale(1, 0.95)"
  },
  container: {
    padding: "0 15px"
  }
}));

const TablePlaceholder = () => {
  const classes = useStyles();

  return (
    <div className={(classes.root, "tablePlaceholder")}>
      <Paper elevation={3} className={classes.paper}>
        <LinearProgress />
         <Box className={classes.container}>
        <Skeleton
          animation="wave"
          variant="text"
          width="100%"
          height="45px"
          className={classes.title}
        />

        <Grid container direction="row" spacing={2}>
          <Grid item xs>
            <Skeleton
              animation="wave"
              variant="text"
              width="100%"
              height="300px"
              classes={{ text: classes.textSkeleton }}
            />
          </Grid>
        </Grid>
         </Box>
      </Paper>
    </div>
  );
};

export default TablePlaceholder;
