import React, { useContext, useEffect, useState } from "react";
import { filterContext } from "../../_context/filterContext";
import { getSaleDesigner } from "../../_services/_saleService";
// import TableComponent from "../Bestseller/components/TableComponent";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { ActiveFilter } from "../Filters/_activeFilter/ActiveFilter";
import { makeStyles } from "@material-ui/core/styles";
import SaleDesignerChartTotal from "./SaleDesignerChartTotal";
import SaleDesignerChartPriceNetto from "./SaleDesignerChartPriceNetto";
import TablePlaceholder from "../TablePlaceholder";
import NoProductAlert from "../../_helpers/NoProductAlert";
import { RaportModeContext } from "../../_context/raportModeContext";
import ReportMenu, { designersReportType } from "../Reports/shared/ReportMenu";
import MUIDataTable from "mui-datatables";
import { RaportContext } from "../../_context/raportContext";
import {
  ADD_TO_SELECTED_DESIGNER,
  REMOVE_FROM_SELECTED_DESIGNER,
  ADD_ALL_TO_SELECTED_DESIGNER,
  REMOVE_ALL_FROM_SELECTED_DESIGNER
} from "../../_reducers/raportReducer";

const useStyles = makeStyles(theme => ({
  buttons: {
    marginTop: theme.spacing(1)
  },
  paper: theme.tablePaper
}));

export const columns = [
  {
    name: "name",
    label: "Nazwa",
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: "total",
    label: "Sprzedane - Zwrócone [szt.]",
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: "sell",
    label: "Sprzedane [szt.]",
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: "returned",
    label: "Zwrócone [szt.]",
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: "rabat",
    label: "Rabat [%]",
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: "avg_vat",
    label: "Średni vat sprzedaży[%]",
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: "avg_p_netto",
    label: "Średnia cena netto [PLN]",
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: "priceNetto",
    label: "Suma netto [PLN]",
    options: {
      filter: true,
      sort: true
    }
  }
];

export const SaleDesignerView = ({ setView }) => {
  const classes = useStyles();
  const { state } = useContext(filterContext);
  const [isLoading, setIsLoading] = useState(true);
  const [saleDesigner, setSaleDesigner] = useState([]);
  const { isRaportMode } = useContext(RaportModeContext);
  const { state: stateRaport, dispatch } = useContext(RaportContext);

  useEffect(() => {
    setIsLoading(true);
    window.scrollTo(0, 0);
    getSaleDesigner(state)
      .then(res => {
        setSaleDesigner(res.data);
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        setIsLoading(false);
        window.scrollTo(0, 0);
      });
  }, [state]);

  function createData(
    name,
    total,
    sell,
    returned,
    rabat,
    avg_vat,
    avg_p_netto,
    priceNetto
  ) {
    return {
      name,
      total,
      sell,
      returned,
      rabat,
      avg_vat,
      avg_p_netto,
      priceNetto
    };
  }

  const round = (n, k) => {
    const factor = Math.pow(10, k + 1);
    n = Math.round(Math.round(n * factor) / 10);
    return n / (factor / 10);
  };

  const datas = saleDesigner.map(it =>
    createData(
      it.nazwa,
      it.qtyTotal,
      it.qtySell,
      it.qtyReturned,
      round(it.percentRabat, 2),
      round(it.avgVatRate, 2),
      round(it.avgPriceNetto, 2),
      round(it.priceNetto, 2)
    )
  );

  if (!isLoading && saleDesigner.length < 1)
    return <NoProductAlert setView={setView} />;

  const handleSelected = arr => {
    if (!arr.length) {
      dispatch({ type: REMOVE_ALL_FROM_SELECTED_DESIGNER });
      return;
    }

    if (arr.length > 1) {
      handleSelectedAll(arr);
      return;
    }

    const id = saleDesigner[arr[0].dataIndex].id;
    const exist = stateRaport.selectedDesigner.includes(id);

    if (!exist) {
      dispatch({ type: ADD_TO_SELECTED_DESIGNER, payload: id });
    } else {
      dispatch({ type: REMOVE_FROM_SELECTED_DESIGNER, payload: id });
    }
  };

  const handleSelectedAll = values => {
    const ids = values.map(it => saleDesigner[it.dataIndex].id);

    dispatch({ type: ADD_ALL_TO_SELECTED_DESIGNER, payload: ids });
  };

  let selectedDesignerIndex = [];

  stateRaport.selectedDesigner.forEach(it => {
    saleDesigner.forEach((des, index) => {
      if (des.id === it) {
        selectedDesignerIndex.push(index);
      }
    });
  });

  const options = {
    download: false,
    print: false,
    filter: false,
    viewColumns: false,
    selectableRows: isRaportMode,
    onRowsSelect: handleSelected,
    disableToolbarSelect: true,
    selectableRowsHeader: true,
    rowsSelected: selectedDesignerIndex,
    textLabels: {
      pagination: {
        rowsPerPage: "Wyświetl"
      },
      toolbar: {
        search: "Szukaj",
        viewColumns: "Pokaż kolumny",
        filterTable: "Filtruj tabelę"
      },
      filter: {
        all: "Wszystkie",
        title: "Filtry",
        reset: "Wyczyść"
      },
      viewColumns: {
        title: "Pokaż kolumny",
        titleArea: "Pokaż/Schowaj kolumnę"
      }
    }
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        {isRaportMode ? (
          <ReportMenu
            reducerKeyh="selectedDesigner"
            type={designersReportType}
          />
        ) : null}
        <Grid item xs={12}>
          <ActiveFilter />
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            {isLoading ? (
              <TablePlaceholder />
            ) : (
              <MUIDataTable data={datas} columns={columns} options={options} />
              // <TableComponent
              //   headCellsData={headers}
              //   rowsData={rows}
              // ></TableComponent>
            )}
          </Paper>
        </Grid>
        <Grid item xs={12}>
          {isLoading ? (
            <TablePlaceholder />
          ) : (
            <SaleDesignerChartTotal data={saleDesigner} />
          )}
        </Grid>
        <Grid item xs={12}>
          {isLoading ? (
            <TablePlaceholder />
          ) : (
            <SaleDesignerChartPriceNetto data={saleDesigner} />
          )}
        </Grid>
      </Grid>
    </div>
  );
};
