import React, { useState } from "react";
import ReportAccordionPanel from "./ReportAccordionPanel";
import { round } from "./shared/TablesFunctions";
import { makeStyles } from "@material-ui/core/styles";
import CommentsButton from "./shared/CommentsButton";
import CategoryAccordion from "./shared/CategoryAccordion";
import ReportItemTypes from "./shared/report-item-types";
import { removeReportItemArray, updateReportResult } from "../../_services/_raportsService";
import { Button } from "@material-ui/core";
import CachedIcon from "@material-ui/icons/CachedRounded";
import DeleteIcon from "@material-ui/icons/DeleteForeverRounded";
import ConfirmRemoveModal from "./shared/ConfirmRemoveModal";

const useStyles = makeStyles(theme => ({
  categories: {
    border: "1px solid rgb(231, 231, 231)",

    "& .MuiAccordion-root:first-of-type": {
      marginTop: "0 !important"
    }
  }
}));

const CategoriesReportPanel = ({ data, comments, id, isDraft, triggerUpdate }) => {
  const title = "KATEGORIE";
  const classes = useStyles();
  const [openDialog, setOpenDialog] = useState(false);

  const [selectedIndexWomen, setSelectedIndexWomen] = useState([]);
  const [selectedIndexMen, setSelectedIndexMen] = useState([]);
  const [selectedIndexChild, setSelectedIndexChild] = useState([]);
  const [selectedIndexColor, setSelectedIndexColor] = useState([]);
  const [selectedIndexUnisex, setSelectedIndexUnisex] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);

  const getRowsToModify = () => !!selectedIds.length ? selectedIds.join(",") : [];

  const handleRemove = () => {
    const obj = { ids: getRowsToModify() };

    removeReportItemArray(data?.id, obj)
      .then(res => {
        if (res.status === 204) {
          setSelectedIndexWomen([]);
          setSelectedIndexMen([]);
          setSelectedIndexChild([]);
          setSelectedIndexColor([]);
          setSelectedIndexUnisex([]);
          setSelectedIds([]);
          triggerUpdate(ReportItemTypes.Category);
          setOpenDialog(false);
        }
      });
  };

  const handleUpdateData = () => {
    const obj = { ids: String(data[0].id) };

    updateReportResult(obj)
      .then(res => triggerUpdate(ReportItemTypes.Category));
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const createData = (
    name,
    total,
    sell,
    returned,
    rabat,
    avg_vat,
    avg_p_netto,
    priceNetto,
    id,
    child
  ) => {
    return {
      name,
      total,
      sell,
      returned,
      rabat,
      avg_vat,
      avg_p_netto,
      priceNetto,
      id,
      child
    };
  };

  const getCategories = key => {
    return data?.result?.categories[key]?.map(it =>
      createData(
        it.p2,
        it.qtyTotal,
        it.qtySell,
        it.qtyReturned,
        round(it.percentRabat, 2),
        round(it.avgVatRate, 2),
        round(it.avgPriceNetto, 2),
        round(it.priceNetto, 2),
        it.p2Id,
        it.child
      )
    );
  };

  const dataWomen = getCategories("women") ?? [];
  const dataMen = getCategories("men") ?? [];
  const dataChild = getCategories("child") ?? [];
  const dataColor = getCategories("color") ?? [];
  const dataUnisex = getCategories("unisex") ?? [];

  let actionButtons;

  if (isDraft) {
    actionButtons = [
      <Button
        color="primary"
        variant="outlined"
        onClick={() => handleUpdateData()}
      >
        <CachedIcon/>
        Aktualizuj dane
      </Button>,
      <>
        <Button
          variant="outlined"
          color="primary"
          style={{ color: "#9B0000" }}
          onClick={() => setOpenDialog(true)}
        >
          <DeleteIcon/>
          {!!selectedIds.length ? "Usuń pozycje" : "Usuń tabelę"}
        </Button>
        <ConfirmRemoveModal
          open={openDialog}
          handleClose={handleClose}
          handleConfirm={handleRemove}
        />
      </>
    ];
  } else {
    actionButtons = [
      <CommentsButton comments={comments} title={title} type={ReportItemTypes.Category}/>
    ];
  }

  const categories = data?.result?.categories;

  return categories?.women.length
  || categories?.men?.length
  || categories?.child?.length
  || categories?.unisex?.length
  || categories?.color?.length ? (
    <ReportAccordionPanel
      id="categories"
      title={title}
      reportId={id}
      actionButtons={actionButtons}
    >
      <div className={classes.categories}>
        {dataWomen && dataWomen.length ? (
          <CategoryAccordion
            data={dataWomen}
            title="Kobiety"
            isDraft={isDraft}
            selectedIndex={selectedIndexWomen}
            setSelectedIndex={setSelectedIndexWomen}
            selectedIds={selectedIds}
            setSelectedIds={setSelectedIds}
          />
        ) : null}
        {dataMen && dataMen.length ? (
          <CategoryAccordion
            data={dataMen}
            title="Mężczyźni"
            isDraft={isDraft}
            selectedIndex={selectedIndexMen}
            setSelectedIndex={setSelectedIndexMen}
            selectedIds={selectedIds}
            setSelectedIds={setSelectedIds}
          />
        ) : null}
        {dataUnisex && dataUnisex.length ? (
          <CategoryAccordion
            data={dataUnisex}
            title="Unisex"
            isDraft={isDraft}
            selectedIndex={selectedIndexUnisex}
            setSelectedIndex={setSelectedIndexUnisex}
            selectedIds={selectedIds}
            setSelectedIds={setSelectedIds}
          />
        ) : null}
        {dataChild && dataChild.length ? (
          <CategoryAccordion
            data={dataChild}
            title="Dzieci"
            isDraft={isDraft}
            selectedIndex={selectedIndexChild}
            setSelectedIndex={setSelectedIndexChild}
            selectedIds={selectedIds}
            setSelectedIds={setSelectedIds}
          />
        ) : null}
        {dataColor && dataColor.length ? (
          <CategoryAccordion
            data={dataColor}
            title="Kolory"
            isDraft={isDraft}
            selectedIndex={selectedIndexColor}
            setSelectedIndex={setSelectedIndexColor}
            selectedIds={selectedIds}
            setSelectedIds={setSelectedIds}
          />
        ) : null}
      </div>
    </ReportAccordionPanel>
  ) : null;
};

export default CategoriesReportPanel;
