import React from "react";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { DefaultTooltipContent } from "recharts/lib/component/DefaultTooltipContent";
import {
  BarChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Brush,
  Bar
} from "recharts";

const useStyles = makeStyles(theme => ({
  paper: theme.chartPaper,
}));

const SaleDesignerChartTotal = ({ data }) => {
  const classes = useStyles();

  const CustomTooltip = props => {
    if (!props.active) {
      return null;
    }

    const round = (n, k) => {
      const factor = Math.pow(10, k + 1);
      n = Math.round(Math.round(n * factor) / 10);
      return n / (factor / 10);
    };

    const newPayload = [
      {
        name: "Średnia cena",
        value:
          props && props.payload
            ? round(props.payload[0].payload.avgPriceNetto, 2)
            : "-"
      }
    ];

    return <DefaultTooltipContent {...props} payload={newPayload} />;
  };

  return (
      <Paper className={classes.paper}>
        <Typography variant="h3">
          Średnia sprzedaży netto PLN
        </Typography>
          <ResponsiveContainer minWidth={0} width="100%" height={300}>
            <BarChart
              data={data}
              margin={{ top: 5, right: 60, left: 5, bottom: 5 }}
            >
              <XAxis dataKey="nazwa" tick={{ fontSize: 12 }} />
              <YAxis dataKey="avgPriceNetto" tick={{ fontSize: 12 }} />
              <CartesianGrid strokeDasharray="3 3" />
              <Tooltip
                content={<CustomTooltip />}
                wrapperStyle={{ fontSize: 12 }}
              />
              <Legend
                payload={[
                  {
                    value: "Średnia cena sprzedaży netto",
                    type: "square",
                    color: "#112E51"
                  }
                ]}
              />
              <Bar dataKey="avgPriceNetto" fill="#8884d8" />
              <Brush dataKey="avgPriceNetto" height={30} stroke="#8884d8" />
            </BarChart>
          </ResponsiveContainer>
      </Paper>
  );
};

export default SaleDesignerChartTotal;
