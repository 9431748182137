import React, { useEffect, useContext, useState } from "react";
import { Grid, Tooltip } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import DeleteIcon from "@material-ui/icons/DeleteForeverRounded";
import { RaportContext } from "../../../_context/raportContext";
import { LOAD_DRAFT_VERSION } from "../../../_reducers/raportReducer";
import useReportListByType from "../../../_hooks/Reports/useReportListByType";
import { useHistory } from "react-router-dom";
import ConfirmRemoveModal from "../shared/ConfirmRemoveModal";
import { removeReport } from "../../../_services/_raportsService";
import { EnhancedTableHead } from "../shared/EnhancedTableHead";
import { MuiTableSortedRows } from "../shared/MuiTableSortedRows";
import { EmptyResultsRow } from "../../Common/EmptyResultsRow";
import IconButton from "../../Common/IconButton";
import ReportPlaceholder from "../shared/ReportPlaceholder";


const DraftTable = props => {
  const headCells = [
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "Nazwa",
      onClick: true
    },
    {
      id: "createdAt",
      numeric: true,
      disablePadding: false,
      label: "Data utworzenia",
      align: "left",
      onClick: true
    },
    {
      id: "email",
      numeric: false,
      disablePadding: false,
      label: "Autor",
      align: "left",
      onClick: true
    },
    {
      id: "dateFrom",
      numeric: true,
      disablePadding: false,
      label: "Zakres dat",
      align: "left",
      onClick: true
    },
    {
      id: "actions",
      numeric: false,
      disablePadding: false,
      label: "Akcje",
      align: "right",
      onClick: false
    }
  ];

  const customCellsData = [
    {
      name: "actions",
      content: (row) =>
        <Grid container direction="row" justify="flex-end">
          <Tooltip title="Usuń">
            <IconButton
              onClick={() => {
                setReportIdToRemove(row.id);
                setOpen(true);
              }}
              color="red"
              Icon={DeleteIcon}/>
          </Tooltip>
        </Grid>
    },
    {
      name: "dateFrom",
      content: (row) => `${row.dateFrom} - ${row.dateTo}`
    }
  ];

  const { dispatch } = useContext(RaportContext);
  const [open, setOpen] = useState(false);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("total");
  const [reportIdToRemove, setReportIdToRemove] = useState(null);
  const history = useHistory();

  const { data, isLoading, reload } = useReportListByType("draft");

  useEffect(() => {
    if (data) {
      dispatch({
        type: LOAD_DRAFT_VERSION,
        payload: data
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleRemove = () => {
    removeReport(reportIdToRemove).then(res => {
      if (res.status === 204) {
        setReportIdToRemove(null);
        setOpen(false);
        reload();
      }
    });
  };

  if (isLoading) return <ReportPlaceholder/>;

  return (
    <>
      <TableContainer>
        <Table size="small">
          <EnhancedTableHead
            order={order}
            setOrder={setOrder}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            rowCount={data.rows ? data.rows.length : 0}
            headCells={headCells}
          />
          <TableBody>
            {data.length ?
              <MuiTableSortedRows
                rows={data?.length ? data : []}
                order={order}
                orderBy={orderBy}
                headCells={headCells}
                page={0}
                rowsPerPage={data?.length}
                customCellsData={customCellsData}
                onClickHandler={(row) => history.push(`/reports/draft/${row.id}`)}
              >
              </MuiTableSortedRows>
             :
              <EmptyResultsRow></EmptyResultsRow>
            }
          </TableBody>
        </Table>
      </TableContainer>
      <ConfirmRemoveModal
        open={open}
        handleClose={handleClose}
        handleConfirm={handleRemove}
        customTitle={
          "Czy na pewno chcesz trwale usunąć wersję roboczą raportu?"
        }
      />
    </>
  );
};

export default DraftTable;
