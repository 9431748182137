import React, { useState } from "react";

export const ReloadNotificationsContext = React.createContext(0);

export const NotificationsContext = React.createContext([]);

export const NotificationsProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);
  const [update, setUpdate] = useState(0);

  return (
    <NotificationsContext.Provider value={{ notifications, setNotifications }}>
      <ReloadNotificationsContext.Provider value={{ update, setUpdate }}>
        {children}
      </ReloadNotificationsContext.Provider>
    </NotificationsContext.Provider>
  );
};
