const ReportItemTypes = {
  Product: "product",
  Order: "order",
  Signature: "sygnatura",
  Localization: "lokalizacja",
  Category: "kategoria",
  Designer: "projektanci",
  General: "generally"
}

export default ReportItemTypes;
