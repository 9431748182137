import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import BestletterListItem from "./BestletterListItem";

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: "-4px"
  },
  grid: {
    "& .activeFilter": {
      paddingTop: 0
    }
  },
  paper: theme.bestsellerPaper,
  placeholderPaper: theme.placeholderPaper,
  filterContainer: {
    paddingTop: "0!important"
  },
  container: {
    marginTop: "-17px"
  }
}));

const BestsellerListDefault = ({
  loading,
  noProductAlert,
  isLoading,
  bestsellerList,
  totalItems
}) => {
  const classes = useStyles();

  const items = bestsellerList.map((it, index) => {
    return (
      <Grid item xs={12} key={index}>
        <Paper className={classes.paper}>
          <BestletterListItem item={it} index={index}></BestletterListItem>
        </Paper>
      </Grid>
    );
  });
  return <>{isLoading ? loading : totalItems > 0 ? items : noProductAlert}</>;
};

export default BestsellerListDefault;
