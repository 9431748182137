import React, { useReducer } from "react";
import { initialState, raportReducer } from "../_reducers/raportReducer";

export const RaportContext = React.createContext();

export const RaportProvider = ({ children }) => {
  const [state, dispatch] = useReducer(raportReducer, initialState);

  return (
    <RaportContext.Provider value={{ state, dispatch }}>
      {children}
    </RaportContext.Provider>
  );
};
