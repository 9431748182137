import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import Paper from "@material-ui/core/Paper";
import { useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { GET_USERS } from "./UsersComponent";
import Button from "@material-ui/core/Button";
import ChangePasswordDialog from "../Users/ChangePasswordDialog";
import { MuiTableSortedRows } from "../Reports/shared/MuiTableSortedRows";
import { EmptyResultsRow } from "../Common/EmptyResultsRow";
import { EnhancedTableHead } from "../Reports/shared/EnhancedTableHead";

const UPDATE_USER_ACTIVITY = gql`
  mutation UpdateUser($id: ID!, $isActive: Boolean) {
    updateUser(input: { id: $id, isActive: $isActive }) {
      user {
        id
        isActive
      }
    }
  }
`;

const UPDATE_USER_DEVSTAT = gql`
  mutation UpdateUser($id: ID!, $isDevStat: Boolean) {
    updateUser(input: { id: $id, isDevStat: $isDevStat }) {
      user {
        id
        isDevStat
      }
    }
  }
`;

function createData(id, name, email, isActive, roles, isDevStat) {
  return { id, name, email, isActive, roles, isDevStat };
}

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  paper: theme.tablePaper,
  table: {
    minWidth: 750
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  },
  tableHead: {
    whiteSpace: "nowrap"
  }
}));

export default function EnhancedTable({ data }) {
  const headCells = [
    {
      id: "name",
      numeric: false,
      label: "Name",
      style: { width: "20%", minWidth: "200px" }
    },
    {
      id: "email",
      numeric: false,
      label: "Email",
      style: { width: "80%", minWidth: "200px" }
    },
    { id: "isDevStat", numeric: true, label: "Is DevStat" },
    { id: "isActive", numeric: true, label: "Is Active" },
    { id: "role", numeric: true, label: "Role" },
    {
      id: "password",
      numeric: true,
      label: "Password Action"
    }
  ];

  const customCellsData = [
    {
      name: "isDevStat",
      content: (row) => <SwitchDevStat isOn={row.isDevStat} item={row} />
    },
    {
      name: "isActive",
      content: (row) => <Switch isOn={row.isActive} item={row} />
    },
    {
      name: 'role',
      content: (row) => row.roles[0]
    },
    {
      name: 'password',
      content: (row) => {
        const handleOpen = item => {
          setItem(item);
          setOpenDialog(true);
        };

        return (
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              handleOpen(row);
            }}
          >
            Zmiana hasła
          </Button>
        );
      }
    }
  ]

  const rows = data.users.map(it =>
    createData(it.id, it.name, it.email, it.isActive, it.roles, it.isDevStat)
  );
  const classes = useStyles();

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("email");

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const [openDialog, setOpenDialog] = useState(false);

  const [item, setItem] = useState(null);
  const changePasswordDialogBox = (
    <ChangePasswordDialog
      open={openDialog}
      setOpen={setOpenDialog}
      item={item}
    />
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [updateUser] = useMutation(UPDATE_USER_ACTIVITY, {
    update(cache, { data }) {
      const { users } = cache.readQuery({ query: GET_USERS });

      cache.writeQuery({
        query: GET_USERS,
        data: {
          users: users.map(it => {
            if (it.id === data.updateUser.user.id) {
              return data.updateUser.user;
            } else {
              return it;
            }
          })
        }
      });
    }
  });
  const handleToggle = item => {
    updateUser({
      variables: {
        id: item.id,
        isActive: !item.isActive
      }
    })
      .then(it => console.log("Success"))
      .catch(err => console.error(err));
  };

  const Switch = ({ isOn, item }) => {
    return (
      <>
        <input
          checked={isOn}
          onChange={() => handleToggle(item)} //call handleToggle using id
          id={`react-switch-new${item.id}`} // Make it unique by adding id
          type="checkbox"
        />
        <label
          className="react-switch-label"
          htmlFor={`react-switch-new${item.id}`} // Make it unique by adding id
        >
          <span className={`react-switch-button`} />
        </label>
      </>
    );
  };

  const handleToggleIsDevStat = item => {
    updateUserIsDevStat({
      variables: {
        id: item.id,
        isDevStat: !item.isDevStat
      }
    })
      .then(it => console.log("Success"))
      .catch(err => console.error(err));
  };

  const SwitchDevStat = ({ isOn, item }) => {
    return (
      <>
        <input
          checked={isOn}
          onChange={() => handleToggleIsDevStat(item)} //call handleToggle using id
          id={`react-switch-new${item.id}`} // Make it unique by adding id
          type="checkbox"
        />
        <label
          className="react-switch-label"
          htmlFor={`react-switch-new${item.id}`} // Make it unique by adding id
        >
          <span className={`react-switch-button`} />
        </label>
      </>
    );
  };

  const [updateUserIsDevStat] = useMutation(UPDATE_USER_DEVSTAT, {
    update(cache, { data }) {
      const { users } = cache.readQuery({ query: GET_USERS });

      cache.writeQuery({
        query: GET_USERS,
        data: {
          users: users.map(it => {
            if (it.id === data.updateUser.user.id) {
              return data.updateUser.user;
            } else {
              return it;
            }
          })
        }
      });
    }
  });

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table className={classes.table} size="small">
            <EnhancedTableHead
              order={order}
              setOrder={setOrder}
              orderBy={orderBy}
              setOrderBy={setOrderBy}
              rowCount={rows ? rows.length : 0}
              headCells={headCells}
            />
            <TableBody>
              {rows.length ?
                <MuiTableSortedRows
                  rows={rows?.length ? rows : []}
                  order={order}
                  orderBy={orderBy}
                  headCells={headCells}
                  page={0}
                  rowsPerPage={rows?.length}
                  customCellsData={customCellsData}
                >
                </MuiTableSortedRows>
                :
                <EmptyResultsRow></EmptyResultsRow>
              }
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      {openDialog ? changePasswordDialogBox : ""}
    </div>
  );
}
