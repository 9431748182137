import React, { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import { CommentCountIcon } from "./CommentCountIcon";
import CommentsDrawer from "../PublishedReport/CommentsDrawer";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const CommentsButton = ({ comments = [], title, type }) => {
  const { name } = useSelector(state => state.auth);
  const params = useParams();

  const [commentsOpen, setCommentsOpen] = useState(false);
  const [commentsCount, setCommentsCount] = useState(null);

  useEffect(() => {
    setCommentsCount(comments.filter(it => it.type === type).length);
  }, [comments, type]);

  return (
    <>
      <Button
        color="primary"
        variant="outlined"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setCommentsOpen(true);
        }}
      >
        <CommentCountIcon count={commentsCount}/>
        KOMENTARZE
      </Button>

      <CommentsDrawer
        open={commentsOpen}
        onClose={() => setCommentsOpen(false)}
        title={title}
        comments={comments?.filter(it => it.type === type)}
        reportId={params.id}
        setCommentsCount={setCommentsCount}
        userName={name}
        type={type}
      />
    </>
  );
};

export default CommentsButton;
