import React, { useContext, useEffect, useState } from "react";
import ReportAccordionPanel from "./ReportAccordionPanel";
import ProductReportTable from "./shared/ProductReportTable";
import SearchIcon from "@material-ui/icons/Search";
import { Button } from "@material-ui/core";
import ProductFiltersDrawer from "./PublishedReport/ProductFiltersDrawer";
import CommentsButton from "./shared/CommentsButton";
import ReportItemTypes from "./shared/report-item-types";
import { moveReportItemPosition, removeReportItemProduct, updateReportResult } from "../../_services/_raportsService";
import { CLEAR_POSITION, CLEAR_SELECTED_PRODUCT_TABLE_ITEMS } from "../../_reducers/raportReducer";
import SaveIcon from "@material-ui/icons/SaveRounded";
import CachedIcon from "@material-ui/icons/CachedRounded";
import { RaportContext } from "../../_context/raportContext";
import DeleteIcon from "@material-ui/icons/DeleteForeverRounded";
import ConfirmRemoveModal from "./shared/ConfirmRemoveModal";

const ProductsReportPanel = ({ data, comments, id, isDraft, triggerUpdate }) => {
  const title = "PRODUKTY";
  const { state, dispatch } = useContext(RaportContext);
  const [openDialog, setOpenDialog] = useState(false);


  // items filtering
  const [selectedFilters, setSelectedFilters] = useState(
    {
      signature: null,
      designer: null,
      category: null,
      level: 0,
      isCO: null
    }
  );

  const [filtersData, setFiltersData] = useState(
    {
      signatures: [],
      designers: []
    }
  );

  const [filtersOpen, setFiltersOpen] = useState(false);

  useEffect(() => {
    setFiltersData(filtersData => {
      const newState = {
        signatures: filtersData?.signatures ?? [],
        designers: filtersData?.designers ?? []
      };

      if (!filtersData.signatures?.length) {
        newState.signatures = [...new Set(data?.map(it => it.result?.sygnatury?.total?.map(x => x.name)).flat(1))];
      }

      if (!filtersData.designers?.length) {
        newState.designers = [...new Set(data?.map(it => it.result?.product?.designer))];
      }

      return newState;
    });

  }, [selectedFilters, data]);

  let items = data;

  if (!!selectedFilters.signature) {
    items = items.filter(it => !!it.result.sygnatury?.total?.find((y) => {
      return y.name === selectedFilters.signature;
    }));
  }

  if (!!selectedFilters.designer) {
    items = items.filter(it => it.result?.product?.designer === selectedFilters.designer);
  }

  if (!!selectedFilters.category) {
    items = items.filter(it => it.result?.product[`categoryP${selectedFilters.level}Id`] === selectedFilters.category);
  }

  if (selectedFilters.isCO === "true" || selectedFilters.isCO === "false") {
    items = items.filter(it => it.result?.product?.isCo === (selectedFilters.isCO === "true" ? 1 : 0));
  }

  const getRowsToModify = () => !!state.selectedProductTableItems.length
    ? state.selectedProductTableItems.map(it => it.id).join(",")
    : data.map(it => it.id).join(",");

  const handleUpdateData = () => {
    updateReportResult({ ids: getRowsToModify() })
      .then(res => {
        dispatch({ type: CLEAR_SELECTED_PRODUCT_TABLE_ITEMS });
        triggerUpdate(ReportItemTypes.Product);
      });
  };

  const changePosition = () => {
    if (!!state.newPosition.length) {
      moveReportItemPosition(state.newPosition)
        .then(res => {
          dispatch({ type: CLEAR_POSITION })
          triggerUpdate(ReportItemTypes.Product);
        });
    }
  };

  const handleRemove = () => {
    removeReportItemProduct({ ids: getRowsToModify() })
      .then(res => {
        dispatch({ type: CLEAR_SELECTED_PRODUCT_TABLE_ITEMS });
        setOpenDialog(false);
        triggerUpdate(ReportItemTypes.Product);
      });
  };

  let actionButtons;

  if (isDraft) {
    actionButtons = [
      <div onClick={() => !!state.newPosition.length ? changePosition() : null}>
        <Button color="primary" variant="outlined" disabled={!state.newPosition.length}>
          <SaveIcon/>
          Zapisz pozycje
        </Button>
      </div>,
      <Button color="primary" variant="outlined" onClick={() => handleUpdateData()}>
        <CachedIcon/>
        Aktualizuj dane
      </Button>,
      <Button
        variant="outlined"
        color="primary"
        style={{ color: "#9B0000" }}
        onClick={() => setOpenDialog(true)}
      >
        <DeleteIcon/>
        {!!state.selectedProductTableItems.length ? "Usuń pozycje" : "Usuń tabelę"}
        <ConfirmRemoveModal
          open={openDialog}
          handleClose={() => setOpenDialog(false)}
          handleConfirm={() => handleRemove()}
        />
      </Button>
    ];
  } else {
    actionButtons = [
      <Button color="primary" variant="outlined" onClick={() => setFiltersOpen(true)}>
        <SearchIcon/>
        Filtrowanie
      </Button>,
      <CommentsButton comments={comments} title={title} type={ReportItemTypes.Product}/>
    ];
  }

  return data?.length ? (
    <>
      <ReportAccordionPanel
        id="products"
        title={title}
        reportId={id}
        actionButtons={actionButtons}
      >
        <ProductReportTable data={items} editMode={isDraft}/>
      </ReportAccordionPanel>

      {!isDraft &&
      <ProductFiltersDrawer
        open={filtersOpen}
        onClose={() => setFiltersOpen(false)}
        title={title}
        filtersData={filtersData}
        onSubmit={(selectedFilters) => {
          setSelectedFilters(selectedFilters);
          setFiltersOpen(false);
        }}
      />
      }
    </>
  ) : null;
};

export default ProductsReportPanel;
