export const getCategory = category => {
  return category.match(/^\d/) ? category.substring(1) : category;
};

export const MUIDataTableNumericSort = (order) => (obj1, obj2) => {
  let op1 = (Number.isFinite(obj1.data) ? obj1.data < 0 : obj1.data.startsWith('-')) ? -1 : 1;
  let op2 = (Number.isFinite(obj2.data) ? obj2.data < 0 : obj2.data.startsWith('-')) ? -1 : 1;
  let val1 = Number.isFinite(obj1.data) ? obj1.data : parseFloat(obj1.data.replace(/[^\d.]/g, '')) * op1;
  let val2 = Number.isFinite(obj2.data) ? obj2.data : parseFloat(obj2.data.replace(/[^\d.]/g, '')) * op2;

  val1 = val1 ? val1 : 0;
  val2 = val2 ? val2 : 0;

  return (val1 - val2) * (order === 'asc' ? 1 : -1);
};
