import React, { useCallback, useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import PersonRoundedIcon from "@material-ui/icons/PersonRounded";
import ArrowForwardIosRoundedIcon from "@material-ui/icons/ArrowForwardIosRounded";
import { HeaderMenuItem } from "./HeaderMenuItem";
import NotificationsRoundedIcon from "@material-ui/icons/NotificationsRounded";
import { getUserReportNotification } from "../_services/_raportsService";
import { NotificationsContext, ReloadNotificationsContext } from "../_context/notoficationsContext";

const useStyles = makeStyles(theme => ({
  appBar: {
    backgroundColor: theme.palette.common.white
  },
  header: {
    boxShadow: "0 -6px 23px 0 rgba(146,146,146,0.15)",
    borderBottom: `1px solid ${theme.palette.border}`
  },
  toolbar: {
    minHeight: theme.headerHeight,
    height: theme.headerHeight,
    padding: "0 5px"
  },
  menu: {
    height: "100%",
  },
  logoutHeader: {
    "&.MuiListItem-button:hover": {
      backgroundColor: theme.palette.common.white,
      cursor: "default"
    }
  },
  logo: {
    padding: "0 8px",
    height: "24px",
    marginRight: theme.spacing(1.5)
  },
  iconWrapper: {
    marginRight: theme.spacing(0.5),
    height: "100%"
  },
  logoutList: {
    minWidth: "220px"
  },
  notificationList: {
    minWidth: "350px"
  },
  bold: {
    fontWeight: 500
  }
}));

export const AppMenu = () => {
  const classes = useStyles();
  const { update } = useContext(ReloadNotificationsContext);
  const [count, setCount] = useState(0);
  const { notifications, setNotifications } = useContext(NotificationsContext);
  const { isDevStat } = useSelector((state) => state.auth);
  const { name, email } = useSelector((state) => state.auth);

  const getNotification = useCallback(() => {
    getUserReportNotification().then(res => {
      setNotifications(res.data);

      let countNote = 0;

      res.data.forEach(item => {
        if (!item.show) {
          countNote++;
        }
      });

      setCount(countNote);
    });
  }, [setNotifications, setCount]);

  useEffect(() => {
    getNotification();
  }, [update, getNotification]);

  const raportMenu = [
    { linkTo: "/raports/draft", primaryText: "Wersje robocze" },
    { linkTo: "/raports/published", primaryText: "Opublikowane" }
  ];

  const adminMenu = [
    { linkTo: "/devstat", primaryText: "Devstat" },
    { linkTo: "/devstat/log", primaryText: "Logi" },
    { linkTo: "/tasks", primaryText: "Taski" },
    { linkTo: "/users", primaryText: "Użytkownicy" },
    { linkTo: "/admin/devices", primaryText: "User devices" },
    { linkTo: "/devstat/check/sales", primaryText: "Check sell" },
  ];

  const logoutMenu = [
    {
      primaryText: name,
      secondaryText: email,
      disabledClick: true,
      derivedClasses: { item: classes.logoutHeader, primaryText: classes.bold, list: classes.logoutList }
    },
    { divider: true },
    { linkTo: "/logout", primaryText: "Wyloguj się", suffix: <ArrowForwardIosRoundedIcon/> },
  ];

  const notificationMenu = notifications.map((it) => ({
    linkTo: `/reports/published/${it.reportId}`,
    primaryText: it.text,
    derivedClasses: { primaryText: it.show ? "" : classes.bold },
    suffix: <ArrowForwardIosRoundedIcon/>
  }));

  return (
    <AppBar
      position="fixed"
      className={classes.appBar}
      classes={{ root: classes.header }}
    >
      <Toolbar classes={{ root: classes.toolbar }}>
        <NavLink to="/">
          <img src={process.env.PUBLIC_URL + "/logo-as2.svg"} alt="" className={classes.logo}/>
        </NavLink>

        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
          className={classes.menu}
        >
          <HeaderMenuItem linkTo="/bestseller" label="Produkty"/>
          <HeaderMenuItem linkTo="/sale/order" label="Zamówienia"/>
          <HeaderMenuItem linkTo="/sale/designers" label="Projektanci"/>
          <HeaderMenuItem linkTo="/sale/lokalizacje" label="Lokalizacje"/>
          <HeaderMenuItem linkTo="/sale/sygnatury" label="Sygnatury"/>
          <HeaderMenuItem linkTo="/sale/kategorie" label="Kategorie"/>
          <HeaderMenuItem linkTo="/realtime" label="Realtime"/>
          <HeaderMenuItem linkTo="/raports" label="Raporty" items={raportMenu}/>
          {isDevStat ? <HeaderMenuItem linkTo="/devices" label="Mobile"/> : null}
          {isDevStat ? <HeaderMenuItem linkTo="/devstat" label="Admin" items={adminMenu}/> : null}
        </Grid>

        <Grid container
              direction="row"
              justifyContent="flex-end"
              alignItems="stretch"
              wrap="nowrap"
              item
              xs
              className={classes.iconWrapper}
              spacing={1}
        >
          <HeaderMenuItem
            linkTo="/reports/published"
            Icon={<NotificationsRoundedIcon/>}
            iconBadge={true}
            badgeCount={count}
            items={notificationMenu}
            derivedClasses={{ list: classes.notificationList }}
          />
          <HeaderMenuItem
            linkTo="/logout"
            Icon={<PersonRoundedIcon/>}
            items={logoutMenu}
            derivedClasses={{ list: classes.logoutList }}
          />
        </Grid>
      </Toolbar>
    </AppBar>
  );
};
