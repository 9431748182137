import Typography from "@material-ui/core/Typography";
import React from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import { DefaultTooltipContent } from "recharts/lib/component/DefaultTooltipContent";
import { formatter } from "../../../_helpers/ConvertPrice";

const SaleKategorieChart = ({ data, dataKey = "p2" }) => {
  const CustomTooltip = props => {
    if (!props.active) {
      return null;
    }

    const newPayload = [{
      name: "Suma netto [PLN]",
      value: formatter(props.payload[0].payload.priceNetto)
    }];

    return <DefaultTooltipContent {...props} payload={newPayload}/>;
  };

  data.forEach(it => {
    it.priceNetto = parseFloat(it.priceNetto);
  });

  return (
    <>
      <Typography variant="h3">
        Suma netto [PLN]
      </Typography>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart
          data={data}
          margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
        >
          <XAxis dataKey={dataKey} tick={{ fontSize: 12 }}/>
          <YAxis dataKey="priceNetto" tick={{ fontSize: 12 }}/>
          <CartesianGrid strokeDasharray="3 3"/>
          <Tooltip
            content={<CustomTooltip/>}
            wrapperStyle={{ fontSize: 12 }}
          />
          <Legend
            payload={[
              {
                value: "Suma netto [PLN]",
                type: "square",
                color: "#112E51"
              }
            ]}
          />
          <Bar dataKey="priceNetto" fill="#8884d8"/>
        </BarChart>
      </ResponsiveContainer>
    </>
  );
};

export default SaleKategorieChart;
