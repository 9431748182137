import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import React, { useContext, useEffect, useState } from "react";
import { ActiveFilter } from "../../_components/Filters/_activeFilter/ActiveFilter";
import { filterContext } from "../../_context/filterContext";
import { getRealTimeList } from "../../_services/_realtimeService";
import { Pagination } from "../Filters/Pagination";
import RealTimeListItem from "./RealTimeListItem";
import BestsellerListItemPlaceholder from "../Bestseller/BestsellerListItemPlaceholder";
import NoProductAlert from "../../_helpers/NoProductAlert";
import RealTimeSummary from "./RealTimeSummary";

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: "-4px",
    "& .MuiGrid-grid-xs-true": {
      width: "100%",
      maxWidth: "unset"
    }
  },
  grid: {
    "& .activeFilter": {
      paddingTop: 0
    }
  },
  paper: theme.bestsellerPaper,
  placeholderPaper: theme.placeholderPaper,
  filterContainer: {
    paddingTop: "0!important"
  },
  container: {
    marginTop: "-17px"
  },
  realTimeSummary: {
    paddingTop: "0!important"
  }
}));

const RealTimeList = ({ setView }) => {
  const classes = useStyles();
  const { state } = useContext(filterContext);
  const [realTimeList, setRealTimeList] = useState([]);
  const [totalItems, setTotalItems] = useState(state.totalItems);
  const [totalPages, setTotalPages] = useState(0);
  const [limit, setLimit] = useState(state.limit);
  const limitOptions = [20, 40, 60, 80, 100];
  const [isLoading, setIsLoading] = useState(true);
  const [update, setUpdate] = useState(0);

  useEffect(() => {
    setIsLoading(true);
    window.scrollTo(0, 0);
    getRealTimeList(state)
      .then(res => {
        setRealTimeList(res.data.results);
        setTotalItems(parseInt(res.data.pagination.totalItems, 10));
        setTotalPages(parseInt(res.data.pagination.totalPages, 10));
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        setIsLoading(false);
        window.scrollTo(0, 0);
      });

    return () => {};
  }, [state, update]);

  setTimeout(() => {
    setUpdate(update + 1);
  }, 180000);

  const loading = [...Array(5)].map((it, index) => {
    return (
      <Grid item xs={12} key={index}>
        <Paper className={classes.placeholderPaper} key={index}>
          <BestsellerListItemPlaceholder />
        </Paper>
      </Grid>
    );
  });

  const items = realTimeList.map((it, index) => {
    return (
      <Grid item xs={12} key={index}>
        <Paper className={classes.paper}>
          <RealTimeListItem item={it} index={index}></RealTimeListItem>
        </Paper>
      </Grid>
    );
  });

  const pagination = (
    <Pagination
      totalItems={totalItems}
      limitOptions={limitOptions}
      totalPages={totalPages}
      currentLimit={limit}
      setLimit={setLimit}
      context={filterContext}
    ></Pagination>
  );

  const noProductAlert = (
    <NoProductAlert setView={setView} withFilters></NoProductAlert>
  );

  return (
    <Grid container spacing={3} className={classes.grid}>
      <Grid
        item
        xs={12}
        container
        justifyContent="flex-start"
        alignItems="center"
        className={classes.root}
      >
        <Grid item xs container justifyContent="flex-end" spacing={3}>
          <Grid item>{pagination}</Grid>
        </Grid>
      </Grid>
      <ActiveFilter />

      <Grid item xs={12} className={classes.realTimeSummary}>
        <RealTimeSummary />
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={2} className={classes.container}>
          {isLoading ? loading : totalItems > 0 ? items : noProductAlert}
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        container
        justifyContent="flex-start"
        alignItems="center"
        className={classes.root}
      >
        <Grid item xs container justifyContent="flex-end" spacing={3}>
          <Grid item>{totalItems > 0 ? pagination : <></>}</Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RealTimeList;
