import React, { useState, useContext, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import {
  DialogActions,
  DialogContent,
  DialogTitle, IconButton,
  makeStyles,
  Typography
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { RaportContext } from "../../../_context/raportContext";
import { LOAD_DRAFT_VERSION } from "../../../_reducers/raportReducer";
import CircularProgress from "@material-ui/core/CircularProgress";
import useReportListByType from "../../../_hooks/Reports/useReportListByType";
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
  root: {
    " & .MuiDialog-paperWidthSm": {
      maxWidth: "unset!important",
      minWidth: "660px"
    }
  },
  modalContent: {
    width: "100%",
    display: "flex",
    gap: "20px",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    margin: "20px 0px"
  },
  inputField: {
    ...theme.inputField,
    ...theme.inputClearButton,
    width: "90%"
  },
  divider: theme.divider,
  labels: theme.datePriceLabels,
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: "11px",
    color: "black",
  },
  actions: {
    justifyContent: "center",
    padding: "16px 24px"
  },
  title: {
    margin: "0!important"
  },
  actionButton: {
    height: "35px"
  }
}));

const AddToRaportModal = ({ open, handleClose, handleSave, isLoading }) => {
  const classes = useStyles();
  const [selectedRaportId, setSelectedRaportId] = useState(null);
  const { state, dispatch } = useContext(RaportContext);
  const { data } = useReportListByType("draft");

  useEffect(() => {
    if (data) {
      dispatch({
        type: LOAD_DRAFT_VERSION,
        payload: data
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);


  const submit = () => {
    handleSave(selectedRaportId);
  };

  const handleChange = e => {
    const id = e.target.value;
    setSelectedRaportId(id);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="dialog-title"
      className={classes.root}
      PaperProps={{ square: true }}
    >
      <DialogTitle disableTypography>
        <Typography variant="h2" className={classes.title}>
          Do którego raportu chcesz dodać zaznaczoną zawartość?
        </Typography>
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
          <CloseIcon className={classes.closeButton}/>
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <FormControl component="fieldset">
          <RadioGroup aria-label="gender" name="radio-buttons-group">
            {state.draftRaports.map((it, index) => {
              return (
                <FormControlLabel
                  key={index}
                  value={it.id}
                  control={<Radio color="primary"/>}
                  label={it.name}
                  onChange={handleChange}
                />
              );
            })}
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button variant="contained" color="primary" onClick={submit} className={classes.actionButton}>
          {isLoading ? (
            <CircularProgress size={20} style={{ color: "white" }}/>
          ) : (
            "DODAJ"
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddToRaportModal;
