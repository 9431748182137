import { useState, useEffect } from "react";
import { getUserReportByType } from "../../_services/_raportsService";

const useReportListByType = type => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getReports();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  const reload = () => {
    getReports();
  };

  const getReports = () => {
    setIsLoading(true);
    getUserReportByType(type)
      .then(res => {
        setData(res.data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return { data, isLoading, reload };
};

export default useReportListByType;
