import { Paper } from "@material-ui/core";
import BestsellerListItemPlaceholder from "../../Bestseller/BestsellerListItemPlaceholder";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";


const useStyles = makeStyles(theme => ({
  placeholderPaper: theme.placeholderPaper
}));

const ReportPlaceholder = () => {
  const classes = useStyles();

  return (
    <Paper className={classes.placeholderPaper}>
      <BestsellerListItemPlaceholder/>
    </Paper>
  );
}

export default ReportPlaceholder;
