import Request from "./Request";

export const createDraftVersion = data => {
  return Request().post("/api/report/", JSON.stringify(data));
};

export const getDraftRaports = () => {
  return Request().get("/api/report/draft");
};

export const getUserReportByType = type => {
  return Request().get("/api/report/" + type);
};

export const getReportById = id => {
  return Request().get("/api/report/show/" + id);
};

export const reportCompletedStatus = id => {
  return Request().put(`/api/report/status/completed/${id}`);
};

export const removeReport = (id) => {
  return Request().delete("/api/report/" + id);
};

export const addCommentToReport = (id, data) => {
  return Request().put("/api/report/comment/" + id, JSON.stringify(data));
};

export const addNoteToReport = (id, data) => {
  return Request().put("/api/report/note/" + id, JSON.stringify(data));
};

export const duplicateReport = (id, data) => {
  return Request().post("/api/report/duplicate/" + id, JSON.stringify(data));
};

//raportItem

export const removeReportItemArray = (id, data) => {
  return Request().delete("/api/report-item/table/" + id, { data: data });
};

export const updateReportResult = data => {
  return Request().put("/api/report-item/", JSON.stringify(data));
};

export const getReportItemByType = (id, type) => {
  return Request().get(`/api/report-item/${id}/${type}`);
};

export const removeReportItemProduct = data => {
  return Request().delete("/api/report-item/product", { data: data });
};

export const addProductItemToReport = (id, type, data) => {
  return Request().put(`/api/report/item/${type}/` + id, JSON.stringify(data));
};

export const moveReportItemPosition = data => {
  return Request().put("/api/report-item/position", JSON.stringify(data));
};

export const filterReport = (
  raportID,
  designerID,
  categoryID,
  categoryLevel,
  signatures
) => {
  // prettier-ignore
  return Request().get('/api/report-item/'+raportID+'?filter={"designers":['+designerID+'],"category":'+categoryID+',"categoryLevel":'+categoryLevel+',"signatures":['+signatures+']}');
};

//shareReport

export const getAllUserAutosuggestion = () => {
  return Request().get("/api/report-share/user/");
};

export const addUserToReport = (id, data) => {
  return Request().put("/api/report-share/" + id, JSON.stringify(data));
};

export const addMultipleUsersToReport = (raportID, users) => {
  return Request().put(
    "/api/report-share/" + raportID + "/users",
    JSON.stringify({ userIdsToAdd: users })
  );
};

export const removeUserFromReport = (id, data) => {
  return Request().delete("/api/report-share/" + id, { data: data });
};

export const getSharedReportsUser = () => {
  return Request().get("/api/report-share/report");
};

export const getAllUsersAddedToReport = id => {
  return Request().get("/api/report-share/user/" + id);
};

//notifications

export const getUserReportNotification = () => {
  return Request().get("/api/report-notification/");
};

export const clearAllUserNotification = () => {
  return Request().delete("/api/report-notification/");
};

export const markReportUserNotificationAsSeen = id => {
  return Request().put("/api/report-notification/" + id);
};
