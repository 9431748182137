import {
  DATE_FROM,
  DATE_TO,
  CLEAR_DATE,
  PRICE_TO,
  PRICE_FROM,
  CLEAR_PRICE,
  DISCOUNT_FROM,
  DISCOUNT_TO,
  CLEAR_DISCOUNT,
  IS_PAID,
  IS_SALE,
  URL_QUERY,
  PRODUCT_QUERY,
  LOAD_FILTERS,
  UPDATE_SEASON,
  UPDATE_SYGNATURAS
} from "../_actions/filtersActions";

const initialState = {
  dateFrom: null,
  dateTo: null,
  priceFrom: undefined,
  priceTo: undefined,
  disFrom: undefined,
  disTo: undefined,
  isPaid: 2,
  isSale: 2,
  urlQuery: undefined,
  productQuery: undefined,
  seasons: [],
  sygnaturas: [],
  hasFilters: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DATE_FROM: {
      return {
        ...state,
        dateFrom: action.payload
      };
    }
    case DATE_TO: {
      return {
        ...state,
        dateTo: action.payload
      };
    }
    case CLEAR_DATE: {
      return {
        ...state,
        dateFrom: initialState.dateFrom,
        dateTo: initialState.dateTo
      };
    }
    case PRICE_FROM: {
      return {
        ...state,
        priceFrom: action.payload
      };
    }
    case PRICE_TO: {
      return {
        ...state,
        priceTo: action.payload
      };
    }
    case CLEAR_PRICE: {
      return {
        ...state,
        priceFrom: initialState.priceFrom,
        priceTo: initialState.priceTo
      };
    }
    case DISCOUNT_FROM: {
      return {
        ...state,
        disFrom: action.payload
      };
    }
    case DISCOUNT_TO: {
      return {
        ...state,
        disTo: action.payload
      };
    }
    case CLEAR_DISCOUNT: {
      return {
        disFrom: initialState.disFrom,
        disTo: initialState.disTo
      };
    }
    case IS_PAID: {
      return {
        ...state,
        isPaid: action.payload
      };
    }
    case IS_SALE: {
      return {
        ...state,
        isSale: parseInt(action.payload, 10)
      };
    }
    case URL_QUERY: {
      return {
        ...state,
        urlQuery: action.payload
      };
    }
    case PRODUCT_QUERY: {
      return {
        ...state,
        productQuery: action.payload
      };
    }
    case LOAD_FILTERS: {
      // const { data } = action.payload;
      const { seasons, sygnaturas } = action.payload;

      return {
        ...state,
        hasFilters: true,
        seasons: seasons.map(it => {
          return {
            ...it,
            isSelected: 0
          };
        }),
        sygnaturas: sygnaturas.map(it => {
          return {
            ...it,
            isSelected: 0
          };
        })
      };
    }
    case UPDATE_SEASON:
      // let { value, isSelected } = action.payload;
      return {
        ...state,
        seasons: state.seasons.map(it => {
          if (action.payload.value.indexOf(it.name) >= 0) {
            return {
              ...it,
              isSelected: action.payload.isSelected
            };
          }

          return it;
        })
      };
    case UPDATE_SYGNATURAS:
      // let { value, isSelected } = action.payload;
      return {
        ...state,
        sygnaturas: state.sygnaturas.map(it => {
          if (action.payload.value.indexOf(it.name) >= 0) {
            return {
              ...it,
              isSelected: action.payload.isSelected
            };
          }

          return it;
        })
      };
    default: {
      return { ...state };
    }
  }
};
