import React, { useState } from "react";
import ReportAccordionPanel from "./ReportAccordionPanel";
import Grid from "@material-ui/core/Grid";
import MUIDataTable from "mui-datatables";
import {
  round,
  createData
} from "./shared/TablesFunctions";
import { ProgressBarComponent } from "../ProgressBarComponent";
import { HeadLabel } from "./shared/HeadLabel";
import { formatter } from "../../_helpers/ConvertPrice";
import { MUIDataTableNumericSort } from "./shared/helpers";
import CommentsButton from "./shared/CommentsButton";
import ReportItemTypes from "./shared/report-item-types";
import { removeReportItemArray, updateReportResult } from "../../_services/_raportsService";
import { Button } from "@material-ui/core";
import CachedIcon from "@material-ui/icons/CachedRounded";
import DeleteIcon from "@material-ui/icons/DeleteForeverRounded";
import ConfirmRemoveModal from "./shared/ConfirmRemoveModal";

const SignaturesReportPanel = ({ data, comments, id, isDraft, triggerUpdate }) => {
  const title = "SYGNATURY";

  const [openDialog, setOpenDialog] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);

  const getRowsToModify = () => !!selectedIds.length
    ? selectedIds.join(",")
    : data?.result?.map(it => it.id).join(",");

  const handleUpdateData = () => {
    updateReportResult({ ids: data?.id })
      .then(res => triggerUpdate(ReportItemTypes.Signature));
  };

  const handleRemove = () => {
    const obj = { ids: getRowsToModify() };

    removeReportItemArray(data?.id, obj).then(res => {
      if (res.status === 204) {
        setSelectedIndex([]);
        setSelectedIds([]);
        setOpenDialog(false);
        triggerUpdate(ReportItemTypes.Signature);
      }
    });
  };

  const handleSelected = selectedRow => {
    const index = selectedRow[0].dataIndex;
    const id = data.result.total[index].id;

    const exist = selectedIndex.includes(index);
    let newArr = [...selectedIndex];
    let newArrIds = [...selectedIds];

    if (exist) {
      newArr = newArr.filter(it => it !== index);
      newArrIds = newArrIds.filter(it => it !== id);
    } else {
      newArr.push(index);
      newArrIds.push(id);
    }

    setSelectedIds(newArrIds);
    setSelectedIndex(newArr);
  };

  const parsedData = data?.result?.total.map(it =>
    createData(
      it.name ? it.name : "-",
      it.qtyTotal ? it.qtyTotal : "-",
      it.qtySell ? it.qtySell : "-",
      it.qtyReturned ? it.qtyReturned : "-",
      it.percentRabat ? round(it.percentRabat, 2) : "-",
      it.avgVatRate ? round(it.avgVatRate, 2) : "-",
      it.avgPriceNetto ? formatter(it.avgPriceNetto) : "-",
      it.priceNetto ? formatter(it.priceNetto) : "-"
    )
  );

  const options = {
    download: false,
    pagination: true,
    print: false,
    filter: false,
    viewColumns: true,
    selectableRows: isDraft,
    onRowsSelect: isDraft ? handleSelected : null,
    rowsSelected: isDraft ? selectedIndex : null,
    disableToolbarSelect: true,
    selectableRowsHeader: false,
    sortOrder: {
      name: "priceNetto",
      direction: "desc"
    },
    textLabels: {
      pagination: {
        rowsPerPage: "Wyświetl"
      },
      toolbar: {
        search: "Szukaj"
      }
    }
  };

  let actionButtons;

  if (isDraft) {
    actionButtons = [
      <Button
        color="primary"
        variant="outlined"
        onClick={() => handleUpdateData()}
      >
        <CachedIcon/>
        Aktualizuj dane
      </Button>,
      <Button
        variant="outlined"
        color="primary"
        style={{ color: "#9B0000" }}
        onClick={() => setOpenDialog(true)}
      >
        <DeleteIcon/>
        {!!selectedIds.length ? "Usuń pozycje" : "Usuń tabelę"}
        <ConfirmRemoveModal
          open={openDialog}
          handleClose={() => setOpenDialog(false)}
          handleConfirm={() => handleRemove()}
        />
      </Button>
    ];
  } else {
    actionButtons = [
      <CommentsButton comments={comments} title={title} type={ReportItemTypes.Signature}/>
    ];
  }

  return data?.result?.total?.length ? (
    <ReportAccordionPanel
      id="signatures"
      title={title}
      reportId={id}
      actionButtons={actionButtons}
    >
      <Grid container direction="column" spacing={3} alignItems="stretch" justifyContent="flex-start">
        <Grid item xs>
          <MUIDataTable data={parsedData} columns={columns} options={options}/>
        </Grid>
        <Grid item xs>
          <ProgressBarComponent type={data?.result?.type}/>
        </Grid>
      </Grid>
    </ReportAccordionPanel>
  ) : null;
};

export default SignaturesReportPanel;

const columns = [
  {
    name: "name",
    label: "Nazwa",
    options: {
      filter: true,
      sort: true,
      customHeadLabelRender: (value, tableMeta) => (
        <HeadLabel value={value.label}/>
      )
    }
  },
  {
    name: "total",
    label: "Sprzedane - Zwrócone [szt.]",
    options: {
      filter: true,
      sort: true,
      sortCompare: MUIDataTableNumericSort,
      customHeadLabelRender: (value, tableMeta) => (
        <HeadLabel value={value.label}/>
      )
    }
  },
  {
    name: "sell",
    label: "Sprzedane [szt.]",
    options: {
      filter: true,
      sort: true,
      sortCompare: MUIDataTableNumericSort,
      customHeadLabelRender: (value, tableMeta) => (
        <HeadLabel value={value.label}/>
      )
    }
  },
  {
    name: "returned",
    label: "Zwrócone [szt.]",
    options: {
      filter: true,
      sort: true,
      sortCompare: MUIDataTableNumericSort,
      customHeadLabelRender: (value, tableMeta) => (
        <HeadLabel value={value.label}/>
      )
    }
  },
  {
    name: "rabat",
    label: "Rabat [%]",
    options: {
      filter: true,
      sort: true,
      sortCompare: MUIDataTableNumericSort,
      customHeadLabelRender: (value, tableMeta) => (
        <HeadLabel value={value.label}/>
      )
    }
  },
  {
    name: "avg_vat",
    label: "Średni vat sprzedaży [%]",
    options: {
      filter: true,
      sort: true,
      sortCompare: MUIDataTableNumericSort,
      customHeadLabelRender: (value, tableMeta) => (
        <HeadLabel value={value.label}/>
      )
    }
  },
  {
    name: "avg_p_netto",
    label: "Średnia cena netto [PLN]",
    options: {
      filter: true,
      sort: true,
      sortCompare: MUIDataTableNumericSort,
      customHeadLabelRender: (value, tableMeta) => (
        <HeadLabel value={value.label}/>
      )
    }
  },
  {
    name: "priceNetto",
    label: "Suma netto [PLN]",
    options: {
      filter: true,
      sort: true,
      sortCompare: MUIDataTableNumericSort,
      customHeadLabelRender: (value, tableMeta) => (
        <HeadLabel value={value.label}/>
      )
    }
  }
];
