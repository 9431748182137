import { useEffect, useState } from "react";
import { getReportById } from "../../_services/_raportsService";

const useReportById = id => {
  const [data, setData] = useState([]);

  useEffect(() => {
    if (!!id){
      getReportById(id).then(res => {
        setData(res.data);
      });
    }
  }, [id]);

  return { data };
};

export default useReportById;
