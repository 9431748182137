import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { getSummaryRealTime } from "../../_services/_realtimeService";
import { convertDate } from "../../_helpers/ConvertDate";
import RealTimeSummaryChartSprzedaze from "./Sprzedaze/RealTimeSummaryChartSprzedaze";
import { Button, Grid, Paper, Typography } from "@material-ui/core";
import BestsellerListItemPlaceholder from "../Bestseller/BestsellerListItemPlaceholder";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import SelectSygnatura from "./SelectSygnatura";
import RealTimeSummaryTableSprzedaze from "./Sprzedaze/RealTimeSummaryTableSprzedaze";
import RealTimeSummaryTableZwroty from "./Zwroty/RealTimeSummaryTableZwroty";
import SelectedSygnaturySprzedazContext from "./Sprzedaze/SelectedSygnaturySprzedazContext";
import SelectedSygnaturyZwrotyContext from "./Zwroty/SelectedSygnaturyZwrotyContext";
import RealTimeSummaryChartZwroty from "./Zwroty/RealTimeSummaryChartZwroty";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import TotalDetails from "./TotalDetails";
import axios from "axios";
import useColumns from "./useColumns";

const useStyles = makeStyles(theme => ({
  root: {
    ...theme.filterPaper,
    "& .MuiTypography-h2": {
      margin: "6px 8px"
    },
    "& .MuiButton-root:hover": {
      color: "rgba(0, 0, 0, 0.54)"
    },
    "& .MuiPaper-elevation1": {
      boxShadow: "none"
    }
  },
  divider: theme.divider,
  labels: theme.datePriceLabels,
  inputField: {
    ...theme.inputField,
    ...theme.inputClearButton
  },
  header: {
    paddingBottom: "0!important"
  },
  arrowLeft: {
    transform: "rotate(180deg)"
  },
  sygnaturaType: {
    display: "flex",
    justifyContent: "center",
    marginTop: "10px",
    fontWeight: "500"
  },
  accordionDiv: {
    width: "100%",
    paddingLeft: "15px",
    paddingRight: "15px"
  },
  accordion: {
    border: "1px solid whitesmoke"
  }
}));

export const RealTimeSummary = () => {
  const { setSelectedS } = useContext(SelectedSygnaturySprzedazContext);
  const { setSelectedZ } = useContext(SelectedSygnaturyZwrotyContext);
  const classes = useStyles();
  const [date, setDate] = useState(new Date());
  const [update, setUpdate] = useState(0);
  const currentDate = new Date();
  let timer;
  const refreshSummaryTime = 180000;
  const [isLoading, setIsLoading] = useState(true);
  const [filters, setFilters] = useState({
    online: false,
    stacjonarne: false,
    inne: false,
    total: true
  });
  const [online, setOnline] = useState();
  const [stacjonarne, setStacjonarne] = useState();
  const [inne, setInne] = useState();
  const [
    selectedSygnaturyIdsSprzedaze,
    setSelectedSygnaturyIdsSprzedaze
  ] = useState([]);
  const [selectedSygnaturyIdsZwroty, setSelectedSygnaturyIdsZwroty] = useState(
    []
  );

  const { getColumnsSprzedaz, getColumnsZwroty } = useColumns();

  useEffect(() => {
    setIsLoading(true);

    let cancelSource = axios.CancelToken.source();

    getSummaryRealTime(convertDate(date), cancelSource.token)
      .then(res => {
        setOnline(res.data.online);
        setStacjonarne(res.data.stationary);
        setInne(res.data.other);
      })
      .catch(err => {
        if (err.response) {
          console.error(err);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });

    return function() {
      cancelSource.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, update]);

  const loading = [...Array(1)].map((it, index) => {
    return (
      <Grid item xs={12} key={index}>
        <Paper className={classes.placeholderPaper} key={index}>
          <BestsellerListItemPlaceholder />
        </Paper>
      </Grid>
    );
  });

  const timerFunction = () => {
    timer = setTimeout(() => {
      setUpdate(update + 1);
    }, refreshSummaryTime);
  };

  timerFunction();

  if (convertDate(date) !== convertDate(currentDate)) {
    clearTimeout(timer);
  }

  const changeDate = dirrection => {
    setSelectedSygnaturyIdsSprzedaze([]);
    setSelectedSygnaturyIdsZwroty([]);
    setSelectedS({ online: [], stacjonarne: [], inne: [] });
    setSelectedZ({ online: [], stacjonarne: [], inne: [] });

    let newDate = new Date(date);

    if (dirrection === "prev") {
      newDate.setDate(newDate.getDate() - 1);
      setDate(newDate);
      setIsLoading(true);
    }

    if (dirrection === "next") {
      newDate.setDate(newDate.getDate() + 1);

      if (newDate.getTime() < currentDate.getTime()) {
        setDate(newDate);
        setIsLoading(true);
      }
    }
  };

  // const columnsSprzedaz = [
  //   {
  //     name: "name",
  //     label: "Sygnatura",
  //     options: {
  //       filter: true,
  //       sort: true
  //     }
  //   },
  //   {
  //     name: "qtyTotal",
  //     label: "Sprzedaże",
  //     options: {
  //       filter: true,
  //       sort: true
  //     }
  //   },
  //   {
  //     name: "percentRabat",
  //     label: "Średni rabat [%]",
  //     options: {
  //       filter: true,
  //       sort: true,
  //       customBodyRender: value => {
  //         return parseFloat(value).toFixed(2);
  //       }
  //     }
  //   },
  //   {
  //     name: "priceNetto",
  //     label: "Suma netto [PLN]",
  //     options: {
  //       filter: true,
  //       sort: true,
  //       customBodyRender: value => {
  //         return parseFloat(value).toFixed(2);
  //       }
  //     }
  //   },
  //   {
  //     name: "avgPriceNetto",
  //     label: "Średnia cena netto [PLN]",
  //     options: {
  //       filter: true,
  //       sort: true,
  //       customBodyRender: value => {
  //         return parseFloat(value).toFixed(2);
  //       }
  //     }
  //   }
  // ];

  // const test = { sortBy: "name", direction: "asc" };

  // const columnsZwroty = [
  //   {
  //     name: "name",
  //     label: "Sygnatura",
  //     options: {
  //       filter: true,
  //       sort: true,
  //       sortDirection: null
  //     }
  //   },
  //   {
  //     name: "qtyTotal",
  //     label: "Zwroty",
  //     options: {
  //       filter: true,
  //       sort: true
  //     }
  //   },
  //   {
  //     name: "percentRabat",
  //     label: "Średni rabat [%]",
  //     options: {
  //       filter: true,
  //       sort: true,
  //       customBodyRender: value => {
  //         return parseFloat(value).toFixed(2);
  //       }
  //     }
  //   },
  //   {
  //     name: "priceNetto",
  //     label: "Suma netto [PLN]",
  //     options: {
  //       filter: true,
  //       sort: true,
  //       customBodyRender: value => {
  //         return parseFloat(value).toFixed(2);
  //       }
  //     }
  //   },
  //   {
  //     name: "avgPriceNetto",
  //     label: "Średnia cena netto [PLN]",
  //     options: {
  //       filter: true,
  //       sort: true,
  //       customBodyRender: value => {
  //         return parseFloat(value).toFixed(2);
  //       }
  //     }
  //   }
  // ];

  return (
    <Paper className={classes.root}>
      <Grid container spacing={3}>
        <Grid
          item
          xs={12}
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          className={classes.header}
        >
          <Grid item>
            <Button
              onClick={e => {
                setTimeout(changeDate("prev"), 3000);
              }}
            >
              <ArrowRightAltIcon
                fontSize="small"
                className={classes.arrowLeft}
              ></ArrowRightAltIcon>
              poprzedni
            </Button>
          </Grid>
          <Grid item>
            <Typography variant="h2">Dzień {convertDate(date)}</Typography>
          </Grid>
          <Grid item>
            <Button onClick={e => changeDate("next")}>
              następny<ArrowRightAltIcon fontSize="small"></ArrowRightAltIcon>
            </Button>
          </Grid>
        </Grid>

        <SelectSygnatura
          filters={filters}
          setFilters={setFilters}
          setSelectedSprzedazeIds={setSelectedSygnaturyIdsSprzedaze}
          setSetectedZwrotyIds={setSelectedSygnaturyIdsZwroty}
        />

        {!isLoading ? (
          <>
            {filters.total ? (
              <>
                <div className={classes.accordionDiv}>
                  <Accordion className={classes.accordion}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="total"
                    >
                      <Typography className={classes.heading}>TOTAL</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <TotalDetails date={date} />
                    </AccordionDetails>
                  </Accordion>
                </div>
              </>
            ) : null}

            {filters.online ? (
              <>
                <div className={classes.accordionDiv}>
                  <Accordion className={classes.accordion}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="online"
                    >
                      <Typography className={classes.heading}>
                        ONLINE
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid item xs={6}>
                        <RealTimeSummaryTableSprzedaze
                          data={online.sprzedaz}
                          keyName={"online"}
                          columns={getColumnsSprzedaz("online")}
                          selectedSygnaturyIds={selectedSygnaturyIdsSprzedaze}
                          setSelectedSygnaturyIds={
                            setSelectedSygnaturyIdsSprzedaze
                          }
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <RealTimeSummaryTableZwroty
                          data={online.zwroty}
                          keyName={"online"}
                          columns={getColumnsZwroty("online")}
                          selectedSygnaturyIds={selectedSygnaturyIdsZwroty}
                          setSelectedSygnaturyIds={
                            setSelectedSygnaturyIdsZwroty
                          }
                        />
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </>
            ) : null}

            {filters.stacjonarne ? (
              <>
                <div className={classes.accordionDiv}>
                  <Accordion className={classes.accordion}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="stacjonarne"
                    >
                      <Typography className={classes.heading}>
                        STACJONARNE
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid item xs={6}>
                        <RealTimeSummaryTableSprzedaze
                          data={stacjonarne.sprzedaz}
                          columns={getColumnsSprzedaz("stacjonarne")}
                          keyName={"stacjonarne"}
                          selectedSygnaturyIds={selectedSygnaturyIdsSprzedaze}
                          setSelectedSygnaturyIds={
                            setSelectedSygnaturyIdsSprzedaze
                          }
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <RealTimeSummaryTableZwroty
                          data={stacjonarne.zwroty}
                          columns={getColumnsZwroty("stacjonarne")}
                          keyName={"stacjonarne"}
                          selectedSygnaturyIds={selectedSygnaturyIdsZwroty}
                          setSelectedSygnaturyIds={
                            setSelectedSygnaturyIdsZwroty
                          }
                        />
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </>
            ) : null}

            {filters.inne ? (
              <>
                <div className={classes.accordionDiv}>
                  <Accordion className={classes.accordion}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="inne"
                    >
                      <Typography className={classes.heading}>INNE</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid item xs={6}>
                        <RealTimeSummaryTableSprzedaze
                          data={inne.sprzedaz}
                          keyName={"inne"}
                          columns={getColumnsSprzedaz("inne")}
                          selectedSygnaturyIds={selectedSygnaturyIdsSprzedaze}
                          setSelectedSygnaturyIds={
                            setSelectedSygnaturyIdsSprzedaze
                          }
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <RealTimeSummaryTableZwroty
                          data={inne.zwroty}
                          columns={getColumnsZwroty("inne")}
                          keyName={"inne"}
                          selectedSygnaturyIds={selectedSygnaturyIdsZwroty}
                          setSelectedSygnaturyIds={
                            setSelectedSygnaturyIdsZwroty
                          }
                        />
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </>
            ) : null}
          </>
        ) : (
          loading
        )}

        <Grid item xs={12}>
          {isLoading ? (
            loading
          ) : (
            <RealTimeSummaryChartSprzedaze
              date={convertDate(date)}
              filters={filters}
              update={update}
              sygnaturyIds={selectedSygnaturyIdsSprzedaze}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          {isLoading ? (
            loading
          ) : (
            <RealTimeSummaryChartZwroty
              date={convertDate(date)}
              filters={filters}
              update={update}
              sygnaturyIds={selectedSygnaturyIdsZwroty}
            />
          )}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default RealTimeSummary;
