import React from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

const MuiTableDescComparator = (a, b, orderBy, numeric) => {
  let val1, val2;

  if (typeof a[orderBy] === 'boolean') {
    return a[orderBy] && b[orderBy] ? 0 : (a[orderBy] && !b[orderBy] ? -1 : 1)
  } else if (!Number.isFinite(a[orderBy]) && typeof a[orderBy] !== 'string' && !(a[orderBy] instanceof String)) {
    return 0;
  } else if (numeric) {
    // sort dates and numbers with % char or with different separators
    let op1 = (Number.isFinite(a[orderBy]) ? a[orderBy] < 0 : a[orderBy].startsWith("-")) ? -1 : 1;
    let op2 = (Number.isFinite(b[orderBy]) ? b[orderBy] < 0 : b[orderBy].startsWith("-")) ? -1 : 1;
    val1 = Number.isFinite(a[orderBy]) ? a[orderBy] : parseFloat(a[orderBy].replace(/[^\d.]/g, "")) * op1;
    val2 = Number.isFinite(b[orderBy]) ? b[orderBy] : parseFloat(b[orderBy].replace(/[^\d.]/g, "")) * op2;
  } else {
    val1 = typeof a[orderBy] === 'string' || a[orderBy] instanceof String ? a[orderBy].toLowerCase() : a[orderBy];
    val2 = typeof b[orderBy] === 'string' || a[orderBy] instanceof String ? b[orderBy].toLowerCase() : b[orderBy];
  }

  if (val2 < val1) {
    return -1;
  }

  if (val2 > val1) {
    return 1;
  }

  return 0;
};

const MuiTableStableSort = (array, cmp) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });

  return stabilizedThis.map(el => el[0]);
};

const getMuiTableSorting = (order, orderBy, numeric) =>
  order === "desc"
    ? (a, b) => MuiTableDescComparator(a, b, orderBy, numeric)
    : (a, b) => -MuiTableDescComparator(a, b, orderBy, numeric);


export const MuiTableSortedRows = ({ rows, order, orderBy, headCells, page, rowsPerPage, customCellsData, onClickHandler }) =>
  MuiTableStableSort(rows, getMuiTableSorting(order, orderBy, headCells.find(it => it.id === orderBy)?.numeric))
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    .map((row, index) =>
      <TableRow
        hover
        role="checkbox"
        tabIndex={-1}
        key={`row.name-${index}`}
      >
        {headCells.map((it, ind) => {
          const foundCustomCell = customCellsData.find(h => h.name === it.id);

          return ind === 0 ? (
            <TableCell
              component="th"
              id={`enhanced-table-checkbox-${index}`}
              scope="row"
              key={ind}
              align={"left"}
              onClick={() => it.onClick ? onClickHandler(row): null}
            >
              {row[it.id]}
            </TableCell>
          ) : (
            <TableCell
              key={ind}
              align={it.align ? it.align : headCells.find(h => h.id === it.id)?.numeric ? "right" : "left"}
              onClick={() => it.onClick ? onClickHandler(row) : null}
            >
              {foundCustomCell ? foundCustomCell.content(row) : row[it.id]}
            </TableCell>
          );
        })}
      </TableRow>
    );
