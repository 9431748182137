import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
// import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import React, { useContext, useEffect } from "react";
import NumberFormat from "react-number-format";
import { getLevel3Kat } from "../../../_services/_saleService";
import LinearProgress from "@material-ui/core/LinearProgress";
import { filterContext } from "../../../_context/filterContext";
import { Checkbox } from "@material-ui/core";
import { RaportModeContext } from "../../../_context/raportModeContext";
import { RaportContext } from "../../../_context/raportContext";
import { ADD_TO_SELECTED_CATEGORIES } from "../../../_reducers/raportReducer";

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

const headCells = [
  {
    id: "p2",
    numeric: false,
    disablePadding: true,
    label: "Nazwa"
  },
  {
    id: "qtyTotal",
    numeric: true,
    disablePadding: false,
    label: "Sprzedane - Zwrócone [szt.]"
  },
  {
    id: "qtySell",
    numeric: true,
    disablePadding: false,
    label: "Sprzedane [szt.]"
  },
  {
    id: "qtyReturned",
    numeric: true,
    disablePadding: false,
    label: "Zwrócone [szt.]"
  },
  {
    id: "percentRabat",
    numeric: true,
    disablePadding: false,
    label: "Rabat [%]"
  },
  {
    id: "avgVatRate",
    numeric: true,
    disablePadding: false,
    label: "Średni vat sprzedaży[%]"
  },
  {
    id: "avgPriceNetto",
    numeric: true,
    disablePadding: false,
    label: "Średnia cena netto [PLN]"
  },
  {
    id: "priceNetto",
    numeric: true,
    disablePadding: false,
    label: "Suma netto [PLN]"
  }
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };
  const { isRaportMode } = useContext(RaportModeContext);

  return (
    <TableHead>
      <TableRow>
        {isRaportMode ? (
          <TableCell padding="checkbox">
            {/* <Checkbox
              color="primary"
              // indeterminate={numSelected > 0 && numSelected < rowCount}
              // checked={false}
              onChange={handleSelectAll}
              inputProps={{
                "aria-label": "select all desserts"
              }}
            /> */}
          </TableCell>
        ) : null}

        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding="default"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  progress: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2)
    }
  },
  paper: theme.tablePaper,
  table: {
    minWidth: 750
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  },
  expandedRow: {
    backgroundColor: "rgba(238, 238, 238, 0.7)"
  },
  currencyRow: {
    backgroundColor: "rgba(238, 238, 238, 0.25)"
  },
  currencyCell: {
    paddingLeft: "30px"
  }
}));

export default function KategorieTable({ data }) {
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [page] = React.useState(0);
  const [rowsPerPage] = React.useState(60);
  const [items, setItems] = React.useState([]);
  const [loadingLevel3, setLoadingLevel3] = React.useState(false);
  const { state } = useContext(filterContext);
  const { isRaportMode } = useContext(RaportModeContext);
  const { state: stateRaport, dispatch } = useContext(RaportContext);

  useEffect(() => {
    setItems(handleSetData(data));
  }, [data]);

  const handleSetData = data => {
    return data.map(it => {
      return {
        ...it,
        level3Show: 0
      };
    });
  };

  const getKat3Level = row => {
    setLoadingLevel3(true);
    getLevel3Kat(row.p2Id, state)
      .then(res => {
        setItems(
          items.map(item => {
            return {
              ...item,
              level3Show: row.p2Id === item.p2Id ? !item.level3Show : 0,
              katLevel3: row.p2Id === item.p2Id ? res.data : []
            };
          })
        );
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        setLoadingLevel3(false);
      });
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = event => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  const parseAvgPrice = number => {
    let parseNumber = parseFloat(number);
    return parseNumber.toFixed(2);
  };

  const renderTableRow = (row, index) => {
    const labelId = `enhanced-table-checkbox-${index}`;
    const onRowClick = () => getKat3Level(row);

    return (
      <React.Fragment key={labelId}>
        <TableRow
          hover
          className={row.level3Show ? classes.expandedRow : null}
          role="checkbox"
          tabIndex={-1}
          key={row.p2}
        >
          {isRaportMode ? (
            <TableCell padding="checkbox">
              <Checkbox
                onChange={() =>
                  dispatch({
                    type: ADD_TO_SELECTED_CATEGORIES,
                    payload: row.p2Id
                  })
                }
                color="primary"
                checked={stateRaport.selectedCategories.includes(row.p2Id)}
                inputProps={{
                  "aria-labelledby": labelId
                }}
              />
            </TableCell>
          ) : null}

          <TableCell component="th" id={labelId} scope="row" onClick={onRowClick}>
            {row.p2}
          </TableCell>
          <TableCell align="right" onClick={onRowClick}>{row.qtyTotal}</TableCell>
          <TableCell align="right" onClick={onRowClick}>{row.qtySell}</TableCell>
          <TableCell align="right" onClick={onRowClick}>{row.qtyReturned}</TableCell>
          <TableCell align="right" onClick={onRowClick}>
            <NumberFormat
              value={parseAvgPrice(row.percentRabat)}
              displayType={"text"}
              thousandSeparator={true}
              fixedDecimalScale={2}
            />
          </TableCell>
          <TableCell align="right" onClick={onRowClick}>
            <NumberFormat
              value={parseAvgPrice(row.avgVatRate)}
              displayType={"text"}
              thousandSeparator={true}
              fixedDecimalScale={2}
            />
          </TableCell>
          <TableCell align="right" onClick={onRowClick}>
            <NumberFormat
              value={parseAvgPrice(row.avgPriceNetto)}
              displayType={"text"}
              thousandSeparator={true}
              fixedDecimalScale={2}
            />
          </TableCell>
          <TableCell align="right" onClick={onRowClick}>
            <NumberFormat
              value={row.priceNetto}
              displayType={"text"}
              thousandSeparator={true}
              fixedDecimalScale={2}
            />
          </TableCell>
        </TableRow>
        {row.level3Show
          ? row.katLevel3.map(it => {
              let keyId = 0;
              keyId++;
              const katId = keyId + it.p3;

              return (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={katId}
                  className={classes.currencyRow}
                >
                  {isRaportMode ? (
                    <TableCell padding="checkbox">
                    </TableCell>
                  ) : null}
                  <TableCell
                    component="th"
                    id={labelId + it.p3Id}
                    scope="row"
                    className={classes.currencyCell}
                  >
                    {it.p3.toUpperCase()}
                  </TableCell>
                  <TableCell align="right">{it.qtyTotal}</TableCell>
                  <TableCell align="right">{it.qtySell}</TableCell>
                  <TableCell align="right">{it.qtyReturned}</TableCell>
                  <TableCell align="right">
                    <NumberFormat
                      value={parseAvgPrice(it.percentRabat)}
                      displayType={"text"}
                      thousandSeparator={true}
                      fixedDecimalScale={2}
                    />
                  </TableCell>
                  <TableCell align="right">
                    <NumberFormat
                      value={parseAvgPrice(it.avgVatRate)}
                      displayType={"text"}
                      thousandSeparator={true}
                      fixedDecimalScale={2}
                    />
                  </TableCell>
                  <TableCell align="right">
                    <NumberFormat
                      value={parseAvgPrice(it.avgPriceNetto)}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  </TableCell>
                  <TableCell align="right">
                    <NumberFormat
                      value={it.priceNetto}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  </TableCell>
                </TableRow>
              );
            })
          : null}
      </React.Fragment>
    );
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        {loadingLevel3 ? (
          <div className={classes.progress}>
            <LinearProgress />
          </div>
        ) : (
          ""
        )}
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="small"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={items.length}
            />
            <TableBody>
              {stableSort(items, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return renderTableRow(row, index);
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}
