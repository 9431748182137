import React, { useContext, useEffect, useState } from "react";
import { filterContext } from "../../_context/filterContext";
import { getSaleLokalizacje } from "../../_services/_saleService";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Chart from "react-google-charts";
import { ActiveFilter } from "../Filters/_activeFilter/ActiveFilter";
import TablePlaceholder from "../TablePlaceholder";
import NoProductAlert from "../../_helpers/NoProductAlert";
import { SaleLokalizacjeTable } from "./SaleLokalizacjeComponents/SaleLokalizacjeTable";
import { SaleDesignerTable } from "./Widgets/SaleDesignerTable";
import { SaleSygnaturyTable } from "./Widgets/SaleSygnaturyTable";
import { SaleKategorieTable } from "./Widgets/SaleKategorieTable";
import Chip from "@material-ui/core/Chip";
import DeleteForeverRoundedIcon from "@material-ui/icons/DeleteForeverRounded";
import ReportMenu, { localizationsReportType } from "../Reports/shared/ReportMenu";
import { RaportModeContext } from "../../_context/raportModeContext";

const useStyles = makeStyles(theme => ({
  buttons: {
    marginTop: theme.spacing(1)
  },
  filterPaper: theme.filterPaper,
  chartPaper: {
    ...theme.chartPaper
  }
}));

export const SaleLokalizacjeView = ({ setView }) => {
  const classes = useStyles();

  const { state } = useContext(filterContext);
  const [isLoading, setIsLoading] = useState(true);
  const [saleLokalizacje, setSaleLokalizacje] = useState([]);
  const [countryIds, setCountryIds] = useState([]);
  const [selectedCountryNames, setSelectedCountryNames] = useState([]);
  const [rowsSelected, setRowsSelected] = useState([]);
  const { isRaportMode } = useContext(RaportModeContext);

  useEffect(() => {
    window.scrollTo(0, 0);
    getSaleLokalizacje(state)
      .then(res => {
        setSaleLokalizacje(res.data);
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        setIsLoading(false);
        window.scrollTo(0, 0);
      });
  }, [state]);

  const removeCountry = country => {
    setCountryIds(countryIds.filter(it => it !== country.id));
    setSelectedCountryNames(
      selectedCountryNames.filter(it => it.name !== country.name)
    );
    setRowsSelected(rowsSelected.filter(it => it !== country.index));
  };

  const headerChartTotal = ["Lokalizacje", "Ilość"];
  const dataChartTotal = saleLokalizacje.map(it => [it.code, it.qtyTotal]);

  const dataChart = [headerChartTotal, ...dataChartTotal];

  if (!isLoading && saleLokalizacje.length < 1)
    return <NoProductAlert setView={setView} />;

  return (
    <Grid container spacing={3}>
      {isRaportMode ? (
        <ReportMenu
          reducerKey="selectedLocalization"
          type={localizationsReportType}
        />
      ) : null}
      <Grid item xs={12}>
        <ActiveFilter></ActiveFilter>
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.tablePaper}>
          {isLoading ? (
            <TablePlaceholder />
          ) : (
            <SaleLokalizacjeTable
              data={saleLokalizacje}
              setCountryIds={setCountryIds}
              setSelectedCountryNames={setSelectedCountryNames}
              setRowsSelected={setRowsSelected}
              rowsSelected={rowsSelected}
              isRaportMode={isRaportMode}
            ></SaleLokalizacjeTable>
          )}
        </Paper>
      </Grid>
      {!isRaportMode ? (
        <>
          <Grid item xs={12}>
            {isLoading ? (
              <TablePlaceholder />
            ) : (
              <Paper className={classes.chartPaper}>
                <Typography variant="h2">Lokalizacje</Typography>

                <Chart
                  mapsApiKey="YOUR_KEY_HERE"
                  width={"600px"}
                  height={"350px"}
                  chartType="GeoChart"
                  loader={<div>Loading Chart</div>}
                  data={dataChart}
                />
              </Paper>
            )}
          </Grid>
          <Grid item xs={12}>
            {countryIds.length ? (
              <Paper className={classes.filterPaper}>
                <Typography variant="h2">Wybrane kraje</Typography>
                <Typography variant="h5">
                  {selectedCountryNames.map((it, i, arr) => (
                    <Chip
                      key={i}
                      label={it.name}
                      size="small"
                      deleteIcon={
                        <DeleteForeverRoundedIcon className={classes.icon} />
                      }
                      onDelete={e => removeCountry(it)}
                    ></Chip>
                  ))}
                </Typography>
              </Paper>
            ) : null}
          </Grid>
          <Grid item xs={12}>
            {countryIds.length ? (
              <SaleKategorieTable
                filters={{ ...state, country: countryIds.toString() }}
                ids={countryIds}
              ></SaleKategorieTable>
            ) : null}
          </Grid>
          <Grid item xs={12}>
            {countryIds.length ? (
              <SaleDesignerTable
                filters={{ ...state, country: countryIds.toString() }}
                ids={countryIds}
              ></SaleDesignerTable>
            ) : null}
          </Grid>
          <Grid item xs={12}>
            {countryIds.length ? (
              <SaleSygnaturyTable
                filters={{ ...state, country: countryIds.toString() }}
                ids={countryIds}
              ></SaleSygnaturyTable>
            ) : null}
          </Grid>
        </>
      ) : null}
    </Grid>
  );
};
