import Request from "./Request";

export const getBestsellerList = (filters = {}) => {
  return Request(true).get("/api/bestseller/", {
    params: filters
  });
};

export const getLokalizacje = (filters = {}, id) => {
  return Request(true).get("/api/bestseller/lokalizacje/" + id, {
    params: filters
  });
};

export const getSygnatury = (filters = {}, id) => {
  return Request(true).get("/api/bestseller/sygnatury/" + id, {
    params: filters
  });
};

export const getRozmiary = (filters = {}, id) => {
  return Request(true).get("/api/bestseller/towar/" + id, {
    params: filters
  });
};

export const getWartosci = (filters = {}, id) => {
  return Request(true).get("/api/bestseller/wartosci/" + id, {
    params: filters
  });
};

export const getRabaty = (filters = {}, id) => {
  return Request(true).get("/api/bestseller/rabat/" + id, {
    params: filters
  });
};

export const getDynamika = (filters = {}, id) => {
  return Request(true).get("/api/bestseller/dynamika/" + id, {
    params: filters
  });
};

export const getSezony = (filters = {}, id) => {
  return Request(true).get("/api/bestseller/sezony/" + id, {
    params: filters
  });
};

export const getExtraStockOdl = id => {
  return Request(true).get("/api/bestseller/towar/stock/" + id);
};

export const getTgPriceCompare = id => {
  return Request(true).get("/api/bestseller/pc/" + id);
};
