import { useMutation, useQuery } from "@apollo/react-hooks";
import { Grid, IconButton, Paper, Typography } from "@material-ui/core";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import { makeStyles } from "@material-ui/core/styles";
import { gql } from "apollo-boost";
import React, { useContext, useState } from "react";
import { filterContext, SET_FORM } from "../../../_context/filterContext";
import SavedFilterItem from "./SavedFilterItem";
import { Indicator } from "../../Indicator";

export const GET_SAVED_FILTER = gql`
  {
    savedFilters {
      id
      name
      value
    }
  }
`;

const REMOVE_SAVED_FILTER = gql`
  mutation deleteSavedFilter($id: ID!) {
    deleteSavedFilter(input: { id: $id }) {
      savedFilter {
        id
      }
    }
  }
`;

const useStyles = makeStyles(theme => ({
  root: theme.filterPaper,
  iconButton: {
    width: "18px",
    height: "18px"
  }
}));

export const SavedFilter = ({ handleShowProductList }) => {
  const { dispatch } = useContext(filterContext);
  const classes = useStyles();
  const { loading, error, data } = useQuery(GET_SAVED_FILTER);
  const [removeFilter] = useMutation(REMOVE_SAVED_FILTER, {
    update(cache, { data }) {
      const { savedFilters } = cache.readQuery({ query: GET_SAVED_FILTER });
      cache.writeQuery({
        query: GET_SAVED_FILTER,
        data: {
          savedFilters: savedFilters.filter(
            it => it.id !== data.deleteSavedFilter.savedFilter.id
          )
        }
      });
    }
  });

  const [isEditable, setIsEditable] = useState(0);

  const toggleEditable = e => {
    e.preventDefault();
    e.stopPropagation();
    setIsEditable(!isEditable);
  };

  const handleRemove = it => {
    removeFilter({
      variables: {
        id: it.id
      }
    })
      .then(it => console.log("Success"))
      .catch(err => console.error(err));
  };

  const handleLoad = it => {
    dispatch({ type: SET_FORM, payload: it.value });
    handleShowProductList();
  };

  if (loading) return <Indicator></Indicator>;
  if (error) return <>Błąd ładowania filtrów</>;

  const items = data.savedFilters.map(it => {
    return (
      <Grid key={it.name} item>
        <SavedFilterItem
          item={it}
          remove={handleRemove}
          isEditable={isEditable}
          load={handleLoad}
        />
      </Grid>
    );
  });

  return (
    <Paper className={classes.root}>
      {/* Nie było tego w projekcie - ale musimy dodać mozliwosc usuwania wczesniej dodanego filtra
        Moze być sama ikonka
      */}
      <Grid container direction="column">
        <Grid
          container
          item
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={1}
        >
          <Grid item>
            <Typography variant="h2">Zapisane filtry</Typography>
          </Grid>
          <Grid item>
            <IconButton
              color="primary"
              onClick={e => toggleEditable(e)}
              size="small"
            >
              <EditRoundedIcon
                size="small"
                classes={{ root: classes.iconButton }}
              />
            </IconButton>
          </Grid>
        </Grid>
        <Grid container item spacing={1} xs={12}>
          {items}
        </Grid>
        <Grid item></Grid>
      </Grid>
    </Paper>
  );
};
