import React, { useState, useEffect } from "react";
import { Button, makeStyles, Tooltip } from "@material-ui/core";
import ArrowRightAltRoundedIcon from "@material-ui/icons/ArrowRightAltRounded";
import { Link } from "react-router-dom";
import ShareOutlinedIcon from "@material-ui/icons/ShareOutlined";
import FlipToFrontOutlinedIcon from "@material-ui/icons/FlipToFrontOutlined";
import ShareDrawer from "./ShareDrawer";
import { useSelector } from "react-redux";
import CommentsDrawer from "./CommentsDrawer";
import CreateRaportModal from "../DraftReport/CreateRaportModal";
import { duplicateReport } from "../../../_services/_raportsService";
import { useDispatch } from "react-redux";
import { SUCCESS } from "../../../_actions/alertsActions";
import NoteOutlinedIcon from "@material-ui/icons/NoteOutlined";
import { CommentCountIcon } from "../shared/CommentCountIcon";
import IconButton from "../../Common/IconButton";
import ReportItemTypes from "../shared/report-item-types";
import NoteDrawer from "../DraftReport/NoteDrawer";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: theme.spacing(1.5)
  },
  rightActions: {
    display: "flex",
    flexDirection: "row",
    gap: "10px"
  }
}));

const Actions = ({ reportId, note, ownerUserId, comments, isDraft }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openCommentDrawer, setOpenCommentDrawer] = useState(false);
  const { userId, name } = useSelector(state => state.auth);
  const [commentsCount, setCommentsCount] = useState(null);
  const [openDuplicateModal, setOpenDuplicateModal] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setCommentsCount(comments.length);
  }, [comments]);

  const onClose = () => {
    setOpen(false);
  };

  const handleDuplicate = data => {
    duplicateReport(reportId, data).then(res => {
      dispatch({ type: SUCCESS, payload: "Duplikacja wykonana pomyślnie" });
    });
  };

  return (
    <>
      <div className={classes.root}>
        <Link to="/raports/published">
          <Button variant="outlined" color="primary">
            <ArrowRightAltRoundedIcon style={{ transform: "rotate(180deg)" }}/>
            WRÓĆ DO LISTY
          </Button>
        </Link>

        <div className={classes.rightActions}>
          {ownerUserId === userId ? (
            <>
              <Tooltip title="Duplikuj">
                <IconButton
                  onClick={() => setOpenDuplicateModal(true)}
                  Icon={FlipToFrontOutlinedIcon}
                />
              </Tooltip>

              <Button
                variant="outlined" color="primary"
                onClick={() => setOpenDrawer(true)}
                disabled={!note}
              >
                <NoteOutlinedIcon fontSize="medium"/>
                ZOBACZ NOTATKĘ
              </Button>
            </>
          ) : null}

          <Button variant="outlined" color="primary" onClick={() => setOpenCommentDrawer(true)}>
            <CommentCountIcon count={commentsCount}/>
            KOMENTARZE
          </Button>

          {ownerUserId === userId ? (
            <Button variant="outlined" color="primary" onClick={() => setOpen(true)}>
              <ShareOutlinedIcon fontSize="medium"/>
              UDOSTĘPNIJ
            </Button>
          ) : null}
        </div>
      </div>

      <ShareDrawer open={open} onClose={onClose} reportId={reportId}/>

      <NoteDrawer
        open={openDrawer}
        setOpen={setOpenDrawer}
        reportId={reportId}
        note={note}
        isEditable={false}
      />

      <CommentsDrawer
        open={openCommentDrawer}
        onClose={() => setOpenCommentDrawer(false)}
        title={"ogólne"}
        comments={comments}
        reportId={reportId}
        type={ReportItemTypes.General}
        setCommentsCount={setCommentsCount}
        userName={name}
      />

      <CreateRaportModal
        open={openDuplicateModal}
        handleClose={() => setOpenDuplicateModal(false)}
        handleSave={handleDuplicate}
        title={"Duplikuj"}
      />
    </>
  );
};

export default Actions;
