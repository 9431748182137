import React, { forwardRef } from "react";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  iconButton: {
    minWidth: "unset!important",
    backgroundColor: "#EEEEEE",
    "& svg": {
      margin: "0!important"
    }
  }
}));

const IconButton = forwardRef(({ Icon, onClick, color, ...props }, ref) => {
  const classes = useStyles();

  let colorValue;

  switch (color) {
    case "red":
      colorValue = "#980000";
      break;
    default:
      colorValue = "#3f3f3f"
  }

  return <Button ref={ref} {...props} className={`${classes.iconButton} iconButton`} onClick={() => onClick()}>
    <Icon {...Icon.props} style={{ color: colorValue }}/>
  </Button>;
});

export default IconButton;
