import AppBar from "@material-ui/core/AppBar";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { Link } from "react-scroll";

const useStyles = makeStyles(theme => ({
  appBar: {
    backgroundColor: "black",
    width: "100%",
    height: theme.headerHeight,
    position: "fixed",
    top: theme.headerHeight
  },
  menuWrapper: {
    display: "flex",
    flexDirection: "row",
    height: "100%",
    alignItems: "center",
    gap: "20px",
    paddingLeft: "15px"
  },
  menuItem: {
    color: "#ffffff",
    fontSize: "12px",

    "&:hover": {
      cursor: "pointer"
    }
  }
}));

const offset = -100;

export const ReportsMenu = () => {
  const classes = useStyles();

  return (
    <>
      <AppBar position="fixed" className={classes.appBar}>
        <div className={classes.menuWrapper}>
          <Link offset={offset} smooth={true} duration={200} to="information">
            <div className={classes.menuItem}>INFORMACJE</div>
          </Link>

          <Link offset={offset} smooth={true} duration={200} to="products">
            <div className={classes.menuItem}>PRODUKTY</div>
          </Link>

          <Link offset={offset} smooth={true} duration={200} to="orders">
            <div className={classes.menuItem}>ZAMÓWIENIA</div>
          </Link>

          <Link offset={offset} smooth={true} duration={200} to="designers">
            <div className={classes.menuItem}>PROJEKTANCI</div>
          </Link>

          <Link offset={offset} smooth={true} duration={200} to="localizations">
            <div className={classes.menuItem}>LOKALIZACJE</div>
          </Link>

          <Link offset={offset} smooth={true} duration={200} to="signatures">
            <div className={classes.menuItem}>SYGNATURY</div>
          </Link>

          <Link offset={offset} smooth={true} duration={200} to="categories">
            <div className={classes.menuItem}>KATEGORIE</div>
          </Link>
        </div>
      </AppBar>
    </>
  );
};
