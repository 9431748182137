import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import React from "react";
import { LokalizacjeComponent } from "./components/Lokalizacje";
import { RabatyComponent } from "./components/Rabaty";
import { RozmiaryComponent } from "./components/Rozmiary";
import { SygnaturyComponent } from "./components/Sygnatury";
import { WartosciComponent } from "./components/Wartosci";
import { DynamikaComponent } from "./components/Dynamika";
import { SezonyComponent } from "./components/Sezony";
import PriceCompare from "./components/PriceCompare";

const useStyles = makeStyles(theme => ({
  paper: {
    ...theme.filterPaper,
    position: "relative",
    borderRadius: 0,
    padding: 0,
    marginTop: "5px",
    marginBottom: 0,
    display: "flex",
    justifyContent: "center",
    boxShadow: "0 1px 1px 1px rgba(146,146,146,0.4)"
  },
  switchLabel: {
    textTransform: "uppercase",
    marginLeft: "5px",
    marginRight: "15px"
  },
  fab: {
    margin: theme.spacing(2)
  },
  absolute: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(3)
  },
  filter: {
    position: "absolute",
    right: "0",
    height: "42px",
    backgroundColor: "#37474f",
    color: "white",
    padding: "0 11px 0 3px"
  },
  icon: {
    height: "16px",
    width: "16px",
    backgroundColor: "transparent",
    border: "2px solid white",
    borderRadius: "50px"
  },
  checkedIcon: {
    height: "16px",
    width: "16px",
    backgroundColor: "transparent",
    border: "2px solid white",
    borderRadius: "50px",
    position: "relative",
    "&:after": {
      content: "' '",
      height: "10px",
      width: "10px",
      backgroundColor: "white",
      borderRadius: "50px",
      position: "absolute",
      top: "3px",
      left: "3px"
    }
  },
  formLabel: {
    margin: "4px 0 0 5px"
  },
  container: {
    margin: 0,
    backgroundColor: "#fdfdfd",
    padding: "12px 11px 10px 9px",
    width: "100%"
  }
}));

export const BestsellerNavigation = ({
  isRaportView = false,
  id = null,
  sygnaturyData,
  rozmiaryData = null,
  wartosciData = null,
  lokalizacjeData = null,
  dynamikaData = null,
  rabatyData = null,
  sezonyData = null,
  priceData = null
}) => {
  const classes = useStyles();
  const [value, setValue] = React.useState("sygnatury");
  const [switchValue, setSwitchValue] = React.useState(true);

  let selectedComponent = "";

  switch (value) {
    case "rozmiary":
      selectedComponent = (
        <RozmiaryComponent
          switchValue={switchValue}
          id={id}
          rozmiaryData={rozmiaryData}
        ></RozmiaryComponent>
      );
      break;
    case "sygnatury":
      selectedComponent = (
        <SygnaturyComponent
          switchValue={switchValue}
          id={id}
          sygnaturies={sygnaturyData}
        ></SygnaturyComponent>
      );
      break;
    case "wartosci":
      selectedComponent = (
        <WartosciComponent
          switchValue={switchValue}
          id={id}
          wartosciData={wartosciData}
        ></WartosciComponent>
      );
      break;
    case "lokalizacje":
      selectedComponent = (
        <LokalizacjeComponent
          switchValue={switchValue}
          id={id}
          lokalizacjeData={lokalizacjeData}
        ></LokalizacjeComponent>
      );
      break;
    case "dynamika":
      selectedComponent = (
        <DynamikaComponent
          switchValue={switchValue}
          id={id}
          dynamikaData={dynamikaData}
        ></DynamikaComponent>
      );
      break;
    case "rabaty":
      selectedComponent = (
        <RabatyComponent
          switchValue={switchValue}
          id={id}
          rabatyData={rabatyData}
        ></RabatyComponent>
      );
      break;
    case "sezony":
      selectedComponent = (
        <SezonyComponent
          switchValue={switchValue}
          id={id}
          sezonyData={sezonyData}
        ></SezonyComponent>
      );
      break;
    case "price_compare":
      selectedComponent = (
        <PriceCompare id={id} priceData={priceData}></PriceCompare>
      );
      break;
    default:
  }

  return (
    <>
      <Paper className={classes.paper}>
        <BottomNavigation
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          showLabels
        >
          <BottomNavigationAction value="rozmiary" label="ROZMIARY" />
          <BottomNavigationAction value="sygnatury" label="SYGNATURY" />
          <BottomNavigationAction value="wartosci" label="WARTOŚCI" />
          <BottomNavigationAction value="lokalizacje" label="LOKALIZACJE" />
          <BottomNavigationAction value="dynamika" label="DYNAMIKA" />
          <BottomNavigationAction value="rabaty" label="RABATY" />
          <BottomNavigationAction value="sezony" label="SEZONY" />
          <BottomNavigationAction
            value="price_compare"
            label="CENY KONKURENCJI (TEST)"
          />
        </BottomNavigation>

        {isRaportView ? null : (
          <div className={classes.filter}>
            <Tooltip
              title={
                switchValue
                  ? "Dane z uwzględnieniem wcześniej ustawionych filtrów"
                  : "Filtry wcześniej ustawione nie są branę pod uwagę, poza filtrem 'Tylko opłacone'"
              }
              aria-label="add"
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={switchValue}
                    onChange={(event, newValue) => {
                      setSwitchValue(newValue);
                    }}
                    value={switchValue}
                    color="primary"
                    checkedIcon={
                      <span>
                        <div className={classes.checkedIcon}></div>
                      </span>
                    }
                    icon={<span className={classes.icon} />}
                  />
                }
                label="FILTRY"
                labelPlacement="end"
                classes={{
                  label: classes.switchLabel,
                  root: classes.formLabel
                }}
              />
            </Tooltip>
          </div>
        )}
      </Paper>
      <Grid container spacing={2} className={classes.container}>
        {selectedComponent}
      </Grid>
    </>
  );
};

export default BestsellerNavigation;
