import React from "react";
import { makeStyles, Typography, Dialog, Button } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    " & .MuiDialog-paperWidthSm": {
      maxWidth: "unset!important",
      minWidth: "660px"
    }
  },
  modalContent: {
    width: "100%",
    display: "flex",
    gap: "20px",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    margin: "20px 0px"
  }
}));

const ConfirmRemoveModal = ({
  open,
  handleClose,
  handleConfirm,
  customTitle = false
}) => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className={classes.root}
    >
      <div className={classes.modalContent}>
        <Typography variant="h2">
          {customTitle
            ? customTitle
            : "Czy na pewno chcesz usunąć tę zawartość?"}
        </Typography>
        <Button
          variant="contained"
          style={{ backgroundColor: "#9B0000", color: "#ffffff" }}
          onClick={handleConfirm}
        >
          USUŃ
        </Button>
      </div>
    </Dialog>
  );
};

export default ConfirmRemoveModal;
