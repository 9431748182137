import React, { useState, useContext } from "react";
import { Helmet } from "react-helmet";
import { makeStyles, Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/AddRounded";
import DraftTable from "./DraftTable";
import CreateRaportModal from "./CreateRaportModal";
import { RaportContext } from "../../../_context/raportContext";
import { createDraftVersion } from "../../../_services/_raportsService";
import { ADD_TO_DRAFT_VERSION } from "../../../_reducers/raportReducer";

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.headerHeight,
    backgroundColor: theme.palette.gray,
    flexGrow: 1,
    minHeight: `calc(100vh - ${theme.headerHeight} * 2)`,
    padding: theme.spacing(1.5),
    boxSizing: "border-box"
  },
  paper: theme.bestsellerPaper,
  buttonIcon: theme.reportsButtonIcon
}));

const DraftView = props => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const { dispatch } = useContext(RaportContext);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = data => {
    createDraftVersion(data).then(res => {
      dispatch({ type: ADD_TO_DRAFT_VERSION, payload: res.data });
    });
  };

  return (
    <div className={classes.root}>
      <Helmet>
        <meta charSet="utf-8"/>
        <title>Wersja robocza</title>
      </Helmet>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setOpen(true);
            }}
          >
            <AddIcon fontSize="medium" classes={{ root: classes.buttonIcon }}/>
            UTWÓRZ WERSJĘ ROBOCZĄ
          </Button>
        </Grid>

        <Grid item xs={12}>
          <DraftTable/>
        </Grid>
      </Grid>
      <CreateRaportModal
        open={open}
        handleClose={handleClose}
        handleSave={handleSave}
      />
    </div>
  );
};

export default DraftView;
