export const createData = (
  name,
  total,
  sell,
  returned,
  rabat,
  avg_vat,
  avg_p_netto,
  priceNetto
) => {
  return {
    name,
    total,
    sell,
    returned,
    rabat,
    avg_vat,
    avg_p_netto,
    priceNetto
  };
};

export const round = (n, k) => {
  const factor = Math.pow(10, k + 1);
  n = Math.round(Math.round(n * factor) / 10);
  return n / (factor / 10);
};
