import React from "react";

export const HeadLabel = ({ value, usePadding }) => {
  return (
    <>
      <span
        style={{
          paddingLeft: usePadding && "68px",
          fontSize: "12px"
        }}
      >
        {value === "NaN" || (!value && value !== 0) ? "0" : value}
      </span>
    </>
  );
};
