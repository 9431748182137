import React, { useReducer } from "react";
import { convertDate } from "../_helpers/ConvertDate";

export const SET_FORM = "SET_FORM";
export const SET_PAGE = "SET_PAGE";
export const SET_LIMIT = "SET_LIMIT";
export const SET_SORT = "SET_SORT";
export const SET_FOR_REALTIME = "SET_FOR_REALTIME";
export const SET_GROUP_BY = "SET_GROUP_BY";
export const CLEAR_FILTER = "CLEAR_FILTER";
export const SET_TOW_GRUP_ID = "SET_TOWAR_GRUP_ID";

export const CLEAR_DATE_FROM = "CLEAR_DATE_FROM";
export const CLEAR_DATE_TO = "CLEAR_DATE_TO";
export const CLEAR_PRICE_FROM = "CLEAR_PRICE_FROM";
export const CLEAR_PRICE_TO = "CLEAR_PRICE_TO";
export const CLEAR_DISCOUNT_FROM = "CLEAR_DISCOUNT_FROM";
export const CLEAR_DISCOUNT_TO = "CLEAR_DISCOUNT_TO";
export const CLEAR_PERCENT_RABAT_PER_ITEM_FROM =
  "CLEAR_PERCENT_RABAT_PER_ITEM_FROM";
export const CLEAR_PERCENT_RABAT_PER_ITEM_TO =
  "CLEAR_PERCENT_RABAT_PER_ITEM_TO";
export const CLEAR_IS_PAID = "CLEAR_IS_PAID";
export const CLEAR_IS_SALE = "CLEAR_IS_SALE";
export const CLEAR_IS_CO = "CLEAR_IS_CO";
export const CLEAR_CZY_OPLACONE = "CLEAR_CZY_OPLACONE";
export const CLEAR_URL = "CLEAR_URL";
export const CLEAR_NAME = "CLEAR_NAME";
export const REMOVE_CATEGORY = "REMOVE_CATEGORY";
export const REMOVE_SYGNATURA = "REMOVE_SYGNATURA";
export const REMOVE_DESIGNER = "REMOVE_DESIGNER";
export const REMOVE_COUNTRY = "REMOVE_COUNTRY";
export const REMOVE_SEASON = "REMOVE_SEASON";

const GROUP_BY = [
  {
    name: "Rok",
    value: "year",
    isActive: 1
  },
  {
    name: "Kwartał",
    value: "quarter",
    isActive: 0
  },
  {
    name: "Miesiąc",
    value: "month",
    isActive: 1
  },
  {
    name: "Dzień",
    value: "day",
    isActive: 0
  }
];

const initialValue = {
  designers: "",
  seasons: "",
  sygnaturas: "",
  country: "",
  category: "",
  discountFrom: "",
  discountTo: "",
  percentRabatPerItemFrom: "",
  percentRabatPerItemTo: "",
  dateFrom: "",
  dateTo: "",
  priceFrom: "",
  priceTo: "",
  isPaid: 1,
  isSale: 2,
  isCo: 2,
  czyOplacone: 2,
  queryUrl: "",
  queryName: "",
  page: 1,
  limit: localStorage.getItem("limitPage")
    ? localStorage.getItem("limitPage")
    : 20,
  towGrupId: "",
  groupBy: GROUP_BY,
  orderBy: "qtyTotalDesc",
  _update: 0,
  _updateTowGrupId: 0
};

let reducer = (state, action) => {
  switch (action.type) {
    case SET_FOR_REALTIME:
      const todayDate = convertDate(new Date());
      return state._update === 0
        ? {
            ...state,
            isPaid: 2,
            dateFrom: todayDate,
            dateTo: todayDate,
            _update: 1
          }
        : state;
    case SET_TOW_GRUP_ID:
      return state._updateTowGrupId === 0
        ? {
            ...state,
            towGrupId: action.payload,
            _updateTowGrupId: 1
          }
        : state;
    case SET_FORM:
      return {
        ...state,
        ...action.payload
      };
    case SET_PAGE:
      return {
        ...state,
        page: action.payload
      };
    case SET_LIMIT:
      return {
        ...state,
        limit: action.payload
      };
    case SET_GROUP_BY:
      if (action.payload.value === "quarter" && action.payload.isActive === 0) {
        const isMonth = state.groupBy.filter(it => it.value === "year");

        const s = {
          ...state,
          groupBy: [
            {
              name: "Rok",
              value: "year",
              isActive: isMonth[0]["isActive"]
            },
            {
              name: "Kwartał",
              value: "quarter",
              isActive: 1
            },
            {
              name: "Miesiąc",
              value: "month",
              isActive: 0
            },
            {
              name: "Dzień",
              value: "day",
              isActive: 0
            }
          ]
        };

        return s;
      } else if (
        action.payload.value === "month" &&
        action.payload.isActive === 0
      ) {
        return {
          ...state,
          groupBy: state.groupBy.map(it => {
            if (it.value === "quarter") {
              return {
                ...it,
                isActive: 0
              };
            } else if (it.value === "month") {
              return {
                ...it,
                isActive: 1
              };
            }

            return it;
          })
        };
      }

      return {
        ...state,
        groupBy: state.groupBy.map(it => {
          return it === action.payload ? { ...it, isActive: !it.isActive } : it;
        })
      };

    case CLEAR_FILTER:
      return {
        ...initialValue,
        page: 1,
        _update: state._update + 1
      };
    case SET_SORT:
      return {
        ...state,
        orderBy: action.payload
      };
    case CLEAR_DATE_FROM:
      return {
        ...state,
        page: 1,
        dateFrom: initialValue.dateFrom
      };
    case CLEAR_DATE_TO:
      return {
        ...state,
        page: 1,
        dateTo: initialValue.dateTo
      };
    case CLEAR_PRICE_FROM:
      return {
        ...state,
        page: 1,
        priceFrom: initialValue.priceFrom
      };
    case CLEAR_PRICE_TO:
      return {
        ...state,
        page: 1,
        priceTo: initialValue.priceTo
      };
    case CLEAR_DISCOUNT_FROM:
      return {
        ...state,
        page: 1,
        discountFrom: initialValue.discountFrom
      };
    case CLEAR_DISCOUNT_TO:
      return {
        ...state,
        page: 1,
        discountTo: initialValue.discountTo
      };
    case CLEAR_PERCENT_RABAT_PER_ITEM_FROM:
      return {
        ...state,
        page: 1,
        percentRabatPerItemFrom: initialValue.percentRabatPerItemFrom
      };
    case CLEAR_PERCENT_RABAT_PER_ITEM_TO:
      return {
        ...state,
        page: 1,
        percentRabarPerItemTo: initialValue.percentRabarPerItemTo
      };
    case CLEAR_IS_PAID:
      return {
        ...state,
        page: 1,
        isPaid: initialValue.isPaid
      };
    case CLEAR_CZY_OPLACONE:
      return {
        ...state,
        page: 1,
        czyOplacone: initialValue.czyOplacone
      };
    case CLEAR_IS_SALE:
      return {
        ...state,
        page: 1,
        isSale: initialValue.isSale
      };
    case CLEAR_IS_CO:
      return {
        ...state,
        page: 1,
        isCo: initialValue.isCo
      };
    case CLEAR_URL:
      return {
        ...state,
        page: 1,
        queryUrl: initialValue.queryUrl
      };
    case CLEAR_NAME:
      return {
        ...state,
        page: 1,
        queryName: initialValue.queryName
      };
    case REMOVE_CATEGORY:
      const id_remove = action.payload.toString();
      const newCategory = state.category
        .split(",")
        .filter(it => it !== id_remove);
      return {
        ...state,
        category: newCategory.length ? newCategory.join(",") : ""
      };
    case REMOVE_SYGNATURA:
      const id_syg = action.payload.toString();
      const newSygnatury = state.sygnaturas
        .split(",")
        .filter(it => it !== id_syg);
      return {
        ...state,
        sygnaturas: newSygnatury.length ? newSygnatury.join(",") : ""
      };
    case REMOVE_DESIGNER:
      const id_proj = action.payload.toString();
      const newDesigners = state.designers
        .split(",")
        .filter(it => it !== id_proj);
      return {
        ...state,
        designers: newDesigners.length ? newDesigners.join(",") : ""
      };
    case REMOVE_COUNTRY:
      const id_country = action.payload.toString();
      const newCountries = state.country
        .split(",")
        .filter(it => it !== id_country);
      return {
        ...state,
        country: newCountries.length ? newCountries.join(",") : ""
      };
    case REMOVE_SEASON:
      const id_season = action.payload.toString();
      const newSeasons = state.seasons
        .split(",")
        .filter(it => it !== id_season);
      return {
        ...state,
        seasons: newSeasons.length ? newSeasons.join(",") : ""
      };
    default:
      return state;
  }
};

export const filterContext = React.createContext(initialValue);

export const FilterProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialValue);

  return (
    <filterContext.Provider value={{ state, dispatch }}>
      {children}
    </filterContext.Provider>
  );
};
