import React from "react";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

const FilterAutocomplete = ({options, setValue, value, title}) => {
  return (
    <Grid container direction="column" alignItems="stretch" spacing={1}>
      <Grid item>
        <Typography variant="h6"><strong>{title}</strong></Typography>
      </Grid>
      <Grid item xs>
        <Autocomplete
          options={options}
          value={value}
          onChange={(e, v) => setValue(v)}
          renderInput={(params) =>
            <TextField {...params} placeholder="Wybierz" variant="outlined"/>
          }
          renderOption={(option) => <Typography noWrap>{option}</Typography>}
        />
      </Grid>
    </Grid>
  );
};

export default FilterAutocomplete;
