import React, { useContext, useState } from "react";
import { filterContext } from "../../../_context/filterContext";
import { makeStyles } from "@material-ui/core/styles";
import ActiveFilterItem from "./ActiveFilterItem";
import ActiveFilterCategory from "./ActiveFilterCategory";
import ActiveFilterSygnatura from "./ActiveFilterSygnatura";
import ActiveFilterDesigner from "./ActiveFilterDesigner";
import ActiveFilterCountry from "./ActiveFilterCountry";
import ActiveFilterSeason from "./ActiveFilterSeason";
import { Button, Paper, Grid, Typography } from "@material-ui/core";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import ChangeActiveFilterDialogBox from "./ChangeActiveFilterDialogBox";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5)
    },
    "& .MuiButtonBase-root": {
      margin: 0
    },
    marginTop: theme.spacing(-1),
    "& .MuiChip-root": {
      color: theme.palette.common.black,
      backgroundColor: theme.palette.common.white,
      border: `1px solid ${theme.palette.divider}`,
      "& .MuiSvgIcon-root": {
        color: theme.palette.divider,
        "&:hover": {
          color: "#969696"
        }
      }
    }
  },
  paper: theme.filterPaper,
  removeButton: {
    height: "20px",
    padding: 0,
    letterSpacing: 0,
    "&:hover": {
      backgroundColor: "transparent"
    },
    "& .MuiButton-startIcon": {
      marginRight: "2px"
    },
    "& .MuiButton-iconSizeMedium > *:first-child": {
      fontSize: "18px"
    }
  }
}));

export const ActiveFilter = () => {
  const classes = useStyles();
  const { dispatch } = useContext(filterContext);
  const [openDialog, setOpenDialog] = useState(false);
  const [name, setName] = useState("");

  const changeActiveFilterDialogBox = (
    <ChangeActiveFilterDialogBox
      open={openDialog}
      setOpen={setOpenDialog}
      name={name}
    />
  );

  const idListCategory = [];
  const idListSygnatura = [];
  const idListDesigner = [];
  const idListCountry = [];
  const idListSeason = [];

  const {
    state: {
      dateFrom,
      dateTo,
      priceFrom,
      priceTo,
      discountFrom,
      discountTo,
      percentRabatPerItemFrom,
      percentRabatPerItemTo,
      isPaid,
      czyOplacone,
      isSale,
      isCo,
      queryUrl,
      queryName,
      category,
      sygnaturas,
      designers,
      country,
      seasons
    }
  } = useContext(filterContext);

  let dFrom = "";
  let dTo = "";
  let pFrom = "";
  let pTo = "";
  let disFrom = "";
  let disTo = "";
  let percentPerItemFrom = "";
  let percentPerItemTo = "";
  let isP = "";
  let czyOpl = "";
  let isS = "";
  let is_Co = "";
  let qUrl = "";
  let qName = "";

  if (category.length > 0) {
    category.split(",").map(it => idListCategory.push(parseInt(it)));
  }

  if (sygnaturas.length > 0) {
    sygnaturas.split(",").map(it => idListSygnatura.push(parseInt(it)));
  }

  if (designers.length > 0) {
    designers.split(",").map(it => idListDesigner.push(parseInt(it)));
  }

  if (country.length > 0) {
    country.split(",").map(it => idListCountry.push(parseInt(it)));
  }

  if (seasons.length > 0) {
    seasons.split(",").map(it => idListSeason.push(parseInt(it)));
  }

  const handleRemove = (actionType, payload = null) => {
    dispatch({ type: actionType, payload: payload });
  };

  const removeAll = () => {
    dispatch({ type: "CLEAR_FILTER" });
  };

  const dateDialogOpen = () => {
    setName("date");
    setOpenDialog(true);
  };

  const priceDialogOpen = () => {
    setName("price");
    setOpenDialog(true);
  };

  const disountDialogOpen = () => {
    setName("discount");
    setOpenDialog(true);
  };

  const searchUrlDialogOpen = () => {
    setName("search");
    setOpenDialog(true);
  };

  const isPaidDialogOpen = () => {
    setName("isPaid");
    setOpenDialog(true);
  };

  const czyOplaconeDialogOpen = () => {
    setName("czyOplacone");
    setOpenDialog(true);
  };

  const isSaleOpenDialog = () => {
    setName("isSale");
    setOpenDialog(true);
  };

  const isCoOpenDialog = () => {
    setName("isCo");
    setOpenDialog(true);
  };

  const categoryOpenDialog = () => {
    setName("category");
    setOpenDialog(true);
  };

  const sygnaturaOpenDialog = () => {
    setName("sygnatura");
    setOpenDialog(true);
  };

  const countryOpenDialog = () => {
    setName("country");
    setOpenDialog(true);
  };

  const seasonOpenDialog = () => {
    setName("season");
    setOpenDialog(true);
  };

  const designersOpenDialog = () => {
    setName("designer");
    setOpenDialog(true);
  };

  const RemoveButton = (
    <Button
      color="primary"
      startIcon={<DeleteForeverIcon />}
      onClick={e => removeAll()}
      className={classes.removeButton}
    >
      USUŃ WSZYSTKIE
    </Button>
  );
  if (dateFrom) {
    dFrom = (
      <ActiveFilterItem
        label={`Data od: ${dateFrom}`}
        type={"CLEAR_DATE_FROM"}
        remove={handleRemove}
        change={dateDialogOpen}
      ></ActiveFilterItem>
    );
  }

  if (dateTo) {
    dTo = (
      <ActiveFilterItem
        label={`Data do: ${dateTo}`}
        type={"CLEAR_DATE_TO"}
        remove={handleRemove}
        change={dateDialogOpen}
      ></ActiveFilterItem>
    );
  }

  if (priceFrom) {
    pFrom = (
      <ActiveFilterItem
        label={`Cena od: ${priceFrom}`}
        type={"CLEAR_PRICE_FROM"}
        remove={handleRemove}
        change={priceDialogOpen}
      ></ActiveFilterItem>
    );
  }

  if (priceTo) {
    pTo = (
      <ActiveFilterItem
        label={`Cena do: ${priceTo}`}
        type={"CLEAR_PRICE_TO"}
        remove={handleRemove}
        change={priceDialogOpen}
      ></ActiveFilterItem>
    );
  }

  if (discountFrom) {
    disFrom = (
      <ActiveFilterItem
        label={`Średni rabat od: ${discountFrom}%`}
        type={"CLEAR_DISCOUNT_FROM"}
        remove={handleRemove}
        change={disountDialogOpen}
      ></ActiveFilterItem>
    );
  }

  if (discountTo) {
    disTo = (
      <ActiveFilterItem
        label={`Średni rabat do: ${discountTo}%`}
        type={"CLEAR_DISCOUNT_TO"}
        remove={handleRemove}
        change={disountDialogOpen}
      ></ActiveFilterItem>
    );
  }

  if (percentRabatPerItemFrom) {
    percentPerItemFrom = (
      <ActiveFilterItem
        label={`Rabat dla produktu od: ${percentRabatPerItemFrom}%`}
        type={"CLEAR_PERCENT_RABAT_PER_ITEM_FROM"}
        remove={handleRemove}
      ></ActiveFilterItem>
    );
  }

  if (percentRabatPerItemTo) {
    percentPerItemTo = (
      <ActiveFilterItem
        label={`Rabat dla produktu do: ${percentRabatPerItemTo}%`}
        type={"CLEAR_PERCENT_RABAT_PER_ITEM_TO"}
        remove={handleRemove}
      ></ActiveFilterItem>
    );
  }

  if (queryUrl) {
    qUrl = (
      <ActiveFilterItem
        label={`Url: ${queryUrl}`}
        type={"CLEAR_URL"}
        remove={handleRemove}
        change={searchUrlDialogOpen}
      ></ActiveFilterItem>
    );
  }

  if (queryName) {
    qName = (
      <ActiveFilterItem
        label={`Produkt: ${queryName}`}
        type={"CLEAR_NAME"}
        remove={handleRemove}
        change={searchUrlDialogOpen}
      ></ActiveFilterItem>
    );
  }

  if (isPaid) {
    switch (isPaid) {
      case "2":
        isP = (
          <ActiveFilterItem
            label="Faktura Vat: Opłacone + Nieopłacone"
            type={"CLEAR_IS_PAID"}
            remove={handleRemove}
            change={isPaidDialogOpen}
          ></ActiveFilterItem>
        );
        break;
      case "1":
        isP = (
          <ActiveFilterItem
            label="Faktura Vat: tylko opłacone"
            type={"CLEAR_IS_PAID"}
            remove={handleRemove}
            change={isPaidDialogOpen}
          ></ActiveFilterItem>
        );
        break;
      case "0":
        isP = (
          <ActiveFilterItem
            label="Faktura Vat: tylko nieopłacone"
            type={"CLEAR_IS_PAID"}
            remove={handleRemove}
            change={isPaidDialogOpen}
          ></ActiveFilterItem>
        );
        break;
      default:
    }
  }

  if (czyOplacone) {
    switch (czyOplacone) {
      case "2":
        czyOpl = (
          <ActiveFilterItem
            label="Potwierdzenie płatności: Opłacone + Nieopłacone"
            type={"CLEAR_CZY_OPLACONE"}
            remove={handleRemove}
            change={czyOplaconeDialogOpen}
          ></ActiveFilterItem>
        );
        break;
      case "1":
        czyOpl = (
          <ActiveFilterItem
            label="Potwierdzenie płatności: tylko opłacone"
            type={"CLEAR_CZY_OPLACONE"}
            remove={handleRemove}
            change={czyOplaconeDialogOpen}
          ></ActiveFilterItem>
        );
        break;
      case "0":
        czyOpl = (
          <ActiveFilterItem
            label="Potwierdzenie płatności: tylko nieopłacone"
            type={"CLEAR_CZY_OPLACONE"}
            remove={handleRemove}
            change={czyOplaconeDialogOpen}
          ></ActiveFilterItem>
        );
        break;
      default:
    }
  }

  if (isSale) {
    switch (isSale) {
      case "2":
        isS = (
          <ActiveFilterItem
            label="Status: sprzedaż z uwzględnieniem zwrotów"
            type={"CLEAR_IS_SALE"}
            remove={handleRemove}
            change={isSaleOpenDialog}
          ></ActiveFilterItem>
        );
        break;
      case "1":
        isS = (
          <ActiveFilterItem
            label="Status: sprzedaż bez uwzględnienia zwrotów"
            type={"CLEAR_IS_SALE"}
            remove={handleRemove}
            change={isSaleOpenDialog}
          ></ActiveFilterItem>
        );
        break;
      case "0":
        isS = (
          <ActiveFilterItem
            label="Status: tylko zwroty"
            type={"CLEAR_IS_SALE"}
            remove={handleRemove}
            change={isSaleOpenDialog}
          ></ActiveFilterItem>
        );
        break;
      default:
    }
  }

  if (isCo) {
    switch (isCo) {
      case "2":
        is_Co = (
          <ActiveFilterItem
            label="Carry Over + sprzedaż sezonowa"
            type={"CLEAR_IS_CO"}
            remove={handleRemove}
            change={isCoOpenDialog}
          ></ActiveFilterItem>
        );
        break;
      case "1":
        is_Co = (
          <ActiveFilterItem
            label="Tylko Carry Over"
            type={"CLEAR_IS_CO"}
            remove={handleRemove}
            change={isCoOpenDialog}
          ></ActiveFilterItem>
        );
        break;
      case "0":
        is_Co = (
          <ActiveFilterItem
            label="Tylko sprzedaż sezonowa"
            type={"CLEAR_IS_CO"}
            remove={handleRemove}
            change={isCoOpenDialog}
          ></ActiveFilterItem>
        );
        break;
      default:
    }
  }

  if (
    idListCategory.length ||
    idListSygnatura.length ||
    idListDesigner.length ||
    idListCountry.length ||
    idListSeason.length ||
    dFrom ||
    dTo ||
    pFrom ||
    pTo ||
    disFrom ||
    disTo ||
    qUrl ||
    qName ||
    isP ||
    czyOpl ||
    isS ||
    is_Co
  ) {
    return (
      <Grid item xs={12} className="activeFilter">
        <Paper className={classes.paper}>
          <Grid container direction="column">
            <Grid item container justifyContent="space-between">
              <Typography variant="h2">Aktywne filtry</Typography>
              {RemoveButton}
            </Grid>
            <Grid item className={classes.activeFilter}>
              <div className={classes.root}>
                {dFrom}
                {dTo}
                {pFrom}
                {pTo}
                {disFrom}
                {disTo}
                {percentPerItemFrom}
                {percentPerItemTo}
                {isP}
                {czyOpl}
                {isS}
                {is_Co}
                {qUrl}
                {qName}
                {idListCategory.length ? (
                  <ActiveFilterCategory
                    IdList={idListCategory}
                    removeAction={handleRemove}
                    change={categoryOpenDialog}
                  ></ActiveFilterCategory>
                ) : (
                  ""
                )}
                {idListSygnatura.length ? (
                  <ActiveFilterSygnatura
                    IdList={idListSygnatura}
                    removeAction={handleRemove}
                    change={sygnaturaOpenDialog}
                  ></ActiveFilterSygnatura>
                ) : (
                  ""
                )}
                {idListDesigner.length ? (
                  <ActiveFilterDesigner
                    IdList={idListDesigner}
                    removeAction={handleRemove}
                    change={designersOpenDialog}
                  ></ActiveFilterDesigner>
                ) : (
                  ""
                )}
                {idListCountry.length ? (
                  <ActiveFilterCountry
                    IdList={idListCountry}
                    removeAction={handleRemove}
                    change={countryOpenDialog}
                  ></ActiveFilterCountry>
                ) : (
                  ""
                )}
                {idListSeason.length ? (
                  <ActiveFilterSeason
                    IdList={idListSeason}
                    removeAction={handleRemove}
                    change={seasonOpenDialog}
                  ></ActiveFilterSeason>
                ) : (
                  ""
                )}
              </div>
            </Grid>
          </Grid>
        </Paper>
        {openDialog ? changeActiveFilterDialogBox : ""}
      </Grid>
    );
  } else {
    return <></>;
  }
};
